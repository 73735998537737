import api from "./api";

const privacyApi = {
	
	privacyApi(params) {
		return api.post("ipv/getDataPrivacy",{...params})
	},

	retrievePrivacyWebhookDataApi(params) {
		return api.post("ipv/retrievePrivacyWebhookData",{...params})
	},

	getSubscriptionData(params) {
		return api.post("ipv/getSubscriptionData",{...params})
	},

	updateSubscriptionData(params) {
		return api.post("ipv/updateSubscriptionData",{...params})
	},

	checkUserWebhookStatus(params) {
		return api.post("ipv/checkUserWebhookStatus",{...params})
	},

	createBillingPortalSession(params) {
		return api.post("createBillingPortalSession",{...params})
	}
};

export default privacyApi;