import api from "./api";

const freeConsultation = {
	
	freeConsultation(params) {
		return api.post("consultancy/request",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default freeConsultation;