import api from "./api";

const paddleSubscription = {
	paddleProductList() {
		return api.get('getPaddleProducts');
	},
	getUserPaddleSubscription(params) {
		return api.post("getUserPaddleSubscription",{device_id:localStorage.getItem('deviceId'),...params})
	},
	cancelPaddleSubscription(params){
		return api.post("cancelPaddleSubscription",{device_id:localStorage.getItem('deviceId'),...params})
	}
};

export default paddleSubscription