import React from "react";
import { Button, Container } from "react-bootstrap";
function Terms() {
  return (
    <Container>
      {/* <h1 className="text-center py-5 my-5">Terms</h1> */}

      <a
        href='https://www.internetprivacy.com/terms-of-use/'
        target="_blank"
      >
      </a>
    </Container>
  );
}

export default Terms;
