
import GlobalService from "./globalService";

const localService = {
  setUserData: function (value) {
    localStorage.setItem('localStorage_userData', JSON.stringify(value));
    GlobalService.localStorageData = value;
  },

  setUserStripePrice: function (value) {
    localStorage.setItem('localStorage_StPrice', value);
    GlobalService.stripePriceValue =  value ;
  },
  setUserStripePriceID: function (value) {
    localStorage.setItem('localStorage_StPriceID',JSON.stringify(value));
    GlobalService.stripePriceId =  value ;
  },

  getUserData: function () {
    const localUserData = localStorage.getItem("localStorage_userData");
    GlobalService.localStorageData = localUserData != null ? JSON.parse(localUserData) : {};
    // console.log('localStorage.getItem("localStorage_userData")',localStorage.getItem("localStorage_userData"))
    // console.log('GlobalService.localStorageData',GlobalService.localStorageData)
  }


};

export default localService;