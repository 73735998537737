
import axios from "axios";

const instance = axios.create({ baseURL: process.env.REACT_APP_API_HOST_URL });

//REACT_APP_API_HOST_URL
instance.interceptors.request.use(req => {
  const request = { ...req }
  const { token } = localStorage
 // const { token } = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9zdGFnaW5nLmludGVybmV0cHJpdmFjeS5jb21cL2FwaVwvdjEwMVwvbG9naW4iLCJpYXQiOjE2NzMyNDc4MDAsImV4cCI6MTY3NTgzOTgwMCwibmJmIjoxNjczMjQ3ODAwLCJqdGkiOiI0TEFDVXNHT2JFRHRCcDhkIiwic3ViIjoxMywicHJ2IjoiODcyNjgwZjI3YzNhZjE3ZmVhNjYxMjY1ZWMyMzhlNTE4ZjEzMTI2OSJ9.fvaTskgKqKDLoXG7FphajNY6ZCOv_8QePz_nyKTn6OQ';
  request.headers.common.Accept = "application/json"
  request.headers.common["Content-Type"] = "application/json"
  request.headers.common.Authorization = `Bearer ${token}`
  // console.log(request.data)
  return request
})

// instance.interceptors.response.use(
//   response => {
//     if (response.data.code == "token_invalid") {
//       localStorage.removeItem('token');
//       window.location.reload();
//     }
//     console.log('response.data.code',response.data.code)
//     return response
//   },
//   error => {
//     return Promise.reject(error)
//   },

// )

instance.interceptors.response.use(
  response => {
    if (response.data.code === "token_invalid") {
      localStorage.removeItem('token');
      window.location.reload();
    }
    return response
  },
  error => {
    return Promise.reject(error)
  },

)

export default instance

