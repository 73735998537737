import { faArrowRight, faSpinner, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import { Container, Form, Button, Row, Col, Card ,Navbar} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import RegistrationScreen2 from "../registration/RegistrationScreen2";
import api from '../../apis'
import GlobalService from "../../services/globalService";
import localService from "../../services/localService";
import { v4 as uuidv4 } from 'uuid';
import { computeHeadingLevel } from "@testing-library/react";
//import Logo from "../../images/logo.png";
import SuccessTick from "../../images/successtick.png";
import AppContext from "../../context/AppContext";
import Logo from "../../images/logo_main.png";

function Index() {
  const appCtx = useContext(AppContext);

  const onClick=()=>{
    if (localStorage.getItem("guest_user_data") !== null) {
      window.location.href = process.env.REACT_APP_HOST_URL+"scanning-result";
    } else {
      window.location.href=process.env.REACT_APP_HOST_URL;
    }
  }

    return (

        <>
            <div className="log-reg-header position-relative px-3">
            <Container>
                <div
                  className="position-absolute top-50"
                  style={{ transform: `translateY(${-50}px` }}
                >
                 <Navbar.Brand href="#">
                        <NavLink to="/app/" style={{marginTop:40}}>
                            <img src={Logo} className="logo2" />
                        </NavLink>
                    </Navbar.Brand>
                </div>
              </Container>
              
            </div>

            <div className="log-reg-footer position-relative" style={{ height: 350, top: 50}}>

            <Container>
                <div
                //   className="position-absolute top-50"
                  style={{ marginTop:"50px",marginBottom:"70px"}}
                > 
               
                <h3> <img src={SuccessTick} className="successTick" />Thank You!</h3>
               
                  
                  <p>Your payment is successful, please click on the below button to go back to home.</p>
                </div>
              </Container>

               
              <Button
                    // variant="primary"
                    className="form-control mt-4 btn-theme"
                    type="submit"
                    style={{ marginTop:"150px"}}
                    onClick={onClick}
                >
                    Home   <FontAwesomeIcon icon={   faArrowRight} />


                </Button>

            </div>

            
        </>

    );
}

export default Index;
