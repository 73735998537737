import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar, Button, Card } from "react-bootstrap";
import "./style.scss";
import api from "../../apis";
import { faSpinner, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessTick from "../../images/successtick.png";
import StopTick from "../../images/stop.png";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import {Routes, Route, useNavigate} from 'react-router-dom';

// Sidebar include
import SideBar from "../../components/sidebar/SideBar";


function PaddleSubscription() {
    const navigate = useNavigate();

    const [showData, setShowData] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [product, setProduct] = useState([]);
    const [subscriptionStatus, setSubscriptionStatus] = useState(false);

    const Paddle = window.Paddle;

    const getUserPaddleSubscription = () => {
        var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));
        let payload = {
            user_id: user_data.id
        }

        const response = api.paddleSubscription.getUserPaddleSubscription(payload).then((res) => {
            console.log(res.data.data_count);

            localStorage.setItem('users_phone',res.data.user_phone);

            if(res.data.data_count > 0) {
                console.log('subscription exists');
                setSubscriptionStatus(true);
                navigate('/app/planstatus');
            } else {
                setSubscriptionStatus(false);
                getProductLists();
            }
            
        }).catch((err) => {
            console.log(err)
        });
    }

    const openCheckout  = (id) => { 
        var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));
        var user_phone = localStorage.getItem('users_phone');
        Paddle.Checkout.open({
            settings: {
              theme: "light",
              //successUrl: process.env.REACT_APP_HOST_URL+"planstatus"
              successUrl: (user_phone == null || user_phone == undefined || user_phone == 'undefined') ? process.env.REACT_APP_HOST_URL+"monitoring" : process.env.REACT_APP_HOST_URL+"planstatus"
            },
            items: [
              {
                priceId: id,
                quantity: 1
              }
            ],
            customData: {
                "utm_content": user_data.id,
            },
            customer: {
                email: user_data.email,
            }
          });
    }

    useEffect(() => {
        getUserPaddleSubscription();
    }, []);

    const getProductLists = () => {
        const response = api.paddleSubscription.paddleProductList().then((response) => {
            console.log(response);
            console.log(response.data.data.status);
            if (response.data.data.status == 'SUCCESS') {
              let result = JSON.parse(response.data.data.response);
              console.log(result.data);
              setProduct(result.data);  
              setShowData(true);
              setShowLoader(false);
            } else {
              toast.error('Something went wrong. Please try after sometime.');
            }
          }).catch((err) => {
            console.log(err)
          });
    }

    return (
        <>
            <div
                className={
                showData ? "free-consultant-page-container paddle-subscription-page-container" : "container-fluid pl-0 zero-data-container"
                }
            >
                <Row>
                    <Col lg={2} className="px-0">
                        <div className="sticky-head border-0 sideBarStick">
                            <SideBar />
                        </div>
                    </Col>
                    <Col lg={10} className="pl-4">
                        {showLoader ? (
                            <div className="loader">
                                <FontAwesomeIcon icon={faSpinner} className="spinner" />
                                <span>Loading...</span>
                            </div>
                            ) : (
                                <>
                                    <Row className="my-4 pb-3 contentBoxing">
                                        <Col md={12} className="d-none d-md-block">
                                            <h2 style={{ marginLeft: "50px" }}>Plans & Billing</h2>
                                        </Col>
                                    </Row>

                                    {subscriptionStatus == false && 
                                        <div className="paddle-product-tabs mb-4">
                                            <Tabs>
                                                <TabList>
                                                    <Tab>
                                                    <span>Monthly</span>
                                                    </Tab>
                                                    <Tab>
                                                    <span>Yearly</span>
                                                    </Tab>
                                                </TabList>
                                                <TabPanel>
                                                        {product.map((p, index) => {
                                                            return (
                                                                <div>
                                                                    <Card style={{ width: '18rem'}} className="mb-4">
                                                                        <Card.Body className="paddle-product">
                                                                            <Card.Title>{p.name}</Card.Title>
                                                                            
                                                                            {p.prices.map((price,idx) =>{
                                                                                return(
                                                                                    price.billing_cycle.interval == 'month' && (
                                                                                        <>
                                                                                            <Card.Text className="mb-5 mt-4">
                                                                                                <span className="paddle-product-price">${price.unit_price.amount / 100} </span>
                                                                                                <span>/ month</span>
                                                                                            </Card.Text>
                                                                                            <Button variant="primary" onClick={() => openCheckout(price.id)} className="btn-theme mb-4">Subscribe</Button>
                                                                                        </>)
                                                                                );
                                                                                
                                                                            })}
                                                                            <Card.Text className="paddle-product-desc">{p.description}</Card.Text>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                                );
                                                            })
                                                        }
                                                </TabPanel>

                                                <TabPanel>
                                                        {product.map((p, index) => {
                                                            return (
                                                                    <div>
                                                                        <Card style={{ width: '18rem' }} className="mb-4">
                                                                            <Card.Body className="paddle-product">
                                                                                <Card.Title>{p.name}</Card.Title>
                                                                                
                                                                                {p.prices.map((pr,ix) =>{
                                                                                    return(
                                                                                        pr.billing_cycle.interval == 'year' && 
                                                                                            (
                                                                                                <>
                                                                                                    <Card.Text className="mb-5 mt-4">
                                                                                                        <span className="paddle-product-price">${pr.unit_price.amount / 100} </span>
                                                                                                        <span>/ year</span>
                                                                                                    </Card.Text>
                                                                                                    <Button variant="primary" onClick={() => openCheckout(pr.id)} className="btn-theme mb-4">Subscribe</Button>
                                                                                                </>
                                                                                            )
                                                                                    );
                                                                                })}
                                                                                <Card.Text className="paddle-product-desc">{p.description}</Card.Text>
                                                                            </Card.Body>
                                                                        </Card>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                </TabPanel>
                                            </Tabs>
                                        </div>
                                    }
                                    
                                </>
                            )
                        }
                    </Col>
                </Row>

            </div>
        </>
    )
}

export default PaddleSubscription;