


import api from "./api";

const stripeProductIdFetch = {
	
	stripeProductIdFetch(params) {
		return api.post("getStripeProductById",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default stripeProductIdFetch;


