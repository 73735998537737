import api from "./api";

const auth = {
	login(params) {
		return api.post("login",{device_id:localStorage.getItem('deviceId'),...params})
	},

	// register(params) {
	// 	return api.post("register",{device_id:"ca32fa1d5cd49552",...params})
	// },

};

export default auth;