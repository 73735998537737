import React from 'react'

import SearchForm from "./SearchForm";

// Bootstrap Module
import { Container, Row, Col, Form, Button, Image, Card } from "react-bootstrap";

// Css path
import "./style/Banner.scss"

// Images
import Laptop from "../../images/laptop.png";
import Trusted_brand from "../../images/trusted-by.png";



function Banner() {
  return (
    <div className="bannerPrivacy">
      <div className="banner-decription">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} className="text-center">
              <h1 className="text-white">
                Remove Your Personal Info From the Web
              </h1>
            </Col>
            <Col lg={9} className="text-center">
              <h2 className="text-white">
                See if your home address, date of birth, phone and other{" "}
                <b>sensitive info is exposed in seconds</b> — and remove it in
                one click.
              </h2>
            </Col>
            <Col lg={12} className="text-center">
              <SearchForm/>
            </Col>
            <Col lg={8}>
              <Image src={Laptop} alt={Laptop} fluid />
            </Col>
            <Col lg={12}>
              <div className="brandCard bg-white">
                <Row className='align-items-center'>
                    <Col lg={6}>
                        <h3>Trusted by millions of users and Fortune 500 companies</h3>
                    </Col>
                    <Col lg={6}>
                        <Image fluid src={Trusted_brand} alt="All Brands Logo" />
                    </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Banner