import React from 'react'

// Bootstrap Module
import { Container, Row, Col} from "react-bootstrap";

// Css path
import "./style/OnlineInfo.scss";

function OnlineInfo() {
  return (
    <div className='OnlineInformation'>
        <Container>
            <Row className='align-items-center justify-content-around'>
                <Col lg={4} sm={4}>
                    <h3 className='lrg-number text-white text-center'>95%</h3>
                </Col>
                <Col lg={7} sm={8}>
                    <div className="infoDetails">
                        <p className='text-white'>of people have exposed personal information online:</p>
                        <ul className='d-flex flex-wrap'>
                            <li>AGE</li>
                            <li>HOME ADDRESS</li>
                            <li>PHONE</li>
                            <li>RELATIVES</li>
                            <li>COURT RECORDS</li>
                            <li>EMAIL &amp; PASSWORD</li>
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default OnlineInfo