import { faArrowRight, faSpinner, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useContext } from "react";
import AppContext from "../../context/AppContext"
import api from "../../apis";
import axios from "axios";

// Bootstrap Module
import { Container, Row, Col, Form, Button, Image, Card } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";


// Scss
import './style/SearchForm.scss';


function SearchForm() {

    const [showLoader, setShowLoader] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const appCtx = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
      getLocation();
      if(localStorage.getItem('customer_current_saved_email') !== null) {
        navigate("/app/scanning-result");
      }
  }, []);

    const onCompleteStep1 = () => {
      setShowLoader(true);
      let payload = {
        firstname: firstname,
        lastname: lastname,
        age: age,
        email: email,
      };

      const response = api.scan
        .scanstepone(payload)
        .then((response) => {
          setShowLoader(false);
          console.log("res......", response);
          if (response.data.status) {
            localStorage.setItem(
              "guest_user_data",
              JSON.stringify(response.data.data)
            );
            localStorage.setItem('guest_user_data_email', response.data.data.email);
            navigate("/app/current-location-scan");
          } else {
            console.log(response);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          console.log(err.message);
          console.log(err);
        });
    };

    const validate = () => {
      return (
        firstname.length &&
        lastname.length &&
        age.length > 0 &&
        age.length <= 3 &&
        age <= 150
      );
    };

    const getLocation = async () => {
      const location = await axios.get("https://ipapi.co/json");
      console.log(location);
      localStorage.setItem('user_current_location_country' ,location.data.country);
      localStorage.setItem('user_current_location_state' ,location.data.region);
      localStorage.setItem('user_current_location_city' ,location.data.city);
      localStorage.setItem('user_current_location_country_name' ,location.data.country_name);
  };



  return (
    <>
      <Form.Floating className="searchForm stepOneForm">
        <Row>
          <Col lg={4} sm={4} xs={12}>
            <Form.Group className="mb-3">
              <Form.Control
                id="firstname"
                type="text"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={4} sm={4} xs={12}>
            <Form.Group className="mb-3">
              <Form.Control
                id="lastname"
                type="text"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={1} sm={4} xs={12}>
            <Form.Group className="mb-3">
              <Form.Control
                id="age"
                type="number"
                placeholder="Age"
                value={age}
                maxLength="3"
                onChange={(e) => {
                  setAge(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col lg={3}>
            <Button
              variant="primary"
              className="form-control"
              type="submit"
              disabled={!validate() || showLoader}
              onClick={onCompleteStep1}
            >
              {" "}
              Scan for Free
              {showLoader ? (
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
              ) : null}
            </Button>
            <p className="btnSpan text-white mt-2">
            Results in 30 seconds. No card required.
            </p>
          </Col>
        </Row>
      </Form.Floating>
      <p className="w-100 d-flex justify-content-center mt-4 mb-0"></p>
    </>
  );
}

export default SearchForm