import { faArrowRight, faSpinner, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Card, Image } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import RegistrationScreen2 from "../registration/RegistrationScreen2";
import api from '../../apis'
import GlobalService from "../../services/globalService";
import localService from "../../services/localService";

import loginLogo from '../../images/logo.png';

import Header from '../scanningflow/header/Header';
import { toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';
import { computeHeadingLevel } from "@testing-library/react";
function Index() {
  const [mail, setMail] = useState("");
  const [pass, setPass] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // const deviceToken = uuidv4(); 
  // console.log('deviceToken====',deviceToken)

 const doLogin = ()=> {
		
			setShowLoader(true);
			let payload = {
			
					email: mail.trim(),
					password: pass,
          // device_token: "7e0ae70f-78de-44ad-8867-e929cd93d345"
          device_token : localStorage.getItem('deviceToken')
				
			}
			console.log('payload fron login', payload)
			const response = api.auth.login(payload).then((response) => {
				console.log('res......', response);
				if (response.data.status) {
         
          setIsLoggedIn(true);
          // localStorage.setItem('isLoggedin', true);
          localStorage.setItem('token', response.data.data.token);
          GlobalService.userData = response.data.data.user_data;
          localService.setUserData(response.data.data.user_data);
          // window.location.reload();
          navigate('/app/dashboard');
				
				} else {
					setShowLoader(false);
					if (response.data.message != undefined && response.data.message != '') {
						toast.error(response.data.message);
					} else {
						console.log('object');
					}
				}
			}).catch((err) => {
				setShowLoader(false);
				console.log(err.message);
				console.log(err);
			});
		
    }

  const validate = () => {
    return mail.length && pass.length;
  };

  return (
    // <>
    //   {
    //     isLoggedIn ?
          // <Button
          //   // variant={!validate() ? 'light' : 'primary'}
          //   className="form-control mt-4"
          //   type="submit"
          //   // disabled={!validate()}
          //   onClick={Logout}
          // >
          //   Logout <FontAwesomeIcon icon={faArrowRight} />
          // </Button>
          // <NavLink to="dashboard">
          // <b className="ms-2 lg-rg-toggle">Sign up </b>
          // </NavLink>
          
          // :
          <>
            <Header/>
            <div className="log-reg-header position-relative px-3">
              <Container>
                <Row>
                  {/* <Col lg={12}>
                    <div className="topBarLogo">
                      <Image src={loginLogo} alt="Login Logo" width={200}/>
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    <div className="text-center welcomeHeads">
                      <h2>Welcome Back! </h2>
                      <p>Please enter your credentials to sign in.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="log-reg-footer position-relative">
              <h4 className="text-center mb-5">Sign in with email</h4>
              <Form.Floating className="mb-4">
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="Email address"
                  value={mail}
                  onChange={(e) => {
                    setMail(e.target.value);
                  }}
                />
                <label htmlFor="email">Email address</label>
              </Form.Floating>
              <Form.Floating>
                <Form.Control
                  id="password"
                  type="password"
                  placeholder="Password"
                  value={pass}
                  onChange={(e) => {
                    setPass(e.target.value);
                  }}
                />
                <label htmlFor="password">Password</label>
              </Form.Floating>
              <NavLink to="/app/forget">
             
                <span className="text-end d-block mt-3 signBtn" style={{ color: "#101828" }}>
                  Forget Password?
                </span>
              </NavLink>
              
              {/* <NavLink to="/app/new_subscriptions">
                <b className="text-end d-block mt-3" style={{ color: "#101828" }}>
                mobile_subscription
                </b>
              </NavLink> */}

              <Button
                // variant="primary"
                className="form-control mt-4 subButton btn-theme"
                type="submit"
                disabled={!validate()}
                // onClick={Login}
                onClick={doLogin}
              >
                Sign in <FontAwesomeIcon icon={faArrowRight} />
               
                {showLoader ?
                      <FontAwesomeIcon icon={faSpinner} className="spinner" />
                      : null}
              </Button>

              <p className="w-100 d-flex justify-content-center mt-4 mb-0">
                Don't have an account?{" "}
                <NavLink to="/app/guest-registration">
                  <span className="ms-2 lg-rg-toggle signBtn">Sign up </span>
                </NavLink>
              </p>
            </div>
          </>
    //   }
    // </>
  );
}

export default Index;
