import React, { useEffect, useState } from "react";
import { Container, Row, Col, Navbar, Button } from "react-bootstrap";
import "./style.scss";
import { Helmet } from "react-helmet";
import GlobalService from "../../services/globalService";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";
import api from "../../apis";
import { faSpinner, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessTick from "../../images/successtick.png";
import StopTick from "../../images/stop.png";
import { toast } from "react-toastify";
// Sidebar include
import SideBar from "../../components/sidebar/SideBar";

var checkValue = "";
function NewSubscription() {
  const userData = GlobalService.localStorageData;
  const [authFound, setAuthFound] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [productDetails, setProductDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);

  const [showData, setShowData] = useState(false);

  useEffect(() => {
    // fetchData();
    // apiCall();

    onPageSubscriptionLoad();
  }, []);

  const onPageSubscriptionLoad = async () => {
    setSubscriptionData([]);

    if (
      GlobalService.subscriptionData != "" &&
      GlobalService.subscriptionData != undefined &&
      GlobalService.subscriptionData != null
    ) {
      setShowLoader(true);
      setTimeout(async () => {
        setSubscriptionData(GlobalService.subscriptionData);
        setShowLoader(false);
        {
          GlobalService.subscriptionData.length > 0
            ? stripeProductIdFetch()
            : console.log("object");
        }
      }, 800);

      setTimeout(async () => {
        setShowData(true);
      }, 1200);
      
    } else {
      apiCall();
    }
  };

  const apiCall = () => {
    setShowLoader(true);
    const response = api.dashboard
      .get()
      .then((response) => {
        console.log("response from subscription", response);
        if (response.data.status) {
          setShowLoader(false);
          setTimeout(() => {
            setShowData(true);
          }, 1200);
          setSubscriptionData(response.data.data.subscription_data);
          GlobalService.subscriptionData = response.data.data.subscription_data;
          console.log(
            " GlobalService.subscriptionData00",
            GlobalService.subscriptionData.length
          );
          console.log("subscription data", subscriptionData.length);

          {
            GlobalService.subscriptionData.length > 0
              ? stripeProductIdFetch()
              : console.log("object");
          }

          // setTimeout(async () => {
          //     await setCheckStatus(true); // for state chnge using it when user back from the subscrition page via the browser back button need one state change to rerender the div
          // }, 1500);
        } else {
          setShowLoader(false);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
        toast.error(err.message);
      });
  };

  const stripeProductIdFetch = () => {
    // setShowLoader(true)

    if (
      GlobalService.subscriptionData &&
      GlobalService.subscriptionData.length > 0
    ) {
      var indexx1 =
        GlobalService.subscriptionData[
          GlobalService.subscriptionData.length - 1
        ];
    }
    let payload = {
      product_id: indexx1 ? indexx1.product_id : 0,
    };
    const response = api.stripeProductIdFetch
      .stripeProductIdFetch(payload)
      .then(async (response) => {
        console.log("response frm stripeProductIdFetch", response);
        if (response.data.status) {
          setProductDetails(response.data.data);
          GlobalService.subscriptionDataProductApi = response.data.data;
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  const fetchData = () => {
    if (
      (GlobalService.subscriptionData =
        "" &&
        GlobalService.subscriptionData == undefined &&
        GlobalService.subscriptionData == null)
    ) {
      // setShowLoader(true);
      // console.log('ll')
      // apiCall()
      // window.location.reaload();
    }

    // checkValue = localStorage.getItem('token');
    // checkValue ? setAuthFound(true) : setAuthFound(false);
  };
  const cancelcall = () => {
    let payload = {
      subscription_id: GlobalService.subscriptionData[0].stripe_subscription_id,
    };
    const response = api.cancelSubscribe
      .cancelSubscribe(payload)
      .then(async (response) => {
        console.log("stripe_subscriptio", response);
        // if (response.data.status) {
        //     GlobalService.subscriptionDataProductApi = response.data.data;
        //     console.log(' GlobalService.subscriptionDataProductApi', GlobalService.subscriptionDataProductApi[0])

        // } else {
        //     toast.error(response.data.message);
        // }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  const lastBilled = () => {
    if (
      GlobalService.subscriptionData &&
      GlobalService.subscriptionData.length > 0
    ) {
      var indexx2 =
        GlobalService.subscriptionData[
          GlobalService.subscriptionData.length - 1
        ];
      var lastBilled = parseInt(Date.parse(indexx2.created_at));
      var dateFormat = new Date(lastBilled);
      var lastBilleDate =
        dateFormat.getDate() +
        "/" +
        (dateFormat.getMonth() + 1) +
        "/" +
        dateFormat.getFullYear();
    }
    return lastBilleDate;
  };

  const nextBilled = () => {
    if (
      GlobalService.subscriptionData &&
      GlobalService.subscriptionData.length > 0
    ) {
      var indexx3 =
        GlobalService.subscriptionData[
          GlobalService.subscriptionData.length - 1
            ];
      var nextBilled = parseInt(Date.parse(indexx3.subscription_end_date));
      var dateFormat = new Date(nextBilled);
      var nextBilleDate =
        dateFormat.getDate() +
        "/" +
        (dateFormat.getMonth() + 1) +
        "/" +
        dateFormat.getFullYear();
    }
    return nextBilleDate;
  };
  const openStripe = (url) => {
    window.open(url, "_blank");
  };

  return (
    <>
      <div
        className={
          showData ? "free-consultant-page-container" : "container-fluid pl-0 zero-data-container"
        }
      >
        <Row>
          <Col lg={2} className="px-0">
            <div className="sticky-head border-0 sideBarStick">
              <SideBar />
            </div>
          </Col>

          <Col lg={10} className="pl-4">
            {showLoader ? (
              <div className="loader">
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
                <span>Loading...</span>
              </div>
            ) : (
              <>
                <Row className="my-4 pb-3 contentBoxing">
                  <Col md={12} className="d-none d-md-block">
                    <h2 style={{ marginLeft: "50px" }}>Plans & Billing</h2>
                  </Col>
                </Row>

                {/* {GlobalService.subscriptionData && GlobalService.subscriptionData.length > 0
                                    && GlobalService.subscriptionData[0].status.toLowerCase() === "active" ? */}

                {(() => {
                  if (subscriptionData && subscriptionData.length > 0) {
                    var indexx = subscriptionData[subscriptionData.length - 1];

                    return (
                      <>
                        <Container>
                          <Row className="subs-stat">
                            <h3 className="d-flex align-items-center">
                              {indexx &&
                              indexx.status.toLowerCase() === "active" ? (
                                <img
                                  src={SuccessTick}
                                  className="successTick "
                                />
                              ) : (
                                <img src={StopTick} className="successTick " />
                              )}
                              Your subscription status
                              <span
                                className="act-color ml-2"
                                style={{ marginLeft: ".5rem" }}
                              >
                                {indexx ? indexx.status : "pp"}
                              </span>
                            </h3>

                            <Col md={12} className="mt-4">
                              {" "}
                              <h4 className="modal-sub-head">
                                Last Billed Date - <span>{lastBilled()} </span>
                                Amounting -{" "}
                                <span>
                                  $
                                  {productDetails &&
                                    productDetails.length > 0 &&
                                    GlobalService.subscriptionDataProductApi[0]
                                      .price}
                                </span>
                              </h4>
                            </Col>

                            <Col md={12}>
                              {" "}
                              <h4 className="modal-sub-head">
                                {" "}
                                You{" "}
                                {indexx &&
                                indexx.status.toLowerCase() === "active"
                                  ? "have  "
                                  : "had  "}
                                {productDetails &&
                                  productDetails.length > 0 &&
                                  GlobalService.subscriptionDataProductApi[0]
                                    .name}{" "}
                                {productDetails &&
                                  productDetails.length > 0 &&
                                  GlobalService.subscriptionDataProductApi[0]
                                    .plan_duration}{" "}
                                Plan{" "}
                                <small>
                                  {productDetails &&
                                  productDetails.length > 0 &&
                                  GlobalService.subscriptionDataProductApi[0]
                                    .plan_duration == "Monthly"
                                    ? "(Per Month)"
                                    : "(Per Year)"}
                                </small>
                              </h4>
                            </Col>
                            {indexx &&
                            indexx.status.toLowerCase() === "active" ? (
                              <Col md={12}>
                                {" "}
                                <h4 className="modal-sub-head">
                                  Next Billing Date -<span>{nextBilled()}</span>
                                </h4>
                              </Col>
                            ) : null}

                            {indexx &&
                            indexx.status.toLowerCase() === "active" ? (
                              <Col md={12} className="mt-4 center-align">
                                {/* <a href="https://dashboard.stripe.com/subscriptions"
                                                                    target="_blank"
                                                                    style={{ color: "grey" }}

                                                                    className="w-100 d-flex justify-content-center mt-4 mb-0">
                                                                    Do you want to cancel it?{" "}
                                                                </a> */}
                                <span style={{ color: "grey" }}>
                                  Please contact{" "}
                                  <a
                                    style={{ color: "grey" }}
                                    target="_blank"
                                    href="mailto:support@internetprivacy.com"
                                  >
                                    <u>support@internetprivacy.com</u>
                                  </a>{" "}
                                  <br />
                                  to cancel your subscription
                                </span>
                              </Col>
                            ) : null}
                          </Row>
                        </Container>

                        {indexx &&
                        indexx.status.toLowerCase() === "cancelled" ? (
                          <Row className="mt-5">
                            <div>
                              <Helmet>
                                <script src="https://js.stripe.com/v3/pricing-table.js"></script>
                              </Helmet>
                              <stripe-pricing-table
                                pricing-table-id="prctbl_1MdYSiKfBR346qLzMKxQgOZt"
                                publishable-key="pk_test_51MTSk0KfBR346qLzhk1jpPt14j7x5vCcGEBTZuDZEc89LmKctFj9rCfdEC8iQGZngkyDc9nopTx1ls0oiszNU4V900EXMD1QGe"
                                client-reference-id={
                                  userData !== null ? userData.id : ""
                                }
                              ></stripe-pricing-table>
                            </div>
                          </Row>
                        ) : null}
                      </>
                    );
                  }
                })()} 

                {GlobalService.subscriptionData &&
                GlobalService.subscriptionData.length == 0 ? (
                  <Row>
                    <div>
                      {/* Client's test stripe */}
                      {/* <Helmet>
                                                <script src="https://js.stripe.com/v3/pricing-table.js"></script>
                                            </Helmet>
                                            <stripe-pricing-table pricing-table-id="prctbl_1MdYSiKfBR346qLzMKxQgOZt"
                                                publishable-key="pk_test_51MTSk0KfBR346qLzhk1jpPt14j7x5vCcGEBTZuDZEc89LmKctFj9rCfdEC8iQGZngkyDc9nopTx1ls0oiszNU4V900EXMD1QGe"
                                                client-reference-id={userData !== null ? userData.id : ""}>
                                            </stripe-pricing-table> */}

                      {/* Client's live stripe */}
                      <Helmet>
                        <script
                          async
                          src="https://js.stripe.com/v3/pricing-table.js"
                        ></script>
                      </Helmet>
                      <stripe-pricing-table
                        pricing-table-id="prctbl_1NIx0PKfBR346qLzkgzQbTRI"
                        publishable-key="pk_live_51MTSk0KfBR346qLz542HRa9ZEgbhfA8akEmNTZ7B4nsJPCBHIr3SgWSmuFOLIl6rcMKMCFiE3lJzUDqP6K52Wi2p00qemsXRUm"
                        client-reference-id={
                          userData !== null ? userData.id : ""
                        }
                      ></stripe-pricing-table>



                         {/* Client's current stripe */}
                        {/* <Helmet><script async src="https://js.stripe.com/v3/pricing-table.js"></script></Helmet>
                        <stripe-pricing-table pricing-table-id="prctbl_1NVGtIKfBR346qLz49ce2qbm"
                        publishable-key="pk_live_51MTSk0KfBR346qLz542HRa9ZEgbhfA8akEmNTZ7B4nsJPCBHIr3SgWSmuFOLIl6rcMKMCFiE3lJzUDqP6K52Wi2p00qemsXRUm"
                        client-reference-id={
                          userData !== null ? userData.id : ""
                        }
                        >
                        </stripe-pricing-table> */}


                    </div>
                  </Row>
                ) : null}

                <Row className="my-4 pb-3"></Row>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NewSubscription;
