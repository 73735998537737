// WithNav.js (Stand-alone Functional Component)
import React from 'react';
import { Outlet } from 'react-router';
import Header from './Header';
import Footer from './Footer';
export default () => {
  return (
    <>
     <Header />
     <Outlet />
     <Footer />
     </>
  );
};
