import React, { useEffect, useState } from "react";
import { NavLink, redirect, useNavigate } from "react-router-dom";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Container, Form, Button, Row, Col, Card, Image } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import "./StepForm.scss";
import GlobalService from '../../services/globalService';
import api from '../../apis';

import loginLogo from '../../images/logo.png'
import { toast } from 'react-toastify';

function StepOne({ nextStep, handleFormData, values }) {

  var guest_user_data_first_name = '';
  var guest_user_data_last_name = '';
  if(localStorage.getItem('guest_user_data') !== null) {
    let guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));
    guest_user_data_first_name = guest_user_data.first_name;
    guest_user_data_last_name = guest_user_data.last_name;
  }
  

  const [fname, setFname] = useState(guest_user_data_first_name);
  const [lname, setLname] = useState(guest_user_data_last_name);
  const [lnameErr, setLnameErr] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  const [blankFname, setBlankFname] = useState(true);
  const [blankLname, setBlankLname] = useState(true);



  // useEffect(() => {
  //  validate()

  // }, []);

  // const validate = () => {
  //   if (fname.length == 0 || lname.length == 0) {
  //     setDisabled(true);
  //   }

  // };

  const handleFirstName = (e) => {
    // console.log(e)
    setFname(e.target.value);
    // validationForm(e.target.value, 'fname')

  }
  const handleLastName = (e) => {
    // console.log(e)
    setLname(e.target.value);
    // validationForm(e.target.value, 'lname')

  }

  const submitFormData = (e) => {
    e.preventDefault();
    let nameRegex = /^[a-zA-Z ]+$/;
    let error = false;

    if (fname != '' && !nameRegex.test(fname)) {
      toast.error('First name cannot contain numeric values or special characters');
      error = true;
      return false;
    }
    else {
      if (nameRegex.test(fname)) {
        error = false;
      }
    }


    if (lname != '' && !nameRegex.test(lname)) {
      toast.error('Last name cannot contain numeric values or special characters');
      error = true;
      return false;
    }
    else {
      if (nameRegex.test(lname)) {
        error = false;
      }
    }

    if (error === false) {
      setShowLoader(true);

      let payload = {
        step: 1,
        first_name: fname.trim(),
        last_name: lname.trim(),
        // device_token: "7e0ae70f-78de-44ad-8867-e929cd93d345"
        device_token : localStorage.getItem('deviceToken')
      }
      console.log(payload);
      const response = api.register.register(payload).then((response) => {

        console.log(response)

        if (response.data.status) {
          GlobalService.register_id = response.data.data.onb_id;
          // console.log(GlobalService.register_id)

          setShowLoader(false);
          nextStep();
        }
        else {
          toast.error(response.data?.message);
          setShowLoader(false);
        }

      });
    } else {
      return false;
    }
  };

  const validationForm = async (value, names) => {
    // console.log('value', value)
    // console.log('fname',fname);
    // console.log('lname',lname)
    let nameRegex = /^[a-zA-Z]+$/;
    if (names == 'fname') {
      setFname(value);
      if (names != '' && nameRegex.test(value)) {
        setFnameErr(false);
        setBlankFname(false)
      }
      else {
        if (names != '' && !nameRegex.test(value)) {
          if (value == '') {
            setFnameErr(false)
            setBlankFname(true)
          } else {
            setFnameErr(true)
            setBlankFname(false)
          }
        } else if (names != '' && value == '') {
          setFnameErr(false)
        } else {
          setFnameErr(false)
        }
      }
    }
    else {
      setLname(value);
      if (names != '' && nameRegex.test(value)) {
        setLnameErr(false);
        setBlankLname(false)
      } else {
        if (names != '' && !nameRegex.test(value)) {
          if (value == '') {
            setLnameErr(false)
            setBlankLname(true)

          } else {
            setLnameErr(true)
            setBlankLname(false)
          }
        } else if (names != '' && value == '') {

          setLnameErr(false)
        } else {
          setLnameErr(false)
        }
      }
    }

    if (fnameErr == false && lnameErr == false) {
      if (fname != '' && lname != '') {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    } else {
      setDisabled(true)
    }
    GlobalService.register_data.fname = fname.trim();
    GlobalService.register_data.lname = lname.trim();


  }
  const validate = () => {
    return fname.length && lname.length;
  };


  return (
    <>
      <div className="log-reg-header position-relative px-3">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="topBarLogo">
                <Image src={loginLogo} alt="Login Logo" width={200}/>
              </div>
            </Col>
            <Col lg={12}>
              <div className="text-center welcomeHeads">
                <h2>What’s your name?</h2>
                <p>Enter your official full name to perform the search on!</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="log-reg-footer  position-relative">
        <Form>
          <Form.Group >
            <Form.Floating className="mb-4">
              <Form.Control
                style={{ background: fnameErr ? "#F88D99" : "" }}
                name="firstName"
                maxLength={25}
                type="text"
                placeholder="First Name"
                value={fname}
                onChange={(e) => {
                  // setFname(e.target.value)
                  handleFirstName(e)
                  // validationForm(e.target.value, 'fname')

                }}
              />
              <label htmlFor="fname">First name</label>
            </Form.Floating>

          </Form.Group>


          <Form.Group>
            <Form.Floating className="mb-4">
              <Form.Control
                style={{ background: lnameErr ? "#F88D99" : "" }}
                name="lastName"
                maxLength={25}
                type="text"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => {
                  handleLastName(e)
                }}
              />
              <label htmlFor="lname">Last name</label>
            </Form.Floating>
          </Form.Group>
          <Button
            className="form-control mt-4 subButton"
            type="submit"
            onClick={submitFormData}
            disabled={!validate()}
          // disabled={fnameErr == false && lnameErr == false && blankFname == false && blankLname == false ? false : true}
          >
            Continue <FontAwesomeIcon icon={faArrowRight} />
            {showLoader ?
              <FontAwesomeIcon icon={faSpinner} className="spinner" />
              : null}
          </Button>
          <p className="w-100 d-flex justify-content-center mt-4 mb-0">
            Already have an account?{" "}
            <NavLink to="/app/login">
              <b className="ms-2 lg-rg-toggle">Sign In</b>
            </NavLink>
          </p>
        </Form>
      </div>
    </>
  );
}

export default StepOne;
