import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Accordion,
  useAccordionButton,
  Dropdown,
  Image
} from "react-bootstrap";
import {
  faArrowRight,
  faSearch,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Twitter from "../../images/twitter.png"
import Insta from "../../images/insta.png"
import Facebook from "../../images/fbbv.png"
import GlobalService from '../../services/globalService';
import api from '../../apis';
import loginLogo from '../../images/logo.png'
import { toast } from 'react-toastify';



function StepThree({prevStep,nextStep}) {
  const [twitter, setTwitter] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");

  const [twtErr, setTwtErr] = useState(false);
  const [insErr, setInsErr] = useState(false);
  const [fbErr, setFbErr] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false)
  

	const nextCall = (e) => {
    e.preventDefault();
		let fb = /^[a-z\d.]{5,}$/i;
		let ins = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/igm;
		let twit = /^([a-z0-9_]{2,15})$/i;
    let error = false;

		if (twitter.trim() != '' && !twit.test(twitter)) {
			toast.error('Invalid twitter handle!');
			setTwtErr(true);
      error = true;
			// return false;
		}
		else if (instagram.trim() != '' && !ins.test(instagram)) {
			toast.error('Invalid instagram handle!');
			setInsErr(true);
      error = true;
			// return false;
		}
		else if (facebook.trim() != '' && facebook.length < 5) {
			toast.error('Facebook handle should be minimum 5 characters in length!');
			setFbErr(true);
      error = true;
			// return false;
		}
		else if (facebook.trim() != '' && !fb.test(facebook)) {
			toast.error('Invalid facebook handle!');
			setFbErr(true);
      error = true;
			// return false;
		}
		if (error === false) {
      setShowLoader(true);
		let payload = {
				step: 3,
				onb_id: GlobalService.register_id,
				twitter_handle: twitter.trim(),
				instagram_handle: instagram.trim(),
				facebook_name: facebook.trim()
			
		}
    console.log('payload',payload)
    const response = api.register.register(payload).then((response) => {

      console.log('response social',response)

      if (response.data.status) {
        GlobalService.register_id = response.data.data.onb_id;
        // console.log(GlobalService.register_id)
       
        setShowLoader(false);
        nextStep();
      }
      else {
        console.log(response.data.message);
        setShowLoader(false);
      }

    });
  }else{
    return false;
  }
	}

  return (
    <>
      <div className="log-reg-header position-relative px-3">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="topBarLogo">
                <Image src={loginLogo} alt="Login Logo" width={200}/>
              </div>
            </Col>
            <Col lg={12}>
              <div className="text-center welcomeHeads">
                <h2>Add your social accounts</h2>
                <p>Adding your social accounts will improve your search results.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="log-reg-footer  position-relative"
        // style={{ height: 420, padding: 15 }}
      >
        {/* {showLoader ? (
          <div style={{}} className="loader">
            <p>Loading...</p>
            <FontAwesomeIcon icon={faSpinner} className="spinner" />
          </div>
        ) : null} */}
        <Form>
          <div className="input-group mb-3">
            <span className="input-group-text add-at" id="basic-addon1"><img src={Twitter} alt="" className="social-icon"/></span>
            <input
              type="text"
              maxLength={40}
              value={twitter}
              onChange={e => setTwitter(e.target.value)} 
              className="form-control"
              placeholder="username"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text add-at" id="basic-addon1"><img src={Insta} alt="" className="social-icon"/></span>
            <input
              type="text"
              maxLength={40}
              value={instagram} 
              onChange={e => setInstagram(e.target.value)} 
              className="form-control"
              placeholder="username"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1"><img src={Facebook} alt="" className="social-icon"/></span>
            <input
              type="text"
              maxLength={40}
              value={facebook} 
              onChange={e => setFacebook(e.target.value)} 
              className="form-control"
              placeholder="Username"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <Container
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              margin: "auto",
            }}
          >
            <Row>
              <Col md={3} xs={4}>
                <Button 
                variant="primary" 
                onClick={prevStep}
                className="mt-4 w-100 subButton">
                  Previous
                </Button>
              </Col>
              <Col md={9} xs={8}>
                <Button 
                  className="form-control mt-4 subButton" 
                  onClick={nextCall}
                  disabled={showLoader}
                  type="submit">
                  Continue <FontAwesomeIcon icon={faArrowRight} />

                  {showLoader ?
                    <FontAwesomeIcon icon={faSpinner} className="spinner" />
                    : null}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </>
  );
}

export default StepThree;
