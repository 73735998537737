import React, { useEffect, useState } from "react";
import { Container, Form, Button, Col, Row, InputGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";
function Index() {
  const [step, setstep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    email: "",
    twitter: "",
    insta: "",
    facebook: ""
  });

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    const nextStep = step + 1;
    setstep(nextStep);
    localStorage.setItem('step', nextStep);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    const prevStep = step - 1;
    setstep(prevStep);
    localStorage.setItem('step', prevStep);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and then adding new value to create new object
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  // javascript switch case to show different form in each step

  // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm

  return (
    <div className="App">
      <div className={step != 1 ? "Hidden" : ""}>

        <StepOne
          nextStep={nextStep}
          handleFormData={handleInputData}
          values={formData}
        />
      </div>


      <div className={step != 2 ? "Hidden" : ""}>

        <StepTwo
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
          values={formData}
        />

      </div>

      {/* <div className={step != 3 ? "Hidden":""}>
         <StepThree 
            values={formData}  
            nextStep={nextStep}
            prevStep={prevStep}
            handleFormData={handleInputData}
                   />
         </div>

         <div className={step != 4 ? "Hidden":""}>
           <StepFour
             values={formData} />
         </div> */}


      <div className={step != 3 ? "Hidden" : ""}>
        <StepThree
          values={formData}
          nextStep={nextStep}
          prevStep={prevStep}
          handleFormData={handleInputData}
        />
      </div>

      <div className={step != 4 ? "Hidden" : ""}>
        <StepFour
          values={formData} 
          nextStep={nextStep}
          prevStep={prevStep}/>
      </div>


    </div>
  );

}

export default Index;
