
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
// import YTVideo from "./YTVideo";
import GlobalService from "../../services/globalService";
import React1 from "../../images/react1.png";
import React2 from "../../images/react2.png";
import React3 from "../../images/react3.png";
import React4 from "../../images/react4.png";
const userData = [
    { name: "Jeevan" },
    { name: "Manish" },
    { name: "Prince" },
    { name: "Arti" },
    { name: "rahul" }
];

function SelectAll() {
    const [users, setUsers] = useState([]);
    const [checkedBox, setCheckedBox] = useState(false);
    const [sentimentId, setSentimentId] = useState([]);
    const [allSentimentId, setAllSentimentId] = useState([]);
    const [searchResultLoader, setSearchResultLoader] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [displaySentimentText, setDisplaySentimentText] = useState('');
    const [showFilterArea, setShowFilterArea] = useState(false);
    const [title, setTitle] = useState('All Results');
    const [sortValue, setSortValue] = useState('all');
    const [sortLabel, setSortLabel] = useState('All');
    const [searchResultData, setSearchResultData] = useState({});


    useEffect(() => {
        setUsers(GlobalService.searchResult.results);
       
    }, []);

    const handleChange = (e) => {
        const { id, checked } = e.target;
        console.log('e.target', id, checked)
        if (id === "allSelect") {
            setCheckedBox(checked);
            let tempUser = users.map((user) => {
                return { ...user, isChecked: checked };
            });
            // setAllSentimentId(id)
            setUsers(tempUser);
        } else {
            //     let tempUser = users.map((user) =>
            //         user.key === id ? { ...user, isChecked: checked } : user

            //     );
            setCheckedBox(checked);
            let tempUser = users.map((user) => {
                return (
                    user.key === id ? { ...user, isChecked: checked } : user
                )
            })
            // console.log('temmmm',tempUser)
            sentimentId(id)
            setUsers(tempUser);
        }
    };


   
    const handleAllChange = (e) => {
        const { id, checked } = e.target;
        console.log('e.target', id, checked)
        var _temp = [];
        if (id === "allSelect") {
            setCheckedBox(checked);
            let tempUser = users.map((user) => {
                return { ...user, isChecked: checked };
            });
            // setAllSentimentId(id)
            setUsers(tempUser);
        }
        // console.log('kkkkkk',tempUser)
        // tempUser.map((obj, i) => {
        //     console.log('iiiiiii', i)
        //     if (tempUser[i]['isChecked'] == true) {
        //         _temp.push(tempUser[i]['key'])
        //         setSentimentId(_temp);
        //     }
        // });

        // console.log('idsss', sentimentId)
    };

    const handleSingleChange = (e) => {
        const { id, checked } = e.target;
        console.log('e.target', id, checked)
        var _temp = [];

        setCheckedBox(checked);
        let tempUser = users.map((user) => {
            return (
                user.key === id ? { ...user, isChecked: checked } : user
            )
        })
        setUsers(tempUser);

        tempUser.map((obj, i) => {
            // console.log('iiiiiii', i)
            if (tempUser[i]['isChecked'] == true) {
                _temp.push(tempUser[i]['key'])
                setSentimentId(_temp);
            }
        });

        console.log('idsss', sentimentId)

    };
    const changeSentiment = (sentiment, itemId, curSentiment) => {
        // doAction(sentiment, sentimentIds, curSentiment)
        console.log(sentiment, itemId, curSentiment)
        doAction(sentiment, sentimentId, curSentiment)
    }
    const doAction = (sentiment, itemId, curSentiment) => {

        console.log('from doAction', sentiment, itemId, curSentiment)

    }


    return (
        <div className="container my-4" style={{ width: "500px" }}>
           
            <form className="form w-100">

                {
                    checkedBox ?
                        <div className="mark-result">
                            <img src={React1} alt="Positive"
                                onClick={() => changeSentiment('SENTIMENT_POSITIVE', '', 'Positives')}
                            />
                            <img src={React2} alt="Negative" onClick={() => changeSentiment('SENTIMENT_NEGATIVE', '', 'Negatives')} />
                            <img src={React3} alt="Neutral" onClick={() => changeSentiment('SENTIMENT_NEUTRAL', '', 'Neutrals')} />
                            <img src={React4} alt="Not Me" onClick={() => changeSentiment('SENTIMENT_NOT_ME', '', 'NotMe')} />
                        </div>

                        : null
                }

                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        name="allSelect"
                        id="allSelect"
                        // checked={
                        //   users.filter((user) => user?.isChecked !== true).length < 1
                        // }
                        checked={!users.some((user) => user?.isChecked !== true)}
                        onChange={handleAllChange}
                    />
                    <label className="form-check-label ms-2">All Select</label>
                </div>
                {users.map((user, index) => (
                    <div className="form-check" key={index}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id={user.key}
                            checked={user?.isChecked || false}
                            onChange={handleSingleChange}
                        />
                        <label className="form-check-label ms-2">{user.title}</label>
                    </div>
                ))}
            </form>
        </div>
    );
}

export default SelectAll;



