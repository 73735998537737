import React, { useState } from "react";

const AppContext = React.createContext({
  APP_HOST_URL: process.env.REACT_APP_HOST_URL,
  API_HOST_URL: process.env.REACT_APP_API_HOST_URL,
});

export const AppContextProvider = (props) => {
  return (
    <AppContext.Provider value={AppContext}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;