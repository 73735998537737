import api from "./api";

const updateManageMonitoring = {
	
	updateManageMonitoring(params) {
		return api.post("user-update-monitoring",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default updateManageMonitoring;