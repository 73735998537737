import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Dropdown,
  Image
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  faArrowRight,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import "./StepForm.scss";
import ReactFlagsSelect from "../../assets/react-flags-select";
import GlobalService from '../../services/globalService';
import loginLogo from '../../images/logo.png'
import { toast } from 'react-toastify';

import api from "../../apis";

function StepTwo({ nextStep, handleFormData, values, prevStep, eventKey }) {
  // creating functional component ans getting props from app.js and destucturing them

  //creating error state for validation

  const [error, setError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [stateData, setStateData] = useState([]);
  const [allStateData, setAllStateData] = useState([]);
  const [stateName, setStateName] = useState('Select State');
  const [stateNameId, setStateNameId] = useState('');
  const [showLoader, setShowLoader] = useState(false)
  const [cityData, setCityData] = useState([]);
  const [cityName, setCityName] = useState("Select City");
  const [cityNameId, setCityNameId] = useState("");
  // after form submit validating the form data using validator

  const navigate = useNavigate();

  // const submitFormData = (e) => {
  //   e.preventDefault();
  //   if (validator.isEmpty(values.age) || validator.isEmpty(values.email)) {
  //     setError(true);
  //   } else {
  //     console.log("next")
  //     nextStep();
  //   }
  // };

  useEffect(() => {
    stateLoad(selectedCountry);
  }, []);

  const stateLoad = (countrycode) => {
    setSelectedCountry(countrycode); // this one set hochhe naa but ata na dile chnges ta hochhe naa screen e
    // console.log('==========', selectedCountry) // prblm hochhe set hochhe na

    setStateData([]);
    setCityData([]);
    setStateName("Select State");
    setCityName("");
    setStateNameId("");
    setCityNameId("");


    let data = {
      country_code: countrycode,
    };
    setShowLoader(true);
    const response = api.states.states(data).then((response) => {
      setShowLoader(false);
      if (response.data.status) {
        let newArray = response.data.data.map((item) => {
          return { id: item.id, name: item.name };
        });
        setStateData(newArray);
      } else {
        console.log(response.data.message);
        setShowLoader(false);
      }
    });
  };

  const cityLoad = (statecode) => {
    let data = {
      state_id: statecode,
    };
    setShowLoader(true);
    const response = api.city.city(data).then((response) => {
      console.log(response);
      setShowLoader(false);
      if (response.data.status) {
        let newArray = response.data.data.map((item) => {
          return { id: item.id, name: item.name };
        });
        setCityData(newArray);
      } else {
        console.log(response.data.message);
        setShowLoader(false);
      }
    });
  };

  const nextCall = (e) => {
    e.preventDefault();
    let error = false;
    if (stateData.length !== 0 && stateNameId === '') {
      error = true;
      toast.error('You must select a State');
    }
    else if (cityData.length !== 0 && cityNameId === '') {
      error = true;
      toast.error('You must select a City');
    }
    if (error === false) {
      setShowLoader(true);
      let payload = {
        step: 2,
        onb_id: GlobalService.register_id,
        country_code: selectedCountry,
        state_id: stateNameId ? stateNameId : null,
        city_id: cityNameId ? cityNameId : null

      }
      const response = api.register.register(payload).then((response) => {
        setShowLoader(false);
        if (response.data.status) {
          nextStep()
        }
        else {
          console.log(response.data.message);
          setShowLoader(false);
        }

      });
    } else {
      return false;
    }
  }

  // State filter constant
  const [selectedState, setselectedState] = useState({})
  const [dropdownSearchValue, setDropdownSearchValue] = useState("")
  const [editMode, setEditMode] = useState(false)
  // City filter constant
  const [selectedCity, setselectedCity] = useState({})


  const dropdownRef = useRef()

  /**
   * Close the dropdown when clicked outside
   * Refer https://www.codingdeft.com/posts/react-on-click-outside/ for details
   */
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        editMode &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setEditMode(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [editMode])

  const stateSelectionHandler = list => {
    setselectedState(list)
    setDropdownSearchValue("")
    setEditMode(false)
  }

  const filteredState = stateData.filter(list =>
    list.name.match(new RegExp(dropdownSearchValue, "i"))
  )

  const filteredCity = cityData.filter(list =>
    list.name.match(new RegExp(dropdownSearchValue, "i"))
  )

  return (
    <>
      <div className="log-reg-header position-relative px-3">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="topBarLogo">
                <Image src={loginLogo} alt="Login Logo" width={200}/>
              </div>
            </Col>
            <Col lg={12}>
              <div className="text-center welcomeHeads">
                <h2>Where should we monitor?</h2>
                <p>In which country should we monitor?</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        className="log-reg-footer  position-relative  csc"
       
      >
        {/* {showLoader ? (
          <div style={{}} className="loader">
            <p>Loading...</p>
            <FontAwesomeIcon icon={faSpinner} className="spinner" />
          </div>
        ) : null} */}
        <Form className="csc-form">

          <ReactFlagsSelect
            selected={selectedCountry}
            searchable={true}
            searchPlaceholder="Search"
            onSelect={(code) => {
              // setSelectedCountry(code)
              stateLoad(code);

            }}
            className="mb-2"
          />
          {stateData.length > 0 && (
            <Dropdown ref={dropdownRef} className="mb-2">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {stateName}
              </Dropdown.Toggle>


              <Dropdown.Menu>

                <div className="search-country">
                  <input
                    placeholder="Search State"
                    autoFocus
                    onChange={e => setDropdownSearchValue(e.target.value)}
                    value={dropdownSearchValue}
                    className="sticky-top"
                  />
                </div>
                {filteredState.map(data => {
                  return (
                    <Dropdown.Item key={data.id}
                      onClick={() => {
                        setStateName(data.name)
                        setStateNameId(data.id)
                        setCityName('Select City')
                        setCityNameId("")
                        cityLoad(data.id)
                        stateSelectionHandler(data)
                      }}
                    >
                      {data.name}
                    </Dropdown.Item>
                  )
                })}
                {filteredState.length === 0 && (
                  <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          )}



          {cityData.length > 0 && (
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">

                {cityName}
              </Dropdown.Toggle>
              <Dropdown.Menu>

                <div className="search-country">
                  <input
                    placeholder="Search City"
                    autoFocus
                    onChange={e => setDropdownSearchValue(e.target.value)}
                    value={dropdownSearchValue}
                    className="sticky-top"
                  />

                </div>
                {filteredCity.map((data) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setCityName(data.name);
                        setCityNameId(data.id);

                      }}
                      key={data.id}
                    >
                      {data.name}
                    </Dropdown.Item>
                  );
                })}
                {filteredCity.length === 0 && (
                  <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                )}

              </Dropdown.Menu>
            </Dropdown>

          )}


          <Container
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              margin: "auto",
            }}
          >
            <Row>
              <Col md={3} xs={4}>
                <Button
                  variant="primary"
                  onClick={prevStep}
                  disabled={showLoader}
                  className="mt-4 w-100 subButton"
                >
                  Previous
                  {/* <NavLink to="StepOne">
                    <b className="ms-2 lg-rg-toggle"></b>
                  </NavLink> */}
                </Button>
              </Col>
              <Col md={9} xs={8}>
                <Button
                  className="form-control mt-4 subButton"
                  type="submit"
                  onClick={nextCall}
                  disabled={showLoader}

                >
                  Continue <FontAwesomeIcon icon={faArrowRight} />
                  {showLoader ?
                    <FontAwesomeIcon icon={faSpinner} className="spinner" />
                    : null}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
    </>
  );
}

export default StepTwo;
