import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { NavLink, redirect } from "react-router-dom";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../apis";
import localService from "../../services/localService";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import loginLogo from "../../images/logo.png";
import axios from "axios";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import "./StepForm.scss";

function HelloPrivacyScan({ handleFormData, values }) {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [lnameErr, setLnameErr] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [blankFname, setBlankFname] = useState(true);
  const [blankLname, setBlankLname] = useState(true);
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const params = useParams();
  const paramFname = params.fname;
  const hasParam =
    paramFname !== null && paramFname !== undefined ? true : false;

  const paramLname = params.lname;
  const paramAge = params.age;

  useEffect(() => {
    document.querySelector('meta[name="viewport"]').content="";



    
    const metaTitle = document.querySelector('meta[name="viewport"]');
    console.log(metaTitle);

    getLocation();
    if (localStorage.getItem("customer_current_saved_email") !== null) {
      navigate("/app/scanning-result");
    } else {
      if (
        paramFname !== null &&
        paramLname !== null &&
        paramAge !== null &&
        paramFname !== undefined &&
        paramLname !== undefined &&
        paramAge !== undefined
      ) {
        setFname(paramFname);
        setLname(paramLname);
        setAge(paramAge);
        onCompleteStep1();
      }
    }
  }, []);

  const handleFirstName = (e) => {
    setFname(e.target.value);
  };
  const handleLastName = (e) => {
    setLname(e.target.value);
  };
  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const onCompleteStep1 = () => {
    setShowLoader(true);
    let payload = {
      firstname:
        paramFname !== null && paramFname !== undefined ? paramFname : fname,
      lastname:
        paramLname !== null && paramLname !== undefined ? paramLname : lname,
      age: paramAge !== null && paramAge !== undefined ? paramAge : age,
      email: email,
    };
    console.log(payload);
    const response = api.scan
      .scanstepone(payload)
      .then((response) => {
        setShowLoader(false);
        console.log("res......", response);
        if (response.data.status) {
          localStorage.setItem(
            "guest_user_data",
            JSON.stringify(response.data.data)
          );
          localStorage.setItem(
            "guest_user_data_email",
            response.data.data.email
          );
          navigate("/app/current-location-scan");
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err.message);
        console.log(err);
      });
  };

  const validate = () => {
    return (
      fname.length &&
      lname.length &&
      age.length > 0 &&
      age.length <= 3 &&
      age <= 150
    );
  };

  const getLocation = async () => {
    const location = await axios.get("https://ipapi.co/json");
    console.log(location);
    localStorage.setItem(
      "user_current_location_country",
      location.data.country
    );
    localStorage.setItem("user_current_location_state", location.data.region);
    localStorage.setItem(
      "user_current_location_state_code",
      location.data.region_code
    );
    localStorage.setItem("user_current_location_city", location.data.city);
    localStorage.setItem(
      "user_current_location_country_name",
      location.data.country_name
    );
  };

  document.querySelector('meta[name="viewport"]').content="";

  return (
    <>
      {hasParam == false ? (
        <>
          <Header />
          {/* <div className="log-reg-header position-relative px-3 guest-user-header">
            <Container>
                <Row>
                  <Col lg={12}>
                    <div className="text-center welcomeHeads">
                      <h2>Start a quick scan</h2>
                      <p style={{ paddingTop: 10, lineHeight: .5}}>See if your address, phone number or other <b>info is exposed in</b> <b>seconds —</b> learn how to remove in a few easy steps.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
          </div> */}

          <div
            className="mb-4 mt-4"
            id="guest-user-footer"
          >
            {/* <Form style={{ display: 'flex', flexDirection: 'row'}}>
              <Form.Group >
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: fnameErr ? "#F88D99" : "" }}
                    name="firstName"
                    maxLength={25}
                    type="text"
                    placeholder="First Name"
                    value={fname}
                    onChange={(e) => {
                      handleFirstName(e)
                    }}
                  />
                  <label htmlFor="fname">First name</label>
                </Form.Floating>
              </Form.Group>
              <Form.Group>
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: lnameErr ? "#F88D99" : "" }}
                    name="lastName"
                    maxLength={25}
                    type="text"
                    placeholder="Last Name"
                    value={lname}
                    onChange={(e) => {
                      handleLastName(e)
                    }}
                  />
                  <label htmlFor="lname">Last name</label>
                </Form.Floating>
              </Form.Group>
              <Form.Group>
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: lnameErr ? "#F88D99" : "" }}
                    name="age"
                    maxLength={3}
                    type="number"
                    placeholder="Age"
                    value={age}
                    onChange={(e) => {
                        handleAge(e);
                    }}
                  />
                  <label htmlFor="email">Age</label>
                </Form.Floating>
              </Form.Group>
              <Button
                className="form-control subButton btn btn-theme"
                type="button"
                onClick={onCompleteStep1}
                disabled={!validate() || showLoader}
                style={{ width: '20%', marginLeft: '20px'}}
              >
                Scan For Free <FontAwesomeIcon icon={faArrowRight} />
                {showLoader ?
                  <FontAwesomeIcon icon={faSpinner} className="spinner" />
                  : null}
              </Button>
              
            </Form> */}

            <div className="formScannig">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <Form.Floating className="searchForm stepOneForm">
                      <div className="mx-auto flex flex-col md:flex-row justify-around">
                        <Container>
                          <Row className="align-items-center">
                            <Col>
                              <Form.Group className="f-search">
                                <Form.Control
                                  id="firstname"
                                  type="text"
                                  placeholder="First Name"
                                  value={fname}
                                  onChange={(e) => {
                                    handleFirstName(e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="f-search">
                                <Form.Control
                                  id="lastname"
                                  type="text"
                                  placeholder="Last Name"
                                  value={lname}
                                  onChange={(e) => {
                                    handleLastName(e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={2} sm={2} xs={2}>
                              <Form.Group className="f-search">
                                <Form.Control
                                  id="age"
                                  type="number"
                                  placeholder="Age"
                                  value={age}
                                  maxLength="3"
                                  onChange={(e) => {
                                    handleAge(e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Button
                                variant="primary"
                                className="form-control btn-theme"
                                type="submit"
                                disabled={!validate() || showLoader}
                                onClick={onCompleteStep1}
                              >
                                {" "}
                                Scan for Free
                                {showLoader ? (
                                  <FontAwesomeIcon
                                    icon={faSpinner}
                                    className="spinner"
                                  />
                                ) : null}
                              </Button>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Form.Floating>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} className="spinner" />
          <span>Loading...</span>
        </div>
      )}
    </>
  );
}

export default HelloPrivacyScan;
