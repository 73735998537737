import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
function Policy() {
  return (
    <Container className="policy py-5">
      <Row>
        <Col md={12} className="my-4">
          <h4 className="pb-3">Privacy & Cookie Policy</h4>
          <p>
            We, Completed, explain in this Privacy and Cookie Policy (the
            “Policy”) how we collect, process and/or use information that we
            receive via our websites, emails we send, and mobile applications
            (collectively, the “Platform”) that link to this Policy, as amended
            from time to time. This Policy describes how Completed collects,
            processes, uses, shares and secures the personal information you
            provide. It also describes your choices regarding use, access, and
            correction of your personal information. For purposes of this
            Policy, personal information shall mean any information relating to
            an identified or identifiable natural person.
          </p>
        </Col>
        <Col md={12} className="mb-5">
          <h4 className="pb-3">Information We Collect</h4>
          <p>
            We collect information about you to provide our services. In order
            for us to best provide our services to you (and to help make it
            feasible for us to do so), it is essential that we are able to
            collect and use the information as described in this Policy. If you
            are a registered user of the Platform the data collection is largely
            necessary for operating our Platform or fulfilling our obligations,
            where neither of those is the case, alternative legal grounds serve
            as our legal basis for processing (i.e., consent, legal obligation,
            and legitimate interests—see details below). Information we collect,
            includes, but is not limited to: Full name;
          </p>
          <ul>
            <li>Address (e.g., postal, billing, shipping, etc.);</li>
            <li>Telephone number including cell number;</li>
            <li>Fax number;</li>
            <li>E-mail address;</li>
            <li>Date of birth;</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Policy;
