import React from 'react'

// Bootstrap Module
import { Container, Row, Col, Image } from "react-bootstrap";

// Css path
import "./style/Discover.scss";

// Image
import discover from "../../images/discover.png";

function Discover() {
    return (
        <div className="discoverBlock">
            <Container>
                <Row className='align-items-center'>
                    <Col lg={3} sm={5} className='text-center'>
                        <Image src={discover} alt="" />
                    </Col>
                    <Col lg={9} sm={7}>
                        <div className="discoverContent">
                            <h3>What are you waiting for? Discover your privacy risks now.</h3>
                            <p>Trusted by nearly a million people. Takes 30 seconds. No credit card required.</p>
                            <a className = "scan_link" onClick={() => {
                                window.scrollTo({ top: 130, left: 10, behavior: 'smooth' });
                            }}>Free Scanning My Name</a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Discover