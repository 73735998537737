
import api from "./api";

const sentiment = {
	
	sentiment(params) {
		return api.post("ipv/sentiment",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default sentiment;