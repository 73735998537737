import api from "./api";

const register = {
	
	register(params) {
		return api.post("register",{device_id:localStorage.getItem('deviceId'),...params})
	},
	guestUserRegistration(params) {
		return api.post("guestUserRegistration",{device_id:localStorage.getItem('deviceId'),...params})
	},
};

export default register;