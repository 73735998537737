import React, { useEffect, useState } from "react";
import { Modal, FloatingLabel, Form } from 'react-bootstrap'
import GlobalService from "../services/globalService";
import { NavLink, useNavigate } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from '../apis';
import { toast } from 'react-toastify';

function ScheduleModal(props) {

  const userData = GlobalService.userData;
  const navigate = useNavigate();

  const [fname, setFname] = useState(userData !== null ? userData.display_first_name : '');
  const [lname, setLname] = useState(userData !== null ? userData.display_last_name : '');
  const [email, setEmail] = useState(userData !== null ? userData.email : '');
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleFirstName = (e) => {
    setFname(e.target.value);
  }

  const handleLastName = (e) => {
    setLname(e.target.value);
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  }

  const apiCall = () => {
    setShowLoader(true)
    let payload = {

      email: email.trim(),
      first_name: fname.trim(),
      last_name: lname.trim(),
      message: message.trim()
    }


    const response = api.freeConsultation.freeConsultation(payload).then((response) => {
      console.log(response);
      if (response.data.status) {
        setShowLoader(false);
        toast.success(response.data.message);
        // navigate('/app/dashboard')
        props.onHide();
      }
      else {
        setShowLoader(false);
        toast.error(response.data.message);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });
  }

  const doSubmit = (e) => {
    e.preventDefault();
    let nameRegex = /^[a-zA-Z ]+$/;
    setMessage("");

    if (fname != '' && !nameRegex.test(fname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(fname)) {
      console.log('valid name');
    }
    if (lname != '' && !nameRegex.test(lname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(lname)) {
      console.log('valid name');
    }
    if (message != '') {
      if (message.length > 3) {
        apiCall();
        setDisabled(false);
      } else {
        toast.error("The message must be more than three characters")
        // setDisabled(true);
      }
    } else {
      toast.error("The message field cannot be empty.")
    }
  }
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Free Consultation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mb-4 modal-sub-head">Schedule your free 30-minute online privacy consultation</h4>
        <FloatingLabel
        controlId="floatingInput"
        label="Email address"
        className="mb-3"
      >
        <Form.Control 
        type="email" 
        placeholder="Email" 
        value={email}
        onChange={(e) => {
          handleEmail(e);
        }}/>
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="First name" className="mb-3">
        <Form.Control 
        type="text" 
        placeholder="First name"
        value={fname}
        onChange={(e) => {
          handleFirstName(e);
        }} />
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Last name" className="mb-3">
        <Form.Control 
        type="text" 
        placeholder="Last name!" 
        value={lname}
        onChange={(e) => {
          handleLastName(e);
        }}/>
      </FloatingLabel>
      <FloatingLabel controlId="floatingPassword" label="Message" className="mb-3">
       <textarea 
       className='form-control' 
       placeholder='Message' 
       style={{height:120, resize:'none'}}
       value={message}
       onChange={(e) => {
         handleMessage(e);
       }}>

       </textarea>
       </FloatingLabel>
       <button 
       className="form-control my-4 btn btn-primary py-2"
       type="submit"
       onClick={doSubmit}
       >Submit
        {showLoader ?
                <FontAwesomeIcon icon={faSpinner} className="spinner" />
                : null}
        </button>
      </Modal.Body>
      
    </Modal>
  )
}

export default ScheduleModal