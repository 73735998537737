import React from 'react'
import { NavLink } from 'react-router-dom'

function AuthFooter() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div className='auth-footer text-center'>
        {/* <ul>
            <li><NavLink>About</NavLink></li>
            <li><NavLink to= "/policy">Privacy</NavLink></li>
            <li><NavLink>Terms</NavLink></li>
        </ul> */}
        <p>&copy; Copyright {year} InternetPrivacy. All rights reserved.</p>
    </div>
  )
}

export default AuthFooter