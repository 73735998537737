import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, FloatingLabel } from "react-bootstrap";
import api from '../apis'
import GlobalService from "../services/globalService";
import { NavLink, useNavigate } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideBar from "./sidebar/SideBar";
import { toast } from 'react-toastify';


function Consultation() {
  const userData = GlobalService.localStorageData;
  const [fname, setFname] = useState(userData !== null ? userData.display_first_name : '');
  const [lname, setLname] = useState(userData !== null ? userData.display_last_name : '');
  const [email, setEmail] = useState(userData !== null ? userData.email : '');
  const [message, setMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const navigate = useNavigate();


  const handleEmail = (e) => {
    setEmail(e.target.value);
  }

  const handleFirstName = (e) => {
    setFname(e.target.value);
  }

  const handleLastName = (e) => {
    setLname(e.target.value);
  }

  const handleMessage = (e) => {
    setMessage(e.target.value);
  }

  const apiCall = () => {
    setShowLoader(true)
    let payload = {

      email: email.trim(),
      first_name: fname.trim(),
      last_name: lname.trim(),
      message: message.trim()
    }


    const response = api.freeConsultation.freeConsultation(payload).then((response) => {
      console.log(response);
      if (response.data.status) {
        setShowLoader(false);
        toast.success(response.data.message);
        navigate('/app/dashboard')
      }
      else {
        setShowLoader(false);
        toast.error(response.data.message);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });
  }

  const doSubmit = (e) => {
    e.preventDefault();
    let nameRegex = /^[a-zA-Z ]+$/;



    if (fname != '' && !nameRegex.test(fname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(fname)) {
      console.log('valid name');
    }


    if (lname != '' && !nameRegex.test(lname)) {
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(lname)) {
      console.log('valid name');
    }


    if (message != '') {

      if (message.length > 3) {

        apiCall();
        setDisabled(false);

      } else {
        toast.error("The message must be more than three characters")
        // setDisabled(true);
      }


    } else {
      toast.error("The message field cannot be empty.")
    }

  }

  return (
    <div className="container-fluid pl-0 free-consultant-page-container">
      <Row>
          <Col lg={2} md={3} className="px-0">
            <div className="sticky-head border-0 sideBarStick">
              <SideBar/>
            </div>
          </Col>
          <Col lg={10} md={9} className="pl-4 contentBoxing">
            <Container>
              <Row className="my-4 pb-3">
                <Col md={12} className="d-none d-md-block">
                  <h2> Free Consultation </h2>
                </Col>
              </Row>
              <Row>
                <Col md={8} className="m-auto" >
                  <h4 className="mb-4 modal-sub-head">Schedule your free 30-minute online privacy consultation</h4>
                  <Form className="mb-5">
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="email"
                          type="email"
                          placeholder="Email address"
                          value={email}
                        // onChange={(e) => {
                        //   handleEmail(e);
                        // }}
                        />
                        <label htmlFor="email">Email address</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="fname"
                          type="text"
                          maxLength={25}
                          placeholder="First name"
                          value={fname}
                          onChange={(e) => {
                            handleFirstName(e);
                          }}
                        />
                        <label htmlFor="email">First name</label>
                      </Form.Floating>
                    </Form.Group>
                    <Form.Group>
                      <Form.Floating className="mb-4">
                        <Form.Control
                          name="lname"
                          maxLength={25}
                          type="text"
                          placeholder="Last name"
                          value={lname}
                          onChange={(e) => {
                            handleLastName(e);
                          }}
                        />
                        <label htmlFor="email">Last name</label>
                      </Form.Floating>
                    </Form.Group>

                    <Form.Group>
                      <FloatingLabel controlId="floatingPassword" label="Message" className="mb-3">
                        <textarea
                          className='form-control'
                          placeholder='Message'
                          style={{ height: 120, resize: 'none' }}
                          value={message}
                          onChange={(e) => {
                            handleMessage(e);
                          }}>

                        </textarea>
                      </FloatingLabel>
                    </Form.Group>
                    <button
                      type="submit"
                      class="form-control my-4 btn btn-primary py-2 btn-theme"
                      // disabled={disabled}
                      onClick={doSubmit}
                    >Submit
                      {showLoader ?
                        <FontAwesomeIcon icon={faSpinner} className="spinner" />
                        : null}

                    </button>
                  </Form>



                </Col>
              </Row>
            </Container>
          </Col>
        
      </Row>
    </div>
  );
}

export default Consultation;
