import api from "./api";

const forgetPassword = {
	
	forgetPassword(params) {
		return api.post("user-reset-pass",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default forgetPassword;