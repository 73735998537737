import React, { useEffect, useState } from "react";
import { Button, Container, NavLink, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { faPencil, faPlus, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideBar from "../../components/sidebar/SideBar";
import { faSpinner, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import visa from "../../images/Visa_logo.png";

import api from "../../apis";
import Swal from "sweetalert2";  
import { toast } from "react-toastify";

function PlanStatus() {
  const navigate = useNavigate();

  const [subscriptionData, setSubscriptionData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));


  useEffect(() => {  
    getUserPaddleSubscription();
  }, []);

  const getUserPaddleSubscription = () => {
    let payload = {
        user_id: user_data.id
    }

    const response = api.paddleSubscription.getUserPaddleSubscription(payload).then((res) => {
        console.log(res);

        if(res.data.data_count == 0) {
            setSubscriptionStatus(false);
            navigate('/app/paddle-subscription');
        } else {
            setSubscriptionStatus(true);
            setSubscriptionData(res.data.data);
            setPaymentData(res.data.payment.payment_details);
            setCustomerData(res.data.customer.response);
            setAddressData(res.data.address.response);
            setProductData(res.data.product_name);
            localStorage.setItem('paddle_subscription_id',res.data.data.subscription_id)
            //setShowLoader(false);

            setTimeout(async () => {
              setShowLoader(false);
            }, 3000); 
        }
        
    }).catch((err) => {
        //setShowLoader(false);
        console.log(err);
        window.location.reload();
    });
  }

  const cancelSubscription = () => {
    Swal.fire({
        title: 'Do you really want to cancel the subscription?',
        icon: 'warning',
        showCancelButton: true,
        reverseButtons : true,
        confirmButtonText: 'Yes, Cancel',
        cancelButtonText: 'No',
        confirmButtonColor: "#f63",
        cancelButtonColor: "#b9b9b9",
    }).then((result) => {
        if(result.value) {
            //Swal.close();
            //navigate('/app/monitoring');

            let payload = {
              subscription_id: localStorage.getItem('paddle_subscription_id')
            }

            const response = api.paddleSubscription.cancelPaddleSubscription(payload).then((response) => {
              console.log(response);
              console.log(response.data.data.status);
              if (response.data.data.status == 'SUCCESS') {
                let result = JSON.parse(response.data.data.response);
                console.log(result.data);
                // setProduct(result.data);  
                // setShowData(true);
                //setShowLoader(false);

                toast.success('Subscription Cancelled');
                navigate('/app/paddle-subscription');
              } else {
                toast.error('Something went wrong. Please try after sometime.');
              }
            }).catch((err) => {
              console.log(err)
            });
        }
    });
  }

  return (
    <>
      <div className="current_plan">
        <Container fluid>
          <Row>
            <Col lg={2} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                <SideBar />
              </div>
            </Col>
            <Col lg={10} className="pl-4 contentBoxing current_plan_boxing">
              {showLoader ? (
                  <div className="loader">
                      <FontAwesomeIcon icon={faSpinner} className="spinner" />
                      <span>Loading...</span>
                  </div>
                  ) : (
                    <>
                      <div className="container my-4">
                        <Row className="my-4 pb-3">
                          <Col
                            md={12}
                            xs={12}
                            className="d-none d-md-block planHeading"
                          >
                            <h2>CURRENT PLAN</h2>
                            <hr />
                          </Col>
                          <Col lg={12} md={12} className="py-4">
                            <Row className="justify-content-between">
                              <Col lg={4}>
                                <div className="cardDetailsPart">
                                  <h3>{productData}</h3>
                                  <h2>${subscriptionData.price_amount} per  {JSON.parse(subscriptionData.billing_cycle).interval}</h2>
                                  <p>Your plan renews on {moment(JSON.parse(subscriptionData.billing_period).ends_at).format("MMMM DD, YYYY")}.</p>
                                  <h4>
                                    <span>
                                      <img src={visa} width={40} />
                                    </span>{" "}
                                    Visa •••• {JSON.parse(paymentData)[0]['method_details'].card.last4}{" "}
                                    {/* <a href="javascript(0);">
                                      <FontAwesomeIcon icon={faPencil} />
                                    </a> */}
                                  </h4>
                                </div>
                              </Col>
                              <Col
                                lg={2}
                                className="justify-content-end d-flex align-items-start"
                              >
                                <Button variant="outline-secondary cancelButton" className="w-100 shadow p-3 mt-3 bg-white rounded" onClick={cancelSubscription}>
                                  Cancel Plan
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-4 pb-3">
                          <Col
                            md={12}
                            xs={12}
                            className="d-none d-md-block planHeading"
                          >
                            <h2>PAYMENT METHOD</h2>
                            <hr />
                          </Col>
                          <Col lg={12} md={12} className="py-4">
                            <Row className="justify-content-between">
                              <Col lg={5}>
                                <div className="d-flex justify-content-between">
                                  <div className="cardDetailsPart">
                                    <h4>
                                      <span>
                                        <img src={visa} width={40} />
                                      </span>{" "}
                                      Visa •••• {JSON.parse(paymentData)[0]['method_details'].card.last4}
                                    </h4>
                                    {/* <a href="javascript(0);" className="shadow p-3 mt-3 bg-white rounded">
                                      <FontAwesomeIcon icon={faPlus} /> Add payment
                                      method
                                    </a> */}
                                  </div>
                                  <div className="expires">
                                    <p>Expires {JSON.parse(paymentData)[0]['method_details'].card.expiry_month}/{JSON.parse(paymentData)[0]['method_details'].card.expiry_year}</p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-4 pb-3">
                          <Col
                            md={12}
                            xs={12}
                            className="d-none d-md-block planHeading"
                          >
                            <h2>BILLING INFORMATION</h2>
                            <hr />
                          </Col>
                          <Col lg={12} md={12} className="py-4">
                            <Row className="justify-content-between">
                              <Col lg={4}>
                                <div className="cardDetailsPart">
                                  <table className="table table-borderless billing-info-table">
                                    <tbody>
                                      <tr>
                                        <td className="text-secondary"><span>Name</span></td>
                                        <td>{user_data.first_name} {user_data.last_name}</td>
                                      </tr>
                                      <tr>
                                        <td className="text-secondary"><span>Email</span></td>
                                        <td>{JSON.parse(customerData).data.email}</td>
                                      </tr>
                                      <tr>
                                        <td className="text-secondary"><span>Billing address</span></td>
                                        <td>{JSON.parse(addressData).data.country_code}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <a href="javascript(0);" className="shadow p-3 mt-3 bg-white rounded d-inline">
                                    <FontAwesomeIcon icon={faPencil} /> Update
                                    information
                                  </a> */}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="my-4 pb-3">
                          <Col
                            md={12}
                            xs={12}
                            className="d-none d-md-block planHeading"
                          >
                            <h2>INVOICE HISTORY</h2>
                            <hr />
                          </Col>
                          <Col lg={12} md={12} className="py-4">
                            <Row className="justify-content-between">
                              <Col lg={6}>
                                <div className="cardDetailsPart">
                                  <table className="table table-borderless">
                                    <tbody>
                                      <tr>
                                        <td>{moment(subscriptionData.created_at).format("MMMM DD, YYYY")} 
                                          {/* <a href="javascript:;"><FontAwesomeIcon icon={faArrowUpRightFromSquare} /></a> */}
                                        </td>
                                        <td>${subscriptionData.price_amount}</td>
                                        <td><span className="bg-success border py-1 px-2 text-white rounded">Paid</span></td>
                                        <td>{productData}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default PlanStatus;