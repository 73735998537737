
import React from "react";

// Sections
import Header from "./header/Header";
import Banner from "./Banner";
import OnlineInfo from "./OnlineInfo";
import PersonalInfo from "./PersonalInfo";
import PrivacyBlock from "./PrivacyBlock";
import Discover from "./Discover";
import Footer from "./footer/Footer";

// Css
import "./StepForm.scss";

function ScanningStepOne() {
  
  return (
    <>
    <Header/>
    <Banner/>
    <OnlineInfo/>
    <PersonalInfo/>
    <PrivacyBlock/>
    <Discover/>
    <Footer/>

    </>
  );
}

export default ScanningStepOne;
