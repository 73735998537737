import React from 'react'

function SitePrivacyCard(props) {
  return (
    <a href={props.url}>
<div className="each-card">
                    <img src={props.logo} alt="" />
                    <div className="info">
                      {
                        props.status ?
                        <span className="tag">Exposed</span>
                        :
                        null
                      }
                     
                      <h4>{props.title}</h4>
                      <p>{props.content}</p>
                    </div>
                  </div>
    </a>
    
  )
}

export default SitePrivacyCard