import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Form, Dropdown } from "react-bootstrap";
import ReactFlagsSelect from "../assets/react-flags-select";
import api from "../apis";
import GlobalService from '../services/globalService';
import { NavLink, useNavigate } from "react-router-dom";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import localService from "../services/localService";
import { toast } from 'react-toastify';

// Sidebar include
import SideBar from "../components/sidebar/SideBar";

function Monitoring() {
  // const userData = GlobalService.userData;
  const userData =GlobalService.localStorageData;
  console.log(userData);
  const navigate = useNavigate();
  const [fname, setFname] = useState(userData !== null ? userData.display_first_name : '');
  const [lname, setLname] = useState(userData !== null ? userData.display_last_name : '');
  const [age, setAge] = useState(userData !== null ? userData.age : '');
  const [phone, setPhone] = useState(userData !== null ? userData.phone_number : '');

  const [addressLine1, setAddressline1] = useState(userData !== null ? userData.address_line_1 : '');
  const [addressLine2, setAddressline2] = useState(userData !== null ? userData.address_line_2 : '');
  const [zip, setZip] = useState(userData !== null ? userData.zipcode : '');

  const country = userData !== null ? userData.country_code : '';
  const statename = userData != null ? userData.state_name == '' ? 'Select State' : userData.state_name : '';
  const cityname = userData != null ? userData.city_name == '' ? 'Select city' : userData.city_name : '';


  const [selectedCountry, setSelectedCountry] = useState(country);
  const [stateData, setStateData] = useState([]);
  const [stateName, setStateName] = useState(statename);
  const [stateNameId, setStateNameId] = useState('');
  const [showLoader, setShowLoader] = useState(false)
  const [cityData, setCityData] = useState([]);
  const [cityName, setCityName] = useState(cityname);
  const [cityNameId, setCityNameId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [editableFields, setEditableFields] = useState(false);
  const [updateAlloweData, setUpdateAlloweData] = useState({})

  let phoneTextInput = null;

  const phoneRef = useRef();
  const zipcodeRef = useRef();

  useEffect(() => {
    checkManageMonitoring();
    stateLoad(selectedCountry, false);

    if(userData !== null) {
      console.log(userData.phone_number);
      console.log(userData.zipcode);
      if(userData.phone_number == null) {
        phoneRef.current.focus();
      } else if(userData.zipcode == null) {
        zipcodeRef.current.focus();
      }
    }
    
  }, []);


  const handleFirstName = (e) => {
    setFname(e.target.value);
  }

  const handleLastName = (e) => {
    setLname(e.target.value);
  }

  const handleAge = (e) => {
    setAge(e.target.value);
  }

  const handlePhone = (e) => {
    setPhone(e.target.value);
  }

  const handleAddressLine1 = (e) => {
    setAddressline1(e.target.value);
  }

  const handleAddressLine2 = (e) => {
    setAddressline2(e.target.value);
  }

  const handleZip = (e) => {
    setZip(e.target.value);
  }

  const stateLoad = (countrycode, flag) => {
    let newArray = [];
    setSelectedCountry(countrycode); // **


    setStateData([]);
    setCityData([]);
    {
      flag == false ?
      setStateName(userData.state_name) : setStateName("Select State")
    }
    {
      flag == false ?
      setStateNameId(userData.state) : setStateNameId("")
    }
    // setStateNameId("");
    // setCityNameId("");


    let data = {
      country_code: countrycode,
    };

    setShowLoader(true);
    const response = api.states.states(data).then((response) => {


      if (response.data.status) {
        setShowLoader(false);

        newArray = response.data.data.map((item) => {
          return { id: item.id, name: item.name };
        });
        setStateData(newArray);
        {
          flag == false ?
          cityLoad(userData.state) :
          setCityName("Select city")
        }
        {
          flag == false ?
          setCityNameId(userData.city) : setCityNameId("")
        }
      } else {
        toast.error(response.data.message);
        setShowLoader(false);
        //     // setStateData(newArray);
        //     // stateName(statename);
        //     // stateNameId(userData.state)
        //     // cityLoad(userData.state)
      }
    });
  };

  const cityLoad = (statecode) => {
    let data = {
      state_id: statecode,
    };
    setShowLoader(true);
    const response = api.city.city(data).then((response) => {

      if (response.data.status) {
        setShowLoader(false);

        let newArray = response.data.data.map((item) => {
          return { id: item.id, name: item.name };
        });
        setCityData(newArray);
      } else {
        console.log(response.data.message);
        setShowLoader(false);
      }
    });
  };


  const confirmMonitoringData = (e) => {
    e.preventDefault();
    let error = false;
    let nameRegex = /^[a-zA-Z ]+$/;

    let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;


    if (fname != '' && !nameRegex.test(fname)) {
      error = true;
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(fname)) {
      console.log('valid name');
    }


    if (lname != '' && !nameRegex.test(lname)) {
      error = true;
      toast.error('Please enter a valid Name');
      return false;
    }
    else if (nameRegex.test(lname)) {
      console.log('valid name');
    }


    if (phone != '' && !phoneRegex.test(phone)) {
      error = true;
      toast.error('Please enter a valid phone number');
      return false;
    }
    else if (phoneRegex.test(phone)) {
      console.log('valid phone');
    }

 
    if (stateData.length !== 0 && stateNameId === '') {
      error = true;
      toast.error('You must select a state');
    }
    else if (cityData.length !== 0 && cityNameId === '') {

      error = true;
      toast.error('You must select a city');
    }
    if (error === false) {

      updateManageMonitoringdata()

    } else {
      return false;
    }
  }

  const updateManageMonitoringdata = (e) => {


    setShowLoader(true);
    let payload = {
      first_name: typeof fname == 'string' ? fname.trim() : fname,
      last_name: typeof lname == 'string' ? lname.trim() : lname,
      country_code: selectedCountry,
      state_id: stateNameId ? stateNameId : null,
      city_id: cityNameId ? cityNameId : null,
      age: age,
      phone_number: phone,
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      zipcode: zip

    }
    const response = api.updateManageMonitoring.updateManageMonitoring(payload).then((response) => {

      if (response.data.status) {
        setShowLoader(false);
        toast.success(response.data.message);
        GlobalService.userData = response.data.data.user_data;
        localService.setUserData(response.data.data.user_data);
        navigate('/app/dashboard');
      }
      else {
        toast.error(response.data.message);
        setShowLoader(false);
      }

    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      toast.error(err.message);
    });

  }

  const checkManageMonitoring = () => {
    
    const response = api.userCheckMonitoring.get().then((res) => {
      if (res.data.status !== null) {
        setUpdateAlloweData(res.data)
        if (res.data.code != undefined && res.data.code === 'update_allowed') {
          setEditableFields(true);
        } else {
          setEditableFields(false);
          setDisabled(true);
        }
      }

      if (res.data.code != undefined && res.data.code === 'update_not_allowed') {
        setEditableFields(false);
        setDisabled(true);

      } else {
        setDisabled(false);
      }


      /*For testing purpose only, delete once testing is done */
      // setEditableFields(true);
      // setDisabled(false);
      /*For testing purpose only, delete once testing is done */
      
    }).catch((err) => {
      setShowLoader(false);
      toast.error(err.message);
    });
  }


  // State filter constant
  const [selectedState, setselectedState] = useState({})
  const [dropdownSearchValue, setDropdownSearchValue] = useState("")
  const [editMode, setEditMode] = useState(false)
  // City filter constant
  const [selectedCity, setselectedCity] = useState({})


  const dropdownRef = useRef();

  

  /**
   * Close the dropdown when clicked outside
   * Refer https://www.codingdeft.com/posts/react-on-click-outside/ for details
   */
  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        editMode &&
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target)
      ) {
        setEditMode(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [editMode])

  const stateSelectionHandler = list => {
    setselectedState(list)
    setDropdownSearchValue("")
    setEditMode(false)
  }

  const filteredState = stateData.filter(list =>
    list.name.match(new RegExp(dropdownSearchValue, "i"))
  )

  const filteredCity = cityData.filter(list =>
    list.name.match(new RegExp(dropdownSearchValue, "i"))
  )

  return (
    <>
    <div className="container-fluid pl-0 free-consultant-page-container">
      <Row>
          <Col lg={2} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                <SideBar/>
              </div>
          </Col>

          <Col lg={10} className="pl-4">
            <Container>
              <Row className="my-4 pb-3 contentBoxing">
                <Col md={12} className="d-none d-md-block">
                  <h2>Manage Monitoring</h2>
                  {!updateAlloweData.status ?
                    <div>
                      <h5 style={{ color: 'red', textAlign: 'center' }}>{updateAlloweData.message}</h5>
                    </div> : null}
                </Col>
              </Row>
              <div className="m-auto col-md-8" disabled={disabled}>
              {/* <div className="m-auto col-md-8"> */}
                <Row className="csc-form">
                    <Col sm={6}>
                        <Form.Group>
                          <Form.Floating className="mb-4">
                            <Form.Control
                              name="fname"
                              type="text"
                              placeholder="First name"
                              value={fname}
                              onChange={(e) => {
                                handleFirstName(e);
                              }}
                            />
                            <label htmlFor="email">First name</label>
                          </Form.Floating>
                        </Form.Group>
                    </Col>

                    <Col sm={6}>
                        <Form.Group>
                          <Form.Floating className="mb-4">
                            <Form.Control
                              name="lname"
                              type="text"
                              placeholder="Last name"
                              value={lname}
                              onChange={(e) => {
                                handleLastName(e);
                              }}
                            />
                            <label htmlFor="email">Last name</label>
                          </Form.Floating>
                        </Form.Group>
                    </Col>

                    <Col sm={8}>
                      <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="phone"
                            type="text"
                            placeholder="Phone Number"
                            value={phone}
                            ref={phoneRef}
                            onChange={(e) => {
                              handlePhone(e);
                            }}
                          />
                          <label htmlFor="phone">Phone Number</label>
                        </Form.Floating>
                      </Form.Group> 
                    </Col>
                    
                    <Col sm={4}>
                      <Form.Group>
                          <Form.Floating className="mb-4">
                            <Form.Control
                              name="age"
                              type="number"
                              placeholder="Age"
                              value={age}
                              onChange={(e) => {
                                handleAge(e);
                              }}
                            />
                            <label htmlFor="age">Age</label>
                          </Form.Floating>
                        </Form.Group>
                    </Col>

                    <Col sm={12}>
                      <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="addressline1"
                            type="text"
                            placeholder="Address Line 1"
                            value={addressLine1}
                            onChange={(e) => {
                              handleAddressLine1(e);
                            }}
                          />
                          <label htmlFor="addressline1">Address Line 1</label>
                        </Form.Floating>
                      </Form.Group>
                    </Col>


                    <Col sm={12}>
                      <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="addressline2"
                            type="text"
                            placeholder="Address Line 2"
                            value={addressLine2}
                            onChange={(e) => {
                              handleAddressLine2(e);
                            }}
                          />
                          <label htmlFor="addressline2">Address Line 2</label>
                        </Form.Floating>
                      </Form.Group>
                    </Col>

                    <Col sm={8}>
                      <ReactFlagsSelect
                          selected={selectedCountry}
                          searchable={true}
                          searchPlaceholder="Search"
                          onSelect={(code) => {
                            stateLoad(code, true);
                          }}
                          className="mb-4"
                      />
                    </Col>

                    <Col sm={4}>
                      <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="zip"
                            type="text"
                            placeholder="Zip"
                            value={zip}
                            ref={zipcodeRef}
                            onChange={(e) => {
                              handleZip(e);
                            }}
                          />
                          <label htmlFor="zip">Zip</label>
                        </Form.Floating>
                      </Form.Group>
                    </Col>

                    {stateData.length > 0 && (
                      <Col sm={12}>
                        <Dropdown ref={dropdownRef} className="mb-4">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              {stateName}
                            </Dropdown.Toggle>


                            <Dropdown.Menu>

                              <div className="search-country">
                                <input
                                  placeholder="Search State"
                                  autoFocus
                                  onChange={e => setDropdownSearchValue(e.target.value)}
                                  value={dropdownSearchValue}
                                  className="sticky-top"
                                />
                              </div>
                              {filteredState.map(data => {
                                return (
                                  <Dropdown.Item key={data.id}
                                    onClick={() => {
                                      setStateName(data.name)
                                      setStateNameId(data.id)
                                      setCityName('Select City')
                                      setCityNameId("")
                                      cityLoad(data.id)
                                      // handleState(data)
                                      stateSelectionHandler(data)
                                    }}
                                  >
                                    {data.name}
                                  </Dropdown.Item>
                                )
                              })}
                              {filteredState.length === 0 && (
                                <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                      </Col>
                    )}

                    {cityData.length > 0 && (
                      <Col sm={12}>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              {cityName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                              <div className="search-country">
                                <input
                                  placeholder="Search City"
                                  autoFocus
                                  onChange={e => setDropdownSearchValue(e.target.value)}
                                  value={dropdownSearchValue}
                                  className="sticky-top"
                                />

                              </div>
                              {filteredCity.map((data) => {
                                return (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setCityName(data.name);
                                      setCityNameId(data.id);

                                    }}
                                    key={data.id}
                                  >
                                    {data.name}
                                  </Dropdown.Item>
                                );
                              })}
                              {filteredCity.length === 0 && (
                                <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                              )}

                            </Dropdown.Menu>
                          </Dropdown>
                      </Col>
                    )}

                      <button
                        type="submit"
                        class="form-control my-4 btn btn-primary py-2 btn-theme"
                        onClick={confirmMonitoringData}
                      >Update
                        {showLoader ?
                          <FontAwesomeIcon icon={faSpinner} className="spinner" />
                          : null}
                      </button>
                </Row>






                <Row>
                  <Col md={8} className="m-auto" >
                    <Form className="mb-5 csc-form">
                      {/* <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="fname"
                            type="text"
                            placeholder="First name"
                            value={fname}
                            onChange={(e) => {
                              handleFirstName(e);
                            }}
                          />
                          <label htmlFor="email">First name</label>
                        </Form.Floating>
                      </Form.Group>
                      <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="lname"
                            type="text"
                            placeholder="Last name"
                            value={lname}
                            onChange={(e) => {
                              handleLastName(e);
                            }}
                          />
                          <label htmlFor="email">Last name</label>
                        </Form.Floating>
                      </Form.Group> */}

                      {/* <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="age"
                            type="number"
                            placeholder="Age"
                            value={age}
                            onChange={(e) => {
                              handleAge(e);
                            }}
                          />
                          <label htmlFor="age">Age</label>
                        </Form.Floating>
                      </Form.Group> */}

                      {/* <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="phone"
                            type="text"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => {
                              handlePhone(e);
                            }}
                          />
                          <label htmlFor="phone">Phone Number</label>
                        </Form.Floating>
                      </Form.Group>       */}

                      {/* <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="addressline1"
                            type="text"
                            placeholder="Address Line 1"
                            value={addressLine1}
                            onChange={(e) => {
                              handleAddressLine1(e);
                            }}
                          />
                          <label htmlFor="addressline1">Address Line 1</label>
                        </Form.Floating>
                      </Form.Group>   */}

                      {/* <Form.Group>
                        <Form.Floating className="mb-4">
                          <Form.Control
                            name="addressline2"
                            type="text"
                            placeholder="Address Line 2"
                            value={addressLine2}
                            onChange={(e) => {
                              handleAddressLine2(e);
                            }}
                          />
                          <label htmlFor="addressline2">Address Line 2</label>
                        </Form.Floating>
                      </Form.Group> */}

                      {/* <Form.Group>
                        <ReactFlagsSelect
                          selected={selectedCountry}
                          searchable={true}
                          searchPlaceholder="Search"
                          onSelect={(code) => {
                            stateLoad(code, true);
                          }}
                          className="mb-2"
                        />
                        {stateData.length > 0 && (
                          <Dropdown ref={dropdownRef} className="mb-2">
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              {stateName}
                            </Dropdown.Toggle>


                            <Dropdown.Menu>

                              <div className="search-country">
                                <input
                                  placeholder="Search State"
                                  autoFocus
                                  onChange={e => setDropdownSearchValue(e.target.value)}
                                  value={dropdownSearchValue}
                                  className="sticky-top"
                                />
                              </div>
                              {filteredState.map(data => {
                                return (
                                  <Dropdown.Item key={data.id}
                                    onClick={() => {
                                      setStateName(data.name)
                                      setStateNameId(data.id)
                                      setCityName('Select City')
                                      setCityNameId("")
                                      cityLoad(data.id)
                                      // handleState(data)
                                      stateSelectionHandler(data)
                                    }}
                                  >
                                    {data.name}
                                  </Dropdown.Item>
                                )
                              })}
                              {filteredState.length === 0 && (
                                <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}



                        {cityData.length > 0 && (
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">

                              {cityName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>

                              <div className="search-country">
                                <input
                                  placeholder="Search City"
                                  autoFocus
                                  onChange={e => setDropdownSearchValue(e.target.value)}
                                  value={dropdownSearchValue}
                                  className="sticky-top"
                                />

                              </div>
                              {filteredCity.map((data) => {
                                return (
                                  <Dropdown.Item
                                    onClick={() => {
                                      setCityName(data.name);
                                      setCityNameId(data.id);

                                    }}
                                    key={data.id}
                                  >
                                    {data.name}
                                  </Dropdown.Item>
                                );
                              })}
                              {filteredCity.length === 0 && (
                                <Dropdown.Item className="no-result">No results found</Dropdown.Item>
                              )}

                            </Dropdown.Menu>
                          </Dropdown>

                        )}
                      </Form.Group> */}

                      {/* <Form.Group>
                        <Form.Floating className="mb-4 mt-4">
                          <Form.Control
                            name="zip"
                            type="text"
                            placeholder="Zip"
                            value={zip}
                            onChange={(e) => {
                              handleZip(e);
                            }}
                          />
                          <label htmlFor="zip">Zip</label>
                        </Form.Floating>
                      </Form.Group> */}
                      {/* <button
                        type="submit"
                        class="form-control my-4 btn btn-primary py-2 btn-theme"
                        onClick={confirmMonitoringData}
                      >Update
                        {showLoader ?
                          <FontAwesomeIcon icon={faSpinner} className="spinner" />
                          : null}
                      </button> */}

                    </Form>

                  </Col>
                </Row>
              </div>
              {updateAlloweData.status ?
                    <div>
                      <h6 style={{ color: 'clack', textAlign: 'center' }}>{updateAlloweData.message}</h6>
                    </div> : null}

            </Container>
          </Col>
      </Row>
      <div style={{height: '100px'}}></div>
    </div>
    </>
    
  )
}

export default Monitoring
