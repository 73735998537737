
// WithoutNav.js (Stand-alone Functional Component)
import React from 'react';
import { Outlet } from 'react-router';
import AuthFooter from './AuthFooter';

export default () => {
    return(
        <>
            <Outlet />
            <AuthFooter />
        </>
    )
}