import React, { useEffect, useState } from "react";
import {
  Nav,
  Navbar,
  Button,
  Container,
  NavDropdown,
  Offcanvas,
  Form,
  Dropdown,
  Badge,
} from "react-bootstrap";
import { NavLink, Link, useNavigate } from "react-router-dom";
import "../../src/style.scss";
import Logo from "../images/logo_main.png";
import Notification from "../images/Notification.png";
import Cart from "../images/cart_new.png";
import Help from "../images/help.png";
import Cog from "../images/cog.png";
import crownIcon from "../images/IP_Crown_White.png";
import ScheduleModal from "./ScheduleModal";
import GlobalService from "../services/globalService";
import api from "../apis";

import { toast } from 'react-toastify';

function Header() {
  const unreadNotification = GlobalService.unreadNotification;
  const navigate = useNavigate();
  const [modalShow, setModalShow] = React.useState(false);
  const [showUpgradeButton, setShowUpgradeButton] = useState(true);
  const [hasSubscription, setHasSubscription] = useState(false);
  //const [url, setUrl] = useState(process.env.REACT_APP_PAYMENT_LINK);

  const [url, setUrl] = useState('/app/plans-and-billing');
  const [notificationCount, setNotificationCount] =
    useState(unreadNotification);

  useEffect(() => {
    // if (
    //   GlobalService.unreadNotification == undefined
    //   ||
    //   GlobalService.unreadNotification == null
    //   ||
    //   GlobalService.unreadNotification == 0
    //   ||
    //   GlobalService.unreadNotification == '') {
    //   if (!GlobalService.fromNotification) {
    getNotifications();
    getSubscriptionData();
    //   }
    // }
  }, []);

  const getNotifications = () => {
    let payload = {
      run_update: 0,
    };

    const response = api.notification
      .notification(payload)
      .then((response) => {
        console.log("res notification from header", response);
        if (response.data.status) {
          setNotificationCount(response.data.data.notice_count);
          GlobalService.unreadNotification = response.data.data.notice_count;
        } else {
          if (
            response.data.message != undefined &&
            response.data.message != ""
          ) {
            toast.info(response.data.message);
          } else {
            console.log("object");
          }
        }
      })
      .catch((err) => {
        console.log(err.data.message);
        console.log("from dashboard header", err);
      });
  };

  
  const getSubscriptionData = () => {
    var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));
    let payload = {
      user_id: user_data.id,
    }
  
    api.privacyApi.getSubscriptionData(payload).then(async (response) => {
      console.log(response);
      let subscriptionData = response.data.subscription_data;
      let flag = 0;
     
      if(subscriptionData.length > 0) {

        if(subscriptionData[subscriptionData.length - 1].status.toLowerCase() === "active") {
          setHasSubscription(true);

          if(subscriptionData[subscriptionData.length - 1].stripe_product_name.toLowerCase() === "executive") {
            //setShowUpgradeButton(false);
          }
        }


        /**************get the customer portal billing section link ********************/
        let billing_payload = {
          customer_id: subscriptionData[subscriptionData.length - 1].stripe_customer_id
        }
        api.privacyApi.createBillingPortalSession(billing_payload).then(async (res) => {
          if(res.data) {
            setUrl(res.data.data.url)
          }
          
        }).catch((err) => {
          console.log(err)
        });
        /**************get the customer portal billing section link ********************/ 
      }

    
    }).catch((err) => {
      console.log(err)
    });
  };
  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("privacyLength");
    localStorage.removeItem("localStorage_userData");
    localStorage.removeItem("localStorage_StPrice");
    localStorage.removeItem("localStorage_StPriceID");
    localStorage.removeItem("guest_user_data");
    localStorage.removeItem("guest_user_data_email");
    localStorage.removeItem("removal_request_applied_for_the_site_name_details");
    localStorage.removeItem("customer_current_saved_email");
    localStorage.removeItem('scan_page_results');
    localStorage.removeItem('show_results');
    localStorage.removeItem('exposed_site_data_counter');
    localStorage.removeItem('total_sites');
    localStorage.removeItem('total_site_scanned');
    localStorage.removeItem('remaining_site_scanned');
    navigate("/app/");

    // setIsLoggedIn(false);
  };

  return (
    <>
      <Navbar expand="lg" fixed="top" className="p-0 darkNav">
        <Container fluid className="px-3">
          <Navbar.Brand href="#">
            <NavLink to="/app/dashboard">
              <img src={Logo} className="logo" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
             {/* <NavLink className="my-nav" to="/dashboard">
                Home
              </NavLink> */}
               {/* <NavLink className="my-nav" to="/results">
                Results
              </NavLink>
              <NavLink
                className="my-nav"
                to="/privacy"
                //  style={({ isActive }) => ({
                //   color: isActive ? '#efefef' : '#545e6f',
                //   background: isActive ? '#7600dc' : '#efefef',
                // })}
              >
                Privacy
              </NavLink>
              <NavLink className="my-nav" to="/mention_screen">
                Mentions
              </NavLink>
              <NavLink
                // className="my-nav"
                // onClick={() => setModalShow(true)}
                // onHide={() => setModalShow(false)}>
                className="my-nav"
                to="/consultation"
              >
                Free Consultation
              </NavLink> */}
            </Nav>
            <div className="d-flex justify-content-between align-content-center">
            {showUpgradeButton && 
                <> 
                  {hasSubscription ? 
                  <a className="round upsBtn"
                    target="_blank" 
                    href={url}
                    //href="/app/paddle-subscription"
                    style={{
                      width: 150,
                      marginRight: 30,
                      position: "relative",
                      background: '#f63',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={crownIcon} width={23} alt='Upgrade Crown Icon' />
                    <h4 className="round d-flex upgrade-btn"
                    style={{
                      background: '#f63',
                      color: '#fff',
                      fontWeight: 'bold',
                      marginTop: '7px',
                      marginLeft: '10px'
                    }}>Upgrade</h4>
                  </a>
                  :<NavLink
                    className="round upsBtn"
                    //to="/app/paddle-subscription"
                    to={url}
                    style={{
                      width: 150,
                      marginRight: 30,
                      position: "relative",
                      background: '#f63',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img src={crownIcon} width={23} alt='Upgrade Crown Icon' />
                    <h4 className="round d-flex upgrade-btn"
                    style={{
                      background: '#f63',
                      color: '#fff',
                      fontWeight: 'bold',
                      marginTop: '7px',
                      marginLeft: '10px'
                    }}>Upgrade</h4>
                  </NavLink>
                  }
                </>
              }

              {/* <NavLink to="/app/PlanStatus">
                <h4 className="round d-flex upgrade-btn"
                    style={{
                      background: '#f63',
                      color: '#fff',
                      fontWeight: 'bold',
                      marginTop: '7px',
                      marginLeft: '10px'
                    }}>Plans Status</h4>
              </NavLink> */}

              <NavLink
                className="round"
                to="/app/notification"
                style={{
                  width: 45,
                  lineHeight: "35px",
                  textAlign: "center",
                  marginRight: 30,
                }}
              >
                <img src={Notification} className="noti-icon" id="badge-icon" />

                {/*  NOTE: notificationCount is setting after api call but it is not showing the updated result just after updation, takes some time thats why used the globalservice to show the notificationCount */}

                {GlobalService.unreadNotification != 0 && (
                  <Badge bg="danger" className="position-absolute">
                    {GlobalService.unreadNotification > 99
                      ? "99+"
                      : GlobalService.unreadNotification}
                  </Badge>
                )}
              </NavLink>
              
              

              {/* <NavLink
                className="round"
                to="/notification"
                style={{
                  width: 45,
                  lineHeight: "35px",
                  textAlign: "center",
                  marginRight: 30,
                }}
              >
                <img src={Help} className="noti-icon" id="badge-icon" />
              </NavLink> */}

              <Dropdown className="round d-flex">
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={Cog} className="noti-icon" /> My Account
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <NavLink to="/app/account_settings"> Account Settings </NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink to="/app/monitoring">Manage Monitoring</NavLink>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <NavLink to="/app/social">Social Accounts</NavLink>
                  </Dropdown.Item>
                  {hasSubscription 
                    ? <Dropdown.Item className="payment-link-container" target="_blank" href={url}>Plans & Billing</Dropdown.Item>
                    : <Dropdown.Item>
                        <NavLink to="/app/plans-and-billing">Plans & Billing</NavLink>
                      </Dropdown.Item>}
                  {/* <Dropdown.Item>
                    <NavLink to="/app/paddle-subscription">Plans & Billing</NavLink>
                  </Dropdown.Item>     */}
                  
                  <Dropdown.Item onClick={Logout}>
                    <NavLink> Sign Out</NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <ScheduleModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Header;
