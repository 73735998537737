


import api from "./api";

const cancelSubscribe = {
	
	cancelSubscribe(params) {
		return api.post("cancelSubscription",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default cancelSubscribe;




