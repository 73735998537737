import React from 'react'

// Bootstrap Module
import { Container, Row, Col, Image} from "react-bootstrap";

// Css path
import "./style/PersonalInfo.scss";

// Images Include
import protect from "../../images/protect.png";
import secure from "../../images/secure.png";
import monitor from "../../images/monitor.png";
import Identity from "../../images/idexntity-icon.png";
import spam from "../../images/spam.png";
import continual from "../../images/continual.png";


function PersonalInfo() {
  return (
    <div className="personal-info">
      <Container>
        <Row>
          <Col lg={12}>
            <h2 className='text-center'>We make it simple to find and remove your personal info:</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={4} xs={12} className="text-center">
            <div className="personalBox">
                <Image src={protect} alt="Protect your privacy" />
                <h3>Protect your privacy.</h3>
                <p>Remove your sensitive personal info from dozens of Data Broker sites that expose it online.</p>
            </div>
          </Col>
          <Col lg={4} sm={4} xs={12} className="text-center">
            <div className="personalBox">
                <Image src={secure} alt="Secure your identity"/>
                <h3>Secure your identity.</h3>
                <p>Keep your private data hidden from hackers, spammers, and anyone who could impact your life.</p>
            </div>
          </Col>
          <Col lg={4} sm={4} xs={12} className="text-center">
            <div className="personalBox">
                <Image src={monitor} alt="Monitor your private info"/>
                <h3>Monitor your private info.</h3>
                <p>Get alerts and automatic removal any time your private info appears in the future.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="link_button text-center">
                {/* <a href="javascript:;">Scan Name in Free</a> */}
                <a className = "scan_link" onClick={() => {
                                window.scrollTo({ top: 130, left: 10, behavior: 'smooth' });
                            }}>Scan Name in Free</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <h2 className='text-center'>Removing your personal information protects you from:</h2>
          </Col>
        </Row>
        <Row className='justify-content-between'>
          <Col lg={5} sm={6} xs={12}>
            <div className="iconBox">
                <Row>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={Identity} alt="Identity theft" />
                            <p><b>Identity theft.</b> Scammers use exposed info to impersonate people, ruin credit and max out cards, which costs time and money to repair.</p>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={spam} alt="Spam" />
                            <p><b>Spam, scams and ransomware.</b> Spammers find email addresses online and use them to disrupt people with spam, scams and viruses.</p>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={continual} alt="Continual re-exposure" />
                            <p><b>Continual re-exposure.</b> Data Brokers continually re-publish pieces of personally identifiable info, which shows up in Google.</p>
                        </div>
                    </Col>
                </Row>
            </div>
          </Col>
          <Col lg={5} sm={6} xs={12}>
            <div className="iconBox">
                <Row>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={spam} alt="Robocalls" />
                            <p><b>Robocalls.</b> Telemarketers find phone numbers online and use them to disrupt people with unsolicited calls.</p>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={Identity} alt="Stalkers, creeps and doxxers" />
                            <p><b>Stalkers, creeps and doxxers.</b> Stalkers use exposed addresses, phone numbers and relatives' names to track down and harass victims.</p>
                        </div>
                    </Col>
                    <Col lg={12}>
                        <div className="eache-iconBox d-flex align-items-center">
                            <Image src={continual} alt="Embarassing disclosures" />
                            <p><b>Embarassing disclosures.</b> Anyone who Googles you can find your divorce records, traffic violations, age, net worth, and more.</p>
                        </div>
                    </Col>
                </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PersonalInfo