import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, NavLink, Row, Card, } from "react-bootstrap";
import api from "../../apis";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterModal from "../FilterModal";
import GlobalService from "../../services/globalService";
import React1 from "../../images/react1.png";
import React2 from "../../images/react2.png";
import React3 from "../../images/react3.png";
import React4 from "../../images/react4.png";
import Filter from "../../images/filter.png";
import EmailVerify from "../../images/emailverify.png";
import { toast } from 'react-toastify';
// Sidebar include
import SideBar from "../../components/sidebar/SideBar";

import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import "../../style.scss";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

var counttrue = 0;
const userData = [
  { name: "Jeevan" },
  { name: "Manish" },
  { name: "Prince" },
  { name: "Arti" },
  { name: "rahul" },
];

function SelectAll() {
  const [users, setUsers] = useState([]);
  const [checkedBox, setCheckedBox] = useState(false);
  const [sentimentId, setSentimentId] = useState([]);
  const [searchResultLoader, setSearchResultLoader] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [displaySentimentText, setDisplaySentimentText] = useState("");
  const [showFilterArea, setShowFilterArea] = useState(false);
  const [title, setTitle] = useState("All Results");
  const [sortValue, setSortValue] = useState("all");
  const [sortLabel, setSortLabel] = useState("All");
  const [searchResultData, setSearchResultData] = useState({});
  const [sentimentLoader, setSentimentLoader] = useState(false);
  const [pressedKey, setPressedKey] = useState(false);

  useEffect(() => {
    onPageSearchResultLoad();
  }, []);

  const onPageSearchResultLoad = async () => {
    // e.preventDefault();
    setSearchResultData([]);

    if (GlobalService.filterSelectedLabel == "All") {
      setDisplaySentimentText("search");
    } else {
      setDisplaySentimentText(GlobalService.filterSelectedLabel);
    }
    // count = 0;
    // if (GlobalService.fromNotification) {
    // 	GlobalService.fromNotification = false;
    // 	this.loadSearchResult(GlobalService.authtoken);
    // } else {
    if (
      GlobalService.searchResult != "" &&
      GlobalService.searchResult != undefined &&
      GlobalService.searchResult != null
    ) {
      setSearchResultLoader(true);
      setSortValue(GlobalService.filterSelected);
      setSortLabel(GlobalService.filterSelectedLabel);
      setTimeout(async () => {
        setSearchResultData(GlobalService.searchResult);
        if (GlobalService.filterSelected != "all") {
          onChangeSortValue(
            GlobalService.filterSelected,
            GlobalService.filterSelectedLabel
          );
          // GlobalService.filterSelected = 'all';
          // GlobalService.filterSelectedLabel = 'All';
        }
        // else{
        //   console.log('apicall=======')
        //   apiCall();
        // }
        setSearchResultLoader(false);
      }, 800);
    } else {
      apiCall();
    }
    if (GlobalService.filterSelected == "all") {
      setSearchResultData(GlobalService.searchResult);
    }
  };

  const apiCall = () => {
    setSearchResultLoader(true);
    const response = api.dashboard
      .get()
      .then((response) => {
        if (response.data.status) {
          setSearchResultLoader(false);
          setSearchResultData(response.data.data.serpwow_results);
          GlobalService.searchResult = response.data.data.serpwow_results;
          // GlobalService.privacyResult = response.data.data.people_results;
          GlobalService.mentionResult = response.data.data.social_results;
          GlobalService.userDashboardAll = response.data.data.user;
        } else {
          setSearchResultLoader(false);
          // toast.error(response.data?.message || "Something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
        setSearchResultLoader(false);
        console.log("err.message", err.message);
        // toast.error(err.message);
      });
  };
  const onChangeSortValue = async (value, label) => {
    if (value == "all") {
      setDisplaySentimentText("search");
    } else {
      setDisplaySentimentText(label);
    }

    // console.log(value + '===' + label)
    if (label == "All") {
      label = "All Results";
    }

    setShowFilterArea(false);
    setTitle(label);
    await setSortValue(value);
    await setSortLabel(label);

    if (GlobalService.searchResult != "") {
      let dataObj = { ...GlobalService.searchResult };
      // let dataArray = [...GlobalService.searchResult];
      let data = [...GlobalService.searchResult.results];
      if (value == "ALL") {
        setSearchResultData(dataObj);
      } else {
        let newData = data.filter(function (item) {
          let name = item.sentiment;
          return name.indexOf(value) > -1;
        });
        console.log("newData", newData);
        if (newData.length > 0) {
          dataObj.results = newData;
          console.log("dataObj.results ", dataObj.results);
          setSearchResultData(dataObj);
        } else {
          if (value == "all") {
            setSearchResultData(dataObj);
          } else {
            dataObj.results = [];
            setSearchResultData([]);
          }
        }
      }
    }
    setModalShow(false);
  };

  const handleAllChange = (e) => {
    const { id, checked, name } = e.target;
    // console.log('e.target', id, checked, name)
    var _temp = [];
    setCheckedBox(checked);
    setPressedKey(checked);
    let dataObj = { ...searchResultData };
    let data = [...searchResultData.results];
    let tempUser = [];
    if (id === "allSelect") {
      if (checked == false || checked == true) {
        // whenever the allselect is checked the counter variable is reset everytime.
        counttrue = 0;
      }
      tempUser = data.map((user, i) => {
        if (checked) {
          counttrue++;
        }
        return { ...user, isChecked: checked };
      });
    } else {
      tempUser = data.map((user) => {
        if (user.key === id) {
          if (checked) {
            counttrue++;
          } else {
            counttrue--;
          }
          return { ...user, isChecked: checked };
        } else {
          return user;
        }
      });
    }

    // console.log('cccccccccc', counttrue)
    if (counttrue > 0) {
      setPressedKey(true);
    } else {
      setPressedKey(false);
    }

    if (tempUser.length > 0) {
      dataObj.results = tempUser;
      setSearchResultData(dataObj);
    }
    tempUser.map((obj, i) => {
      if (tempUser[i]["isChecked"] == true) {
        _temp.push(tempUser[i]["key"]);
        setSentimentId(_temp);
      }
    });
  };

  const handleSingleChange = (e) => {
    const { id, checked } = e.target;
    console.log("e.target", id, checked);
    var _temp = [];

    setCheckedBox(checked);
    let dataObj = { ...searchResultData };
    let data = [...searchResultData.results];
    let tempUser = data.map((user) => {
      return user.key === id ? { ...user, isChecked: checked } : user;
    });
    console.log("object");
    if (tempUser.length > 0) {
      dataObj.results = tempUser;
      // console.log('dataObj.results ', dataObj.results)
      setSearchResultData(dataObj);
    }

    tempUser.map((obj, i) => {
      // console.log('iiiiiii', i)
      if (tempUser[i]["isChecked"] == true) {
        _temp.push(tempUser[i]["key"]);
        setSentimentId(_temp);
      }
    });

    // console.log('idsss', sentimentId)
  };

  const changeSentiment = (sentiment, itemId, curSentiment) => {
    console.log(sentiment, itemId, curSentiment);
    doAction(sentiment, sentimentId, curSentiment);
  };
  const doAction = (sentiment, itemId, curSentiment) => {
    console.log("from doAction", sentiment, itemId, curSentiment);
    // console.log(sentiment, 'sentiment=============================', curSentiment, 'curSentiment',)

    if (sentiment != curSentiment) {
      let payload = {
        sentiment: sentiment,
        item_id: itemId,
      };
      //this.setState({ sentimentLoader: true });
      setSentimentLoader(true);
      const response = api.sentiment
        .sentiment(payload)
        .then(async (response) => {
          console.log("sentiment response", response);
          if (response.status) {
            setSentimentLoader(false);
            // const { searchResultData } = this.state;
            itemId.map((obj, i) => {
              let index = searchResultData.results.findIndex(
                (p) => p.key == obj
              );
              let index2 = GlobalService.searchResult.results.findIndex(
                (p) => p.key == obj
              );
              searchResultData["results"][index].sentiment = sentiment;
              GlobalService.searchResult["results"][index2].sentiment =
                sentiment;
            });
            //await this.setState(searchResultData);
            await setSearchResultData();
            onChangeSortValue(sortValue, sortLabel);
            toast.success(response.data.message);
            refresData();
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
          if (err.message != undefined) {
            console.log(err.message);
          }
        });
    } else {
      toast.info("Already Assigned!");
    }
  };

  const refresData = () => {
    searchResultData.results.map((obj, i) => {
      searchResultData.results[i]["isChecked"] = false;
    });
    setCheckedBox(false);
    setPressedKey(false);
  };
  const openLink = (url) => {
    console.log(url);
    // var win = window.open(url, '_blank');
    // win.focus();
    window.location.assign(url);
  };
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  // console.log('doaction after', searchResultData)
  return (
    <>
        <div className={searchResultLoader ? "container-fluid pl-0 with-skeleton" : "container-fluid pl-0 "}>
          <Row>
            <Col lg={2} md={3} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                {/* <h2 className="pb-3">Sidebar</h2> */}
                <SideBar/>
              </div>
            </Col>
            <Col lg={10} md={9} className="pl-4">
            {searchResultLoader ? (
              // <div className="loader">
              //   <FontAwesomeIcon icon={faSpinner} className="spinner" />
              //   <span>Loading...</span>
              // </div>
                <>
                    <Row className="my-4 pb-3 sticky-head">
                      <Col md={3} xs={6} className="d-none d-md-block">
                        <h2>Results</h2>
                      </Col>
                    </Row>
                    <ul className="all-results all-search-results">
                        <li><div class="shadow p-3 bg-white rounded result-list-card"><p><Skeleton /></p><p><Skeleton /></p></div></li>
                        <li><div class="shadow p-3 bg-white rounded result-list-card"><p><Skeleton /></p><p><Skeleton /></p></div></li>
                        <li><div class="shadow p-3 bg-white rounded result-list-card"><p><Skeleton /></p><p><Skeleton /></p></div></li>
                        <li><div class="shadow p-3 bg-white rounded result-list-card"><p><Skeleton /></p><p><Skeleton /></p></div></li>
                    </ul>
                  </>
            ) : (
              <>
                <Row className="my-4 pb-3 sticky-head">
                  <Col lg={12}>
                    <Row>
                      <Col md={3} xs={6} className="d-none d-md-block">
                        <h2>Results</h2>
                      </Col>
                      {sentimentLoader && (
                        <div className="loader fixed">
                          <FontAwesomeIcon icon={faSpinner} className="spinner" />
                          <span>Loading...</span>
                        </div>
                      )}
                      <Col md={5}>
                        {pressedKey ? (
                          <div className="mark-result">
                            <img
                              src={React1}
                              alt="Positive"
                              onClick={() =>
                                changeSentiment(
                                  "SENTIMENT_POSITIVE",
                                  "",
                                  "Positives"
                                )
                              }
                            />
                            <img
                              src={React2}
                              alt="Negative"
                              onClick={() =>
                                changeSentiment(
                                  "SENTIMENT_NEGATIVE",
                                  "",
                                  "Negatives"
                                )
                              }
                            />
                            <img
                              src={React3}
                              alt="Neutral"
                              onClick={() =>
                                changeSentiment(
                                  "SENTIMENT_NEUTRAL",
                                  "",
                                  "Neutrals"
                                )
                              }
                            />
                            <img
                              src={React4}
                              alt="Not Me"
                              onClick={() =>
                                changeSentiment("SENTIMENT_NOT_ME", "", "NotMe")
                              }
                            />
                          </div>
                        ) : null}
                      </Col>
                      <Col md={4} xs={12} className="mt-md-0 mt-2">
                        <button
                          className="search-filter"
                          onClick={() => setModalShow(true)}
                        >
                          <p>
                            {title != "" && title != undefined
                              ? title
                              : "All Results"}
                          </p>
                          <img src={Filter} alt="" />
                        </button>
                        <FilterModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          onChangeSortValue={onChangeSortValue}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {GlobalService.searchResult.email_btn ? (
                  <div className="verify">
                    <Card className="verify-card">
                      <Card.Body>
                        <img src={EmailVerify} />
                        <div>
                          {GlobalService.userDashboardAll.email_notification !=
                          0 ? (
                            <h4>Email Verification</h4>
                          ) : null}

                          <h6 style={{ margin: 0, top: "60%" }}>
                            {GlobalService.searchResult.exposed_note
                              ? GlobalService.searchResult.exposed_note
                              : ""}
                          </h6>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ) : (
                  <form className="form w-100">
                    {pressedKey ? (
                      <div className="all-check">
                        <label className="custom-all-check">
                          <p>All Select</p>
                          <input
                            type="checkbox"
                            className="all-checkbox"
                            name="allSelect"
                            id="allSelect"
                            // checked={
                            //   users.filter((user) => user?.isChecked !== true).length < 1
                            // }
                            // checked={!users.some((user) => user?.isChecked !== true)}
                            checked={
                              searchResultData && searchResultData.results
                                ? !searchResultData.results.some((user) => {
                                    return user?.isChecked !== true;
                                  })
                                : null
                            }
                            onChange={handleAllChange}
                          />

                          <span class="checkmark"></span>
                        </label>
                      </div>
                    ) : null}
                    <ul className="all-results all-search-results">
                      {searchResultData && searchResultData.results ? (
                        searchResultData.results.map((user, index) => {
                          return (
                            <li>
                              <div class="shadow p-3 bg-white rounded result-list-card">
                                <div className="each-reasult" key={index}>
                                  <label className="custom-check">
                                    <input
                                      type="checkbox"
                                      className="check-icon"
                                      id={user.key}
                                      checked={user?.isChecked || false}
                                      onChange={handleAllChange}
                                    />
                                    <span class="checkmark"></span>
                                  </label>

                                  <h5
                                    style={{
                                      color:
                                        user.sentiment == "SENTIMENT_POSITIVE"
                                          ? "#6AAB44"
                                          : user.sentiment == "SENTIMENT_NEGATIVE"
                                          ? "#F04438"
                                          : user.sentiment == "SENTIMENT_NEUTRAL"
                                          ? "#98A2B3"
                                          : "#101828",
                                    }}
                                  >
                                    {user.title}
                                  </h5>
                                  <p
                                    className="truncated"
                                    style={{
                                      color:
                                        user.sentiment == "SENTIMENT_POSITIVE"
                                          ? "#6AAB44"
                                          : user.sentiment == "SENTIMENT_NEGATIVE"
                                          ? "#F04438"
                                          : user.sentiment == "SENTIMENT_NEUTRAL"
                                          ? "#98A2B3"
                                          : "#101828",
                                    }}
                                  >
                                    {user.snippet}
                                  </p>

                                  <small class="site-title">
                                    <a
                                      href={user.link}
                                      target="_blank"
                                      style={{
                                        color:
                                          user.sentiment == "SENTIMENT_POSITIVE"
                                            ? "#6AAB44"
                                            : user.sentiment ==
                                              "SENTIMENT_NEGATIVE"
                                            ? "#F04438"
                                            : user.sentiment ==
                                              "SENTIMENT_NEUTRAL"
                                            ? "#98A2B3"
                                            : "#101828",
                                      }}
                                    >
                                      {user.domain}
                                    </a>
                                  </small>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <span className="no-result">No results to display</span>
                      )}
                    </ul>
                  </form>
                )}
              </>
              )}
            </Col>
          </Row>
        </div>
    </>
  );
}

export default SelectAll;
