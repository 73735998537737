import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import {Image, Button, Col, Container, Row, Card, Nav, Navbar} from "react-bootstrap";

import HomeMenu from "../../images/nav-icons/IP_Dashboard_Blue.png";
import HomeMenuActive from "../../images/nav-icons/IP_Dashboard_White.png";

import ResultsMenu from "../../images/nav-icons/IP_Search_Blue.png";
import ResultsMenuActive from "../../images/nav-icons/IP_Search_White.png";

import PrivacyMenu from "../../images/nav-icons/IP_Privacy_Blue.png";
import PrivacyMenuActive from "../../images/nav-icons/IP_Privacy_White.png";

import MentionsMenu from "../../images/nav-icons/IP_Mentions_Blue.png";
import MentionsMenuActive from "../../images/nav-icons/IP_Mentions_White.png";

import FreeConsultationMenu from "../../images/nav-icons/IP_Consult_Blue.png";
import FreeConsultationMenuActive from "../../images/nav-icons/IP_Consult_White.png";

import HelpCenter from "../../images/nav-icons/IP_Help_Blue.png";
import HelpCenterActive from "../../images/nav-icons/IP_Help_White.png";

import { NavLink, Link, useNavigate } from "react-router-dom";

import './SideBar.scss'

function SideBar() {
  return (
    <>
    {console.log(window.location.pathname)}
      <div className="sideBarNav">
        <div className="topSidebar">
          <Nav className="flex-column">
            <NavLink to="/app/dashboard" className={window.location.pathname == '/app/dashboard' ? 'active-nav-link nav-link' : 'nav-link'}>
              {
                window.location.pathname == '/dashboard' ? <Image src={HomeMenuActive} alt={HomeMenu}/> : <Image src={HomeMenu} alt={HomeMenu}/>
              }
              <span>Dashboard</span>
            </NavLink>
            <NavLink to="/app/privacy" className={window.location.pathname == '/app/privacy' ? 'active-nav-link nav-link' : 'nav-link'}>
              {window.location.pathname == '/privacy' ? <Image src={PrivacyMenuActive}/> : <Image src={PrivacyMenu}/>}
              <span>Privacy Protector</span>
            </NavLink>
            <NavLink to="/app/results" className={window.location.pathname == '/app/results' ? 'active-nav-link nav-link' : 'nav-link'}>
              {window.location.pathname == '/results' ? <Image src={ResultsMenuActive}/> : <Image src={ResultsMenu}/>}
              <span>Search Manager</span>
            </NavLink>
            <NavLink to="/app/mention_screen" className={window.location.pathname == '/app/mention_screen' ? 'active-nav-link nav-link' : 'nav-link'}>
              {window.location.pathname == '/mention_screen' ? <Image src={MentionsMenuActive}/> : <Image src={MentionsMenu}/>}
              <span>Mentions Monitor</span>
            </NavLink>
{/*            
            <a className='nav-link' href={process.env.REACT_APP_PAYMENT_LINK+'?prefilled_email='+encodeURIComponent(JSON.parse(localStorage.getItem('localStorage_userData')).email)} target="_blank">
              <i class="fa fa-usd" aria-hidden="true" style={{ color:"#1368ba", fontSize: 27, paddingRight: 26 }}></i>
              <span>Manage Payment</span>
            </a> */}
          </Nav>
        </div>
        <div className="btmSidebar">
          <Nav className="flex-column">
            {/* <NavLink to="/app/consultation" className={window.location.pathname == '/app/consultation' ? 'active-nav-link nav-link' : 'nav-link'}>
              {window.location.pathname == '/consultation' ? <Image src={FreeConsultationMenuActive}/> : <Image src={FreeConsultationMenu}/>}
              <span>Free</span> Consultation
            </NavLink> */}
            {/* <NavLink to="/app/consultation" className={window.location.pathname == '/consultation' ? 'active-nav-link nav-link' : 'nav-link'}>
              {window.location.pathname == '/consultation' ? <Image src={HelpCenterActive}/> : <Image src={HelpCenter}/>}
              <span>Help</span> Center
            </NavLink> */}
            <NavLink className="nav-link" to={window.location.pathname}>
              <Image src={HelpCenter}/>
              <span>Help</span> Center
            </NavLink>
          </Nav>
        </div>
      </div>
    </>
  );
}

export default SideBar;
