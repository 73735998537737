import React, { useEffect, useState } from "react";
import { Button, Container, NavLink, Row, Col, Card } from 'react-bootstrap';
import api from '../apis'
import GlobalService from "../services/globalService";
import { useNavigate } from "react-router-dom";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from 'react-toastify';

// Sidebar include
import SideBar from "../components/sidebar/SideBar";

function Notification() {
  const [notificationData, setNotificationData] = useState([]);
  const [readNotic, setReadNotic] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    getNotifications();
  }, [])

  const getNotifications = () => {
    setShowLoader(true);
    let payload = {
      run_update: 1
    }
    console.log('payload fron notification', payload)
    const response = api.notification.notification(payload).then((response) => {
      console.log('res......', response);
      if (response.data.status) {

        setShowLoader(false);
        setRefreshing(false);
        setNotificationData(response.data.data.notifications)

      } else {
        setShowLoader(false);
        setRefreshing(false);
        if (response.data.message != undefined && response.data.message != '') {
          toast.error(response.data.message);
        } else {
          console.log('object');
        }
      }
    }).catch((err) => {
      setShowLoader(false);
      setRefreshing(false);
      console.log(err.message);
      console.log(err);
    });

  }

  const navigateTo = (screen, url, notice_id) => {
    console.log('object', screen, url, notice_id)
    const arr = [...readNotic];
    arr.push(notice_id);
    setReadNotic(arr);
    GlobalService.unreadNotification = (GlobalService.unreadNotification != '' && GlobalService.unreadNotification != '0' && GlobalService.unreadNotification != 0 && GlobalService.unreadNotification > 0) ? Number(GlobalService.unreadNotification) - 1 : 0;
    readNotification(notice_id);
    if (screen == 'none') {
      document.getElementById("badge-icon").click();
    }
    if (screen != 'none') {
      let screens = {
        'privacy': '/privacy',
        'mentions': '/mention_screen',
        'search': '/results',
        'dashboard': '/dashboard'
      }

      if (screens[screen] != undefined) {
        GlobalService.fromNotification = true;
        // const resetAction = StackActions.reset({
        // 	index: 0,
        // 	actions: [NavigationActions.navigate({ routeName: screens[screen] })],
        // });
        // this.props.navigation.dispatch(resetAction)
        if (screen == 'privacy') {
          navigate("/app/privacy");
        }
        else if (screen == 'mentions') {
          navigate('/app/mention_screen');
        }
        else if (screen == 'search') {
          navigate('/app/results');
        }
        else if (screen == 'dashboard') {
          navigate('/app/dashboard');
        }
      }
      else {
        if (screen == 'email') {
          toast.info('Please open your email for verification');
          document.getElementById("badge-icon").click();
          // onRefresh();
        }
        // else {
        // 	this.props.navigation.navigate(screen);
        // }
      }
    }
  }

  const readNotification = (noticeId) => {

    let payload = {
      notice_id: noticeId
    }
    console.log(payload)
    const response = api.updateNotification.updateNotification(payload).then((response) => {
      if (response.data.status) {
        console.log('response from read notification', response)
      }
    }).catch((err) => {
      console.log(err.message);
      console.log(err);
    });

  }

  const onRefresh = async () => {
    await setRefreshing(true);
    getNotifications();
  }


  return (
    <>
      <div className={showLoader ? "container-fluid pl-0" : "container-fluid pl-0 zero-data-container"}>
        <Row>
          <Col lg={2} className="px-0">
            <div className="sticky-head border-0 sideBarStick">
              <SideBar/>
            </div>
          </Col>
          <Col lg={10} className="pl-4 contentBoxing">
          {showLoader ?
            <div className="loader">
              <FontAwesomeIcon icon={faSpinner} className="spinner" />
              <span>Loading...</span>
            </div>
            : 
            <div className="container my-4 notification-container" >
                <Row className="my-4 pb-3 sticky-head">
                  
                  <Col md={3} xs={6} className="d-none d-md-block">
                    <h2>Notifications</h2>
                  </Col>
                </Row>
                {notificationData.length > 0 ?
                  (notificationData.map((ele, index) => {
                    return (
                      <>
                        <div className="shadow p-3 bg-white rounded result-list-card">
                          <Card className="verify-card" key={ele.index}>
                            <Card.Body>
                              <NavLink
                                onClick={() => navigateTo(ele.action, ele.url, ele.notice_id)}>
                                <h4 style={{ color: (ele.is_read == true || readNotic.indexOf(ele.notice_id) !== -1) ? '#cdcdcd' : "#000" }}>{ele.title}</h4>
                                <p className="time">{ele.elapsed}</p>
                                <p style={{ color: (ele.is_read == true || readNotic.indexOf(ele.notice_id) !== -1) ? '#cdcdcd' : "#000" }}>{ele.message}</p>
                              </NavLink>
                            </Card.Body>
                          </Card>
                        </div>
                        {/* <ul className="notification">
                          <li className="py-3" key={ele.index}>
                            <NavLink
                              onClick={() => navigateTo(ele.action, ele.url, ele.notice_id)}>
                              <h4 style={{ color: (ele.is_read == true || readNotic.indexOf(ele.notice_id) !== -1) ? '#cdcdcd' : "#000" }}>{ele.title}</h4>
                              <p className="time">{ele.elapsed}</p>
                              <p style={{ color: (ele.is_read == true || readNotic.indexOf(ele.notice_id) !== -1) ? '#cdcdcd' : "#000" }}>{ele.message}</p>
                            </NavLink>
                          </li>
                        </ul> */}
                      </>
                    )
                  })) : null}
            </div>}
            <div style={{height: '150px'}}></div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Notification