
import api from "./api";

const updateNotification = {
	
	updateNotification(params) {
		return api.post("user-update-notification",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default updateNotification;