import React, { useEffect, useState } from "react";
import { Button, Card, Container, Row, Col, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Amazing from "../../images/AMAZING.png";
import DANGEROUS from "../../images/DANGEROUS.png";
import POOR from "../../images/POOR.png";
import OKAY from "../../images/OKAY.png";
import GOOD from "../../images/GOOD.png";
import React1 from "../../images/react1.png";
import React2 from "../../images/react2.png";
import React3 from "../../images/react3.png";
import React4 from "../../images/react4.png";
import EmailVerify from "../../images/emailverify.png";
import Data from "../../images/data.png";
import Happy from "../../images/happy-chat.png";
import Sad from "../../images/sad-chat.png";
import Neutral from "../../images/nutral-chat.png";
import Notme from "../../images/not-chat.png";
import ScheduleModal from "../ScheduleModal";
import ResultCard from "./ResultCard";
import MentionCard from "./MentionCard";
import { useNavigate, redirect, } from "react-router-dom";
import api from "../../apis";
import GlobalService from "../../services/globalService";
import Modal from 'react-bootstrap/Modal';

// Sidebar include
import SideBar from "../../components/sidebar/SideBar";

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';

var newDate = new Date();
function Index(props) {
  // const dashboardData = GlobalService.allDashboardResult;
  const [modalShow, setModalShow] = React.useState(false);
  const [allResultData, setAllResultData] = useState({});
  const [resultList, setResultList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [privacyResult, setPrivacyResult] = useState([]);
  const [showLoaderPrivacy, setShowLoaderPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState("");
  const [dashboardApiError, setDashboardApiError] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    loadSearchResult();
    privacyApiCall();

  }, [])
  const resultImgPositive = React1;
  const resultImgNegative = React2;
  const resultImgNeutrals = React3;
  const resultImgNotMe = React4;
  const mentionIconHappy = Happy;
  const mentionIconSad = Sad;
  const mentionIconNeutral = Neutral;
  const mentionIconNotMe = Notme;

  const handleClose = () => setShowActionSheet(false);

  const viewResults = () => {
    // navigate('/app/results')
    // navigate('/app/selectall')
    navigate('/app/selectallbkp')
  }
  const viewMentions = () => {
    navigate('/app/mention')
  }
  const viewPrivacy = () => {
    navigate('/app/privacy')
  }
  const navigateTo = (page, filterName, filterLabel) => {
    console.log('navigate to', page, filterName, filterLabel)
    GlobalService.filterSelected = filterName;
    GlobalService.filterSelectedLabel = filterLabel;
    navigate(page);
  }

  const loadSearchResult = () => {
    setShowLoader(true)
    GlobalService.appOpend = true;
    const response = api.dashboard.get().then((response) => {
      console.log('response from dashboard', response)
      if (response.data.status) {
        setShowLoader(false);
        setAllResultData(response.data)
        GlobalService.searchResult = response.data.data.serpwow_results;
        // GlobalService.privacyResult = response.data.data.people_results;
        GlobalService.mentionResult = response.data.data.social_results;
        GlobalService.userDashboardAll = response.data.data.user;
        GlobalService.allDashboardResult = response.data.data;
        GlobalService.subscriptionData = response.data.data.subscription_data;
        console.log('hhhhhh', GlobalService.subscriptionData)
        // privacyApiCall();


        if (GlobalService.userDashboardAll.email_notification != 0) {
          setShowActionSheet(true)
        } else {
          setShowActionSheet(false)
        }
      } else {
        setShowLoader(false);
        // toast.error(response.data?.message || "Something went wrong");
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      setDashboardApiError(err.message)
      console.log('err.message from adsboard api', err.message)
      // toast.error(err.message);
    });

  }



  const privacyApiCall = () => {

    var date = new Date();
    var current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    var current_time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
    var date_time = current_date + " " + current_time;

    console.log(date_time)



    setShowLoaderPrivacy(true)
    let payload = {
      // user_id: GlobalService.userDashboardAll.id
      user_id: GlobalService.localStorageData.id

    }
    const response = api.privacyApi.privacyApi(payload).then((response) => {
      console.log('response', response)
      if (response.data.status) {
        setShowLoaderPrivacy(false);
        console.log('response privacy api-------', response)
        setPrivacyResult(response);
        GlobalService.privacyResult = response.data;
        localStorage.setItem('privacyLength', response.data.results.length);
        localStorage.setItem('currentTime', date_time);

      } else {
        setShowLoaderPrivacy(false);
        // toast.error(response.data?.message || "Something went wrong");
      }
    }).catch((err) => {
      console.log(err)
      setShowLoaderPrivacy(false);
      setPrivacyError(err.message)
      console.log('err.message', err.message)
      // toast.error(err.message);
    });

  }


  // const value = GlobalService.allDashboardResult.privacy_scores != undefined ? GlobalService.allDashboardResult.privacy_scores.score : 0;
  // const privacyScrore = value != undefined ? value : 0;
  // const percentValue = GlobalService.allDashboardResult.privacy_scores != undefined ? GlobalService.allDashboardResult.privacy_scores.percent : 0;
  // const flagValue = GlobalService.allDashboardResult.privacy_scores != undefined ? GlobalService.allDashboardResult.privacy_scores.flag : 'DANGEROUS';



  console.log(GlobalService.privacyResult);
  const serpwowPrivacyScrore = GlobalService.allDashboardResult && GlobalService.allDashboardResult.serpwow_results && GlobalService.allDashboardResult.serpwow_results.privacy_stack?.privacy_score != undefined ? GlobalService.allDashboardResult.serpwow_results.privacy_stack.privacy_score : 0;
  const privacyApiScore = GlobalService.privacyResult && GlobalService.privacyResult.privacy_stack.privacy_score != undefined ? GlobalService.privacyResult.privacy_stack.privacy_score : 0;

  const calculatedPrivacyScrore = parseInt(serpwowPrivacyScrore) + parseInt(privacyApiScore);
  const skeletonRowNumbers = ["1", "2", "3", "4" , "5", "6", "7", "8" , "9" , "10" ,"11","12","13","14","15","16"];
  const skeletonResultSummaryRowNum = ["1","2","3","4"];
  return (
    <>
        <div className={showLoader ? "container-fluid pl-0 with-skeleton" : "container-fluid pl-0 "}>
          <Row>
            <Col lg={2} md={3} className="px-0">
              <div className="sticky-head border-0 sideBarStick">
                <SideBar/>
              </div>
            </Col>

            <Col lg={10} md={9} className="pl-4 contentBoxing">
            {showLoader ?
              // <div className="loader">
              //   <FontAwesomeIcon icon={faSpinner} className="spinner"
              //   />
              //   <span>Loading...</span>
              // </div>
              <>
                <Row style={{ marginTop: 50 }}>
                  <Col md={6}>
                    <Card>
                      <Card.Header>Overview</Card.Header>
                      <Card.Body className="text-center">
                      {skeletonRowNumbers.map((skel, indexNumber) => (
                          <Skeleton />
                      ))}
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card>
                      <Card.Header>Result Summary</Card.Header>
                        <Card.Body>
                          <Row>
                          {skeletonResultSummaryRowNum.map((skelRes, skelResIndexNumber) => (
                            <Col md={6}>
                                <NavLink>
                                  <div className='each-result text-center'>
                                    <h2 className="result-counter"><Skeleton /></h2>
                                    <h4><Skeleton /></h4>
                                  </div>
                                </NavLink>
                            </Col>
                          ))}
                          </Row>
                        </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </>
              :
              <Container className="my-5">


                <Row>
                  <Col md={6}>
                    <Card>
                      <Card.Header>Overview</Card.Header>

                      <Card.Body className="text-center">
                        {calculatedPrivacyScrore <= 99 &&
                          <img src={DANGEROUS} className="over-img" alt="" />
                        }
                        {calculatedPrivacyScrore >= 100 && calculatedPrivacyScrore <= 199 &&
                          <img src={POOR} className="over-img" alt="" />}
                        {calculatedPrivacyScrore >= 200 && calculatedPrivacyScrore <= 299 &&
                          <img src={OKAY} className="over-img" alt="" />}
                        {calculatedPrivacyScrore >= 300 && calculatedPrivacyScrore <= 399 &&
                          <img src={GOOD} className="over-img" alt="" />}
                        {calculatedPrivacyScrore >= 400 &&
                          <img src={Amazing} className="over-img" alt="" />}
                        <div className="privacy-counter">
                          <h2 className="">{calculatedPrivacyScrore}</h2>
                          <p>Privacy Score</p>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>



                  <Col md={6}>
                    <Card>
                      <Card.Header>Result Summary</Card.Header>
                      {
                        dashboardApiError != "" ?
                          <Card.Body>
                            <div className="verify1" style={{ height: '60vh' }}
                              onClick={loadSearchResult}>
                              <div className="verify-card1" >
                                <FontAwesomeIcon icon={faSpinner} className="spinner in-dash" />
                                <h4>Reload....</h4>
                              </div>

                            </div>
                          </Card.Body>
                          :
                          <Card.Body>
                            <Row>
                              <Col md={6}>
                                <NavLink
                                  // onClick={() => navigateTo("/app/results", "SENTIMENT_POSITIVE", "Positives")}
                                  onClick={() => navigateTo("/app/results", "SENTIMENT_POSITIVE", "Positives")}
                                >
                                  <div className='each-result text-center posi'>

                                    <img src={resultImgPositive} alt="" />
                                    <h2 className="result-counter">{allResultData && allResultData.data && allResultData.data.serpwow_results ? allResultData.data.serpwow_results.positive_count : '0'}</h2>
                                    <h4>{'Positive'}</h4>
                                  </div>
                                </NavLink>
                              </Col>
                              <Col md={6}>
                                <NavLink
                                  // onClick={() => navigateTo("/app/results", 'SENTIMENT_NEGATIVE', 'Negatives')}
                                  onClick={() => navigateTo("/app/results", "SENTIMENT_NEGATIVE", 'Negatives')}
                                >
                                  <div className='each-result text-center nega'>

                                    <img src={resultImgNegative} alt="" />
                                    <h2 className="result-counter">{allResultData && allResultData.data && allResultData.data.serpwow_results ? allResultData.data.serpwow_results.negative_count : '0'}</h2>
                                    <h4>{'Negative'}</h4>
                                  </div>
                                </NavLink>
                              </Col>
                              <Col md={6}>
                                <NavLink
                                  // onClick={() => navigateTo("/app/results", 'SENTIMENT_NEUTRAL', 'Neutrals')}
                                  onClick={() => navigateTo("/app/results", 'SENTIMENT_NEUTRAL', 'Neutrals')}
                                >
                                  <div className='each-result text-center ntrl'>

                                    <img src={resultImgNeutrals} alt="" />
                                    <h2 className="result-counter">{allResultData && allResultData.data && allResultData.data.serpwow_results ? allResultData.data.serpwow_results.neutral_count : '0'}</h2>
                                    <h4>{'Neutral'}</h4>
                                  </div>
                                </NavLink>
                              </Col>
                              <Col md={6}>
                                <NavLink
                                  // onClick={() => navigateTo("/app/results", 'SENTIMENT_NOT_ME', 'Not me')}
                                  onClick={() => navigateTo("/app/results", 'SENTIMENT_NOT_ME', 'Not me')}
                                >

                                  <div className='each-result text-center ntm'>

                                    <img src={resultImgNotMe} alt="" />
                                    <h2 className="result-counter">{allResultData && allResultData.data && allResultData.data.serpwow_results ? allResultData.data.serpwow_results.not_me_count : '0'}</h2>
                                    <h4>{'Not Me'}</h4>
                                  </div>
                                </NavLink>
                              </Col>
                            </Row>
                            <NavLink className="text-center tab-link"
                              // onClick={() => navigateTo('/app/results', 'all', 'All')}
                              onClick={() => navigateTo('/app/results', 'all', 'All')}
                            // onClick={viewResults}
                            >
                              View Results{" "}
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{ marginLeft: 10 }}
                              />
                            </NavLink>
                          </Card.Body>
                      }
                    </Card>
                  </Col>

                  {
                    privacyError != "" ?
                      <Col md={6} className="mt-4">
                        <Card>
                          <Card.Header>
                            <h2>Data Privacy</h2>
                          </Card.Header>
                          <Card.Body>
                            <div className="verify1" style={{ height: '60vh' }}
                              onClick={privacyApiCall}>
                              {/* <div className="verify-card" >
                                <FontAwesomeIcon icon={faSpinner} className="spinner in-dash" />
                                <h4>Reload....</h4>
                              </div> */}
                              {allResultData && allResultData.data && allResultData.data.user && allResultData.data.user.email_notification == 0 ?
                              <div className="verify-card1" >
                                <img src={EmailVerify} />
                                <h6>Your result is being processed, we will notify you when it is ready.</h6>
                              </div>
                              :
                              <div className="verify-card1" >
                              <img src={EmailVerify} />
                              <h4>Email verification</h4>
                              <h6>Please verify your email address to enable all features</h6>
                            </div>
                            }

                            </div>
                            <NavLink className="text-center tab-link"
                              onClick={viewPrivacy}>
                              View All
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{ marginLeft: 10 }}
                              />
                            </NavLink>
                          </Card.Body>
                        </Card>
                      </Col>
                      :
                      privacyResult && privacyResult.data && !privacyResult.data.show_results ?
                        <Col md={6} className="mt-4">
                          <Card>
                            <Card.Header>
                              <h2>Data Privacy</h2>
                            </Card.Header>
                            <Card.Body>
                              <div className="verify1" style={{ height: '60vh' }}>
                                <div className="verify-card1" >
                                  <img src={EmailVerify} />
                                  {
                                    privacyResult && privacyResult.data && privacyResult.data.email_btn ?
                                      <h4>Email verification</h4>
                                      : null
                                  }

                                  <h6 style={{ margin: 0 }}>{privacyResult && privacyResult.data ? privacyResult.data.exposed_note : ''}</h6>

                                </div>

                              </div>

                              <NavLink className="text-center tab-link"
                                onClick={viewPrivacy}>
                                View All
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ marginLeft: 10 }}
                                />
                              </NavLink>
                            </Card.Body>
                          </Card>
                        </Col>

                        :
                        <Col md={6} className="mt-4 dataPrivacy">
                          <Card>
                            <Card.Header>
                              <h2>Data Privacy</h2>
                            </Card.Header>
                            <Card.Body>
                              <img src={Data} className="over-img" style={{ width: "80%" }} />
                              <div class="privacy-counter" style={{ top: "35%" }}>
                                <h2 style={{ margin: 0 }}>
                                  {
                                    // showLoaderPrivacy == false &&
                                    privacyResult && privacyResult.data ? privacyResult.data.exposed_count : '0'
                                  }

                                </h2>
                                <p style={{ lineHeight: 1.5, margin: 0, fontSize: "1.3rem" }}>
                                  Sites exposing your
                                  <br /> personal data
                                </p>
                              </div>

                            </Card.Body>

                            <NavLink className="text-center tab-link"
                              onClick={viewPrivacy}>
                              View All
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{ marginLeft: 10 }}
                              />
                            </NavLink>
                          </Card>
                        </Col>
                  }
                  {

                    dashboardApiError != "" ?
                      <Col md={6} className="mt-4">
                        <Card>
                          <Card.Header>
                            <h2>Online Mentions</h2>
                          </Card.Header>
                          <Card.Body>
                            <div className="verify1" style={{ height: '60vh' }}
                              onClick={loadSearchResult}>
                              <div className="verify-card1" >
                                <FontAwesomeIcon icon={faSpinner} className="spinner in-dash" />
                                <h4>Reload....</h4>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      :
                      allResultData && allResultData.data && allResultData.data.social_results && !allResultData.data.social_results.show_results ?

                        <Col md={6} className="mt-4">
                          <Card>
                            <Card.Header>
                              <h2>Online Mentions</h2>
                            </Card.Header>
                            <Card.Body>
                              <div className="verify1" style={{ height: '60vh' }}>
                                <div className="verify-card1" >
                                  <img src={EmailVerify} />
                                  {
                                    GlobalService.userDashboardAll.email_notification != 0 ?
                                      <h4>Email Verification</h4> : null
                                  }

                                  <h6 style={{ margin: 0 }}>{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.exposed_note : ''}</h6>

                                </div>

                              </div>
                              <NavLink className="text-center tab-link"
                                onClick={() => navigateTo('/app/mention', 'all', 'All')}>
                                View All
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ marginLeft: 10 }}
                                />
                              </NavLink>
                            </Card.Body>
                          </Card>
                        </Col>
                        :
                        <Col md={6} className="mt-4">
                          <Card>
                            <Card.Header>
                              <h2>Online Mentions</h2>
                            </Card.Header>
                            <Card.Body>
                              <Row>

                                <Col md={6} >
                                  <NavLink
                                    onClick={() => navigateTo("/app/mention_screen", 'SENTIMENT_POSITIVE', 'Positives')}>
                                    <div className='each-mention'>
                                      <img src={mentionIconHappy} alt="" />
                                      <h2 className="mention-counter">{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.positive_count : '0'}</h2>
                                      <p>{'Positive'}</p>
                                    </div>
                                  </NavLink>
                                </Col>

                                <Col md={6} >
                                  <NavLink onClick={() => navigateTo("/app/mention_screen", 'SENTIMENT_NEGATIVE', 'Negatives')}>
                                    <div className='each-mention'>
                                      <img src={mentionIconSad} alt="" />
                                      <h2 className="mention-counter">{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.negative_count : '0'}</h2>
                                      <p>{'Negative'}</p>
                                    </div>
                                  </NavLink>
                                </Col>

                                <Col md={6} >
                                  <NavLink onClick={() => navigateTo("/app/mention_screen", 'SENTIMENT_NEUTRAL', 'Neutrals')}>
                                    <div className='each-mention'>
                                      <img src={mentionIconNeutral} alt="" />
                                      <h2 className="mention-counter">{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.neutral_count : '0'}</h2>
                                      <p>{'Neutrals'}</p>
                                    </div>
                                  </NavLink>
                                </Col>

                                <Col md={6} >
                                  <NavLink onClick={() => navigateTo("/app/mention_screen", 'SENTIMENT_NOT_ME', 'Not me')}>
                                    <div className='each-mention'>
                                      <img src={mentionIconNotMe} alt="" />
                                      <h2 className="mention-counter">{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.not_me_count : '0'}</h2>
                                      <p>{'Not Me'}</p>
                                    </div>
                                  </NavLink>
                                </Col>
                              </Row>
                              <NavLink className="text-center tab-link"
                                onClick={() => navigateTo('/app/mention_screen', 'all', 'All')}>
                                {/* onClick={viewMentions}> */}
                                View Mentions{" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  style={{ marginLeft: 10 }}
                                />
                              </NavLink>
                            </Card.Body>
                          </Card>
                        </Col>
                  }
                  <Col md={12}>
                    <div className="schedule">
                      <Row>
                        <Col md={6}>
                          <div className="privacy-txt">
                            <h4>Want to protect your privacy?</h4>
                            <p>
                              Let's secure your online privacy and help you regain
                              control of your data.
                            </p>
                          </div>
                        </Col>
                        <Col md={6} className="text-end">
                          <Button className="schdl"
                            // onClick={() => setModalShow(true)}
                            onClick={() => navigate('/app/consultation')}

                          >
                            Schedule free consultation
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{ marginLeft: 10 }}
                            />
                          </Button>
                          {/* <ScheduleModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Modal
                  show={showActionSheet}
                  onHide={() => setShowActionSheet(false)}>
                  {/* onHide={() => setShowActionSheet(false)} */}

                  <Modal.Header closeButton>
                    <Modal.Title >Email verification</Modal.Title>
                  </Modal.Header>
                  <img src={EmailVerify} className="mt-4" style={{ width: '100px', margin: 'auto' }} />
                  <Modal.Body className="py-4 text-center">{allResultData && allResultData.data && allResultData.data.social_results ? allResultData.data.social_results.dashboard_modal_exposed_note : ''}</Modal.Body>
                </Modal>
              </Container>}
            </Col>
          </Row>
        </div>
    </>
  )
}

export default Index