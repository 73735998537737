
import api from "./api";

const notification = {
	
	notification(params) {
		return api.post("user-check-notifications",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default notification;