import React, { lazy, useEffect, useState } from "react";
import { Button, Col, Container, Row, Card } from "react-bootstrap";
import Filter from "../images/filter.png";
import api from "../apis"
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import FilterModal from "./FilterModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalService from "../services/globalService";
import EmailVerify from "../images/emailverify.png";
import NoImage from "../images/no-image.png";
import LoaderImg from "../images/download.gif";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { toast } from 'react-toastify';

var intervalId = null;
var parcentage = 0;
var arrayLength = 25;
var arrayProgress = 26;
var progressTime = null;
var newDate = new Date();
function Index(props) {
    const [list, setList] = useState([]);
    const [privacyResult, setPrivacyResult] = useState([]);
    const [showLoader, setShowLoader] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [title, setTitle] = useState('All Reactions');
    const [sortValue, setSortValue] = useState('all');
    const [sortLabel, setSortLabel] = useState('All');
    const [showResults, setShowResults] = useState(false);
    const [privacyError, setPrivacyError] = useState("");
    const [checkStatus, setCheckStatus] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoadStarted, setLoadStarted] = useState(false);
    const [load, setLoad] = useState(false);
    const [allResultData, setAllResultData] = useState({});
    const [percentage, setPercentage] = useState(0)
    var privacyLength = localStorage.getItem("privacyLength")
    var currentTime = localStorage.getItem("currentTime")


    useEffect(() => {
        // apiCall();
        demoscan();
        privacyApiCall()  // this one for when the page hits it calling the api otherwise the api call is sightly delayed.


    }, [])

    function demoscan() {
        // console.log('arrayLength', arrayLength);
        // console.log('GlobalService.privacyResult.length', privacyLength)

        var date = new Date();
        var current_date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        var current_time = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        var date_time = current_date + " " + current_time;

        console.log(date_time)

        var date1 = new Date(date_time);
        var date2 = new Date(localStorage.getItem("currentTime"));

        var diff =  date1.getTime() - date2.getTime() ;
        console.log(diff);

        var msec = diff;
        var hh = Math.floor(msec / 1000 / 60 / 60);


        console.log("hh",hh)

        // var remainArray = arrayLength - privacyResult.length;
        // console.log('remainArray', remainArray)

        // let progressTime = setInterval(async() => {
        //     if(parcentage<=5 && privacyResult&&privacyResult.length <= 5){
        //         parcentage++;
        //         console.log('parcentage',parcentage)
        //         console.log('privacyResult0000',privacyResult)
        //        privacyApiCall()
        //     }
        //     else{
        //         clearInterval(progressTime);
        //     }
        // }, 5000);

        // let intervalId = setInterval(executingFunction, 1000)
        // return (() => {
        //     clearInterval(intervalId)
        // })
        // privacyApiCall()
        if (arrayLength >= privacyLength && currentTime) {
            console.log('sett')
            // console.log(' privacyResult.length', privacyResult.length)
            progressTime = setInterval(() => {
                privacyApiCall()
            }, 5000);
            // clearInterval(progressTime);
        }
        if (privacyLength == 26) {
            console.log('equal')
            privacyApiCall()
        }
        //  clearInterval(progressTime);





        // let progressTime = setInterval(async() => {
        //     if(percentage<80){
        //         await setPercentage(percentage+randomIntFromInterval(5,18))
        //         // await this.setState({percentage:percentage+randomIntFromInterval(5,18)});
        //     }
        // }, 5000);
        // privacyApiCall(async()=>{
        //     await this.setState({percentage:100});
        //     clearInterval(progressTime);

        // });
    }

    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


    function checkIfImageExists(url, callback) {
        const img = new Image();
        img.src = url;

        if (img.complete) {
            callback(true);
        } else {
            img.onload = () => {
                callback(true);
            };

            img.onerror = () => {
                callback(false);
            };
        }
    }



    const apiCall = () => {
        setShowLoader(true)
        const response = api.dashboard.get().then((response) => {
            if (response.data.status) {
                setShowLoader(false);
                setAllResultData(response.data)
                GlobalService.mentionResult = response.data.data.social_results;
                GlobalService.userDashboardAll = response.data.data.user;
                // privacyApiCall();
                // console.log(response.data)
            } else {
                setShowLoader(false);
                toast.error(response.data.message);
            }
        }).catch((err) => {
            console.log(err)
            setShowLoader(false);
            toast.error(err.message);
        });

    }

    const privacyApiCall = () => {
        console.log('object111111')
        let payload = {
            // user_id: GlobalService.userDashboardAll.id
            user_id: GlobalService.localStorageData.id
        }
        setShowLoader(true);
        setCheckStatus(false);
        const response = api.privacyApi.privacyApi(payload).then(async (response) => {
            console.log('pri api res', response)
            if (response.data.status) {
                GlobalService.privacyResult = response.data;

                /*********************** Validating the favicon and site-logo *****************************/
                if (response.data.results.length > 0) {
                    let resultData = response.data.results;
                    for (let i = 0; i < resultData.length; i++) {
                        checkIfImageExists(resultData[i]['icon'], (exists) => {
                            if (exists) {
                                resultData[i]['site_icon'] = resultData[i]['icon'];
                            } else {
                                checkIfImageExists(resultData[i]['logo'], (existLogo) => {
                                    if (existLogo) {
                                        resultData[i]['site_icon'] = resultData[i]['logo'];
                                    } else {
                                        resultData[i]['site_icon'] = NoImage;
                                    }
                                })
                            }
                        })
                    }
                    // console.log('resulrdataaaaaaa', resultData)
                    localStorage.setItem('privacyLength', resultData.length);
                    // console.log('privacyResultprivacyResult', privacyResult)
                    setPrivacyResult(response.data.results);
                    setShowResults(response.data.show_results)
                    await setShowLoader(false);

                    setTimeout(async () => {
                        // setShowLoader(false);
                        await setCheckStatus(true);
                        // imageURL();
                    }, 4000);
                } else {
                    setShowLoader(false);
                }
                /*********************** Validating the favicon and site-logo *****************************/
            } else {
                setShowLoader(false);
            }
        }).catch((err) => {
            console.log(err)
            setShowLoader(false);
            setPrivacyError(err.message)
            console.log('err.message-------------', err.message)
            // toast.error(err.message);
        });

    }

    const onChangeSortValue = async (value, label) => {
        //console.log('value and label ', value, label)
        // this.setState({ showFilterArea: false, title: label });
        if (label == 'All') {
            label = 'All Reactions'
        }

        setTitle(label);
        await setSortValue(value);
        await setSortLabel(label);

        let finalValue = (value == 'exposed') ? 'true' : 'false';

        if (GlobalService.privacyResult != '') {
            let dataObj = { ...GlobalService.privacyResult };
            let data = [...GlobalService.privacyResult.results];
            let newData = data.filter(function (item) {
                let name = item.exposed_status.toString();
                return name.indexOf(finalValue) > -1
            });

            //console.log('new data', newData);

            if (value != 'all' && newData.length > 0) {
                setPrivacyResult(newData)
            } else {
                if (value == 'all') {
                    setPrivacyResult(dataObj.results)
                } else {
                    setPrivacyResult([]);
                }
            }
        }
        setModalShow(false);
    }

    // const imageURL = (imgurl) => {
    //   console.log('imgurlimgurl', imgurl)
    //   // document.getElementById("imgg").onload();
    //   document.getElementById("imgg").addEventListener("load", window.onload);// working
    //   document.getElementById("imgg").addEventListener("load", privacyApiCall);// working

    //   // return imgurl?.site_icon ? imgurl.site_icon : NoImage

    //   // window.onload=function(){
    //   //   // document.getElementById("imgg").click();
    //   //   document.getElementById("imgg").addEventListener("load",privacyApiCall());
    //   // };

    // }

    // window.onload = function pageLoad() {
    //   if (load) {
    //     window.location.reload(true);
    //     setLoad(false);
    //   }
    // };


    return (
        <>
            {/* {showLoader ?
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} className="spinner"
                    />
                    <span>Loading...</span>
                </div>
                

                
                : */}


            <Container>

                <div className="container my-4">
                    <Row className="my-4 pb-3 sticky-head">
                        <Col md={8} xs={6}>
                            <h2>Privacy</h2>
                        </Col>


                        {/* <Col md={8} xs={6}>
                      <h2>Privacy</h2>
                    </Col> */}
                        <Col md={4} xs={6}>
                            <button className="search-filter" onClick={() => setModalShow(true)}>
                                <p>{title != '' && title != undefined ? title : 'All Results'}</p>
                                <img src={Filter} alt="" />
                            </button>
                            <FilterModal
                                show={modalShow}
                                isExposed={true}
                                onHide={() => setModalShow(false)}
                                onChangeSortValue={onChangeSortValue}
                            />
                        </Col>

                    </Row>
                    {/* {console.log('privacyLength', privacyLength)}
                    {console.log("object", arrayLength + 1)}
                    {console.log('lllllllll', privacyLength / arrayProgress)} */}

                    {GlobalService.privacyResult.email_btn ? null :
                        <ProgressBar animated now={(privacyLength / arrayProgress) * 100} />}

                    {showLoader ?
                        <div className="loader" style={{ marginTop: "20px" }}>
                            <FontAwesomeIcon icon={faSpinner} className="spinner"
                            />
                            <span>Loading...</span>
                        </div>



                        :



                        privacyError != "" ?
                            // <span className="no-result">No privacy results to display</span>
                            <div className="verify">

                                <Card className="verify-card">
                                    <Card.Body>
                                        <img src={EmailVerify} />
                                        {allResultData && allResultData.data && allResultData.data.user && allResultData.data.user.email_notification == 0 ?
                                            <div>

                                                <h6 style={{ margin: 0, top: "60%" }}>Your result is being processed, we will notify you when it is ready.</h6>
                                            </div>
                                            :
                                            <div>
                                                <h4>Email verification</h4>
                                                <h6 style={{ margin: 0, top: "60%" }}>Please verify your email address to enable all features.</h6>
                                            </div>
                                        }
                                    </Card.Body>
                                </Card>
                            </div>
                            :
                            !showResults ?
                                // GlobalService.privacyResult.email_btn?
                                <div className="verify">
                                    <Card className="verify-card">
                                        <Card.Body>
                                            <img src={EmailVerify} />
                                            <div>
                                                {
                                                    GlobalService.privacyResult.email_btn ? <h4>Email verification</h4> : ''
                                                }

                                                <h6 style={{ margin: 0, top: "60%" }}>{GlobalService.privacyResult.exposed_note ? GlobalService.privacyResult.exposed_note : ''}</h6>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                :
                                // <div className="container my-4">
                                <>

                                    <Row>

                                        <Col md={12}>
                                            <ul className="all-privacy">
                                                {/* {console.log('privacyResult', privacyResult)} */}
                                                {privacyResult.length > 0 ?
                                                    (privacyResult.map((exposed, index) => {
                                                        return (
                                                            <li key={exposed.key}>
                                                                <a href={exposed.url != '' ? exposed.url : 'javascript:void(0)'} target={exposed.url != '' ? "_blank" : ""}>
                                                                    <div className="each-card">
                                                                        {/* {console.log("imageeee", exposed.site_icon)} */}
                                                                        <img
                                                                            // on={() =>//   imageURL() }
                                                                            id="imgg" src={exposed.site_icon != undefined ? exposed.site_icon : exposed.site_icon == undefined ? LoaderImg : NoImage}
                                                                        // loading={lazy} 
                                                                        />
                                                                        <div className="info">
                                                                            {
                                                                                exposed.exposed_status ? <span className="tag">Exposed</span> : null
                                                                            }
                                                                            <h4>{exposed.title}</h4>
                                                                            <p>{exposed.snippet}</p>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )

                                                    })) : <span className="no-result">No privacy results to display</span>
                                                }
                                            </ul>
                                        </Col>
                                    </Row>
                                </>
                    }
                </div>
            </Container>
            {/* }  */}
        </>

    );
}

export default Index;

