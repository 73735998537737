// import React, { useEffect } from 'react'
// import { StripeProvider, Elements } from 'react-stripe-elements'
// import CheckoutFrom from './CheckoutFrom'

// const CheckOut = ({ selectedProduct, history }) => {
//   useEffect(() => {
//     window.scrollTo(0, 0)
//   }, [])

//   return (
//     <StripeProvider apiKey="pk_test_51MZsuASJJvAV40GY4SmFfeA49FsJ6W5hCXDxPj1uxV2FglXHXuZEezxUK26PcGveXwTUGWbsaX9Pa7Pqqr16Lzfg00c4oNpVKz">
//       <Elements>
//         <CheckoutFrom/>
//       </Elements>
//     </StripeProvider>
//   )
// }

// export default CheckOut


import React from "react";
// import "./index.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutFrom";

const stripePromise = loadStripe("pk_test_51MTSk0KfBR346qLzhk1jpPt14j7x5vCcGEBTZuDZEc89LmKctFj9rCfdEC8iQGZngkyDc9nopTx1ls0oiszNU4V900EXMD1QGe");

const CheckOut = () => {
    
  return (
    <div className="App">
      <div className="product">
      
        <div>
          <Elements stripe={stripePromise}>
            <CheckoutForm/>
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;