import React from "react";
import { Container } from "react-bootstrap";

import "./StepForm.scss";

// Sections
import Header from "./header/Header";
import StepTwoForm from "./StepTwoForm";
import Footer from "./footer/Footer";


function ScanningStepTwo() {


   return (
    <>
      <Header />
      <StepTwoForm />
      <div className="log-reg-header position-relative px-3 d-none">
        <Container>
          <div>
            <h2>Your Free Privacy Scan is Seconds Away!</h2>
            <p>Enter your location </p>
          </div>
        </Container>
      </div>

      
      <Footer />
    </>
  );
}

export default ScanningStepTwo;
