import api from "./api";

const saveUserLocation = {
	
	saveUserLocation(params) {
		return api.post("saveUserLocation",{device_id:localStorage.getItem('deviceId'),...params})
	},
	setUserLocation(params) {
		return api.post("setUserLocation",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default saveUserLocation;