import React, { useEffect, useState, useRef, useContext } from "react";
import { Container, Form, Button, Row, Col, Dropdown } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { faArrowRight, faArrowLeft, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validator from "validator";
import "./StepForm.scss";
import ReactFlagsSelect from "../../assets/react-flags-select";
import GlobalService from '../../services/globalService';
import AppContext from "../../context/AppContext"
import api from "../../apis";
import { toast } from 'react-toastify';

// Scss
import './style/SearchForm.scss';

function StepTwoForm() {

    const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('user_current_location_country'));
    const [stateData, setStateData] = useState([]);
    //const [stateName, setStateName] = useState("Select State");
    const [stateName, setStateName] = useState(localStorage.getItem('user_current_location_state'));
    const [stateNameId, setStateNameId] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showFirstTimeLoader, setShowFirstTimeLoader] = useState(false);
    const [cityData, setCityData] = useState([]);
    // const [cityName, setCityName] = useState("Select City");
    const [cityName, setCityName] = useState(localStorage.getItem('user_current_location_city'));
    const [cityNameId, setCityNameId] = useState("");
    const [ showCityDropDown, setShowCityDropDown ] = useState(true);

    const appCtx = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
      //setSelectedCountry(localStorage.getItem('user_current_location_country'));

      if(localStorage.getItem('customer_current_saved_email') !== null) {
        navigate("/app/scanning-result");
      }
      setUserCurrentLocation();
      
    }, []);

    const setUserCurrentLocation = () => {
      
      //setShowFirstTimeLoader(true);
      let payload = {
        city_name: localStorage.getItem('user_current_location_city'),
        state_name: localStorage.getItem('user_current_location_state')
      }
      const response = api.saveUserLocation.setUserLocation(payload).then((response) => {
        console.log('res......', response);
        setStateNameId(response.data.data.state_id);
        setStateName(response.data.data.state_name);
        setCityNameId(response.data.data.city_id);
        setCityName(response.data.data.city_name);

        stateLoad(selectedCountry);
        cityLoad(response.data.data.state_id);
        //setShowFirstTimeLoader(false);

        setTimeout(() => {
          setShowFirstTimeLoader(false);
        }, 1800);
    }).catch((err) => {
        setShowFirstTimeLoader(false);
        setShowLoader(false);
        console.log(err.message);
        console.log(err);
    });
    }

    const stateLoad = (countrycode) => {
      setSelectedCountry(countrycode);

      setStateData([]);
      setCityData([]);
      // setStateName("Select State");
      // setCityName("");
      // setStateNameId("");
      // setCityNameId("");

      let data = {
        country_code: countrycode,
      };
      setShowLoader(true);
      const response = api.states.states(data).then((response) => {
        setShowLoader(false);
        if (response.data.status) {
          let newArray = response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          setStateData(newArray);
        } else {
          console.log(response.data.message);
          setShowLoader(false);
        }
      });
    };

    const cityLoad = (statecode) => {
      let data = {
        state_id: statecode,
      };
      setShowLoader(true);
      const response = api.city.city(data).then((response) => {
        console.log(response);
        setShowLoader(false);
        if (response.data.status) {
          let newArray = response.data.data.map((item) => {
            return { id: item.id, name: item.name };
          });
          
          if(newArray.length == 0){
            setShowCityDropDown(false);
          } else {
            setShowCityDropDown(true);
          }

          setCityData(newArray);
        } else {
          console.log(response.data.message);
          setShowLoader(false);
        }
      });
    };

    const onCompleteStep2 = (e) => {
      e.preventDefault();
      let error = false;
      if (stateData.length !== 0 && stateNameId === "") {
        error = true;
        toast.error("You must select a State");
      } else if (cityData.length !== 0 && cityNameId === "") {
        error = true;
        toast.error("You must select a City");
      }

      if (error === false) {
        setShowLoader(true);
        var guest_user_data = JSON.parse(
          localStorage.getItem("guest_user_data")
        );
        let payload = {
          country_code: selectedCountry,
          state_id: stateNameId ? stateNameId : null,
          city_id: cityNameId ? cityNameId : null,
          email: guest_user_data.email,
        };

        const response = api.scan
          .scansteptwo(payload)
          .then((response) => {
            setShowLoader(false);
            console.log("res......", response);
            if (response.data.status) {
              // localStorage.setItem('guest_user_data',  JSON.stringify(response.data.data));
              navigate("/app/scanning-result");
            } else {
              console.log(response);
            }
          })
          .catch((err) => {
            setShowLoader(false);
            console.log(err.message);
            console.log(err);
          });
      } else {
        return false;
      }
    };

    // State filter constant
    const [selectedState, setselectedState] = useState({});
    const [dropdownSearchValue, setDropdownSearchValue] = useState("");
    const [editMode, setEditMode] = useState(false);
    // City filter constant
    const [selectedCity, setselectedCity] = useState({});

    const dropdownRef = useRef();

    /**
     * Close the dropdown when clicked outside
     * Refer https://www.codingdeft.com/posts/react-on-click-outside/ for details
     */
    useEffect(() => {
      const checkIfClickedOutside = (e) => {
        // If the menu is open and the clicked target is not within the menu,
        // then close the menu
        if (
          editMode &&
          dropdownRef.current &&
          !dropdownRef.current.contains(e.target)
        ) {
          setEditMode(false);
        }
      };
      document.addEventListener("mousedown", checkIfClickedOutside);
      return () => {
        // Cleanup the event listener
        document.removeEventListener("mousedown", checkIfClickedOutside);
      };
    }, [editMode]);

    const stateSelectionHandler = (list) => {
      setselectedState(list);
      setDropdownSearchValue("");
      setEditMode(false);
    };

    const filteredState = stateData.filter((list) =>
      list.name.match(new RegExp(dropdownSearchValue, "i"))
    );

    const filteredCity = cityData.filter((list) =>
      list.name.match(new RegExp(dropdownSearchValue, "i"))
    );

    const goToPreviousStep = () => {
      navigate("/app/login");
    };


  return (
    <div className="stepTowForm">
      {showFirstTimeLoader ? (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} className="spinner" />
          <span>Loading...</span>
        </div>
      ) : 
      (<div>
        <section className="interStepsHeading">
          <Container>
            <Row>
              <Col lg={12} className="text-center">
                <h1>Your Free Privacy Scan is Seconds Away!</h1>
                <h2>Enter your city and state:</h2>
              </Col>
            </Row>
          </Container>
        </section>
        <Form className="searchForm stepTwoForm">
          <Container>
            <Row className="justify-content-center">
              <Col lg={4} sm={4} xs={12}>
                <Form.Group className="mb-3">
                  <ReactFlagsSelect
                    selected={selectedCountry}
                    searchable={true}
                    searchPlaceholder="Search"
                    onSelect={(code) => {
                      stateLoad(code);
                      setStateName("Select State");
                      setCityName("Select City");
                    }}
                    className="mb-2 form-control interStepsField"
                  />
                </Form.Group>
              </Col>
              <Col lg={4} sm={4} xs={12}>
                <Form.Group>
                  {/* {stateData.length > 0 && ( */}
                    <Dropdown
                      ref={dropdownRef}
                      className="mb-2 form-control interStepsField"
                    >
                      <Dropdown.Toggle variant="success">
                        {stateName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="search-country">
                          <input
                            placeholder="Search State"
                            autoFocus
                            onChange={(e) =>
                              setDropdownSearchValue(e.target.value)
                            }
                            value={dropdownSearchValue}
                            className="form-control"
                          />
                        </div>
                        {filteredState.map((data) => {
                          return (
                            <Dropdown.Item
                              key={data.id}
                              onClick={() => {
                                setStateName(data.name);
                                setStateNameId(data.id);
                                setCityName("Select City");
                                setCityNameId("");
                                cityLoad(data.id);
                                stateSelectionHandler(data);
                              }}
                            >
                              {data.name}
                            </Dropdown.Item>
                          );
                        })}
                        {filteredState.length === 0 && (
                          <Dropdown.Item className="no-result">
                            No results found
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  {/* )} */}
                </Form.Group>
              </Col>
              {showCityDropDown && (
                <Col lg={4} sm={4} xs={12}>
                  <Form.Group>
                    <Dropdown className="form-control interStepsField">
                      <Dropdown.Toggle variant="success">
                        {cityName}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="search-country">
                          <input
                            placeholder="Search City"
                            autoFocus
                            onChange={(e) =>
                              setDropdownSearchValue(e.target.value)
                            }
                            value={dropdownSearchValue}
                            className=""
                          />
                        </div>
                        {filteredCity.map((data) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                setCityName(data.name);
                                setCityNameId(data.id);
                              }}
                              key={data.id}
                            >
                              {data.name}
                            </Dropdown.Item>
                          );
                        })}
                        {filteredCity.length === 0 && (
                          <Dropdown.Item className="no-result">
                            No results found
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </Col>
              )}
            </Row>
          </Container>
          <Container>
            <Row className="justify-content-center">
              {/* <Col lg={4} md={6} xs={6}>
                <Button
                  variant="primary"
                  disabled={showLoader}
                  className="mt-4 w-100"
                  onClick={goToPreviousStep}
                >
                  <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Button>
              </Col> */}
              <Col lg={4} md={6} xs={12}>
                <Button
                  className="form-control mt-4 btn-theme"
                  type="submit"
                  onClick={onCompleteStep2}
                  disabled={showLoader}
                >
                  Continue <FontAwesomeIcon icon={faArrowRight} />
                  {showLoader ? (
                    <FontAwesomeIcon icon={faSpinner} className="spinner" />
                  ) : null}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
      )
      }
    </div>
  );
}

export default StepTwoForm