import React from 'react';

// Owl Carousel
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";  

// Bootstrap Module
import { Container, Row, Col, Image} from "react-bootstrap";

// FontAwesome
import { faQuoteLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

// Css path
import "./style/PrivacyBlock.scss";

// Images
import removePersonal from "../../images/removePersonal.png";
import monitorArrow from "../../images/monitor-arrow.png";
import deletes from "../../images/delete.png";
import cleans from "../../images/clean.png";


function PrivacyBlock() { 
    const state= {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 2,
            },
            1000: {
                items: 3,
            },
        },
    }
  return (
    <div className='privacyBlock'>
        <Container>
            <Row>
                <Col lg={12}>
                    <h2 className='text-center'>We make it simple to find and remove your personal info:</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col lg={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12}>
                                    <div className="eachArrow">
                                        <a href="javascript:;">
                                            <div className="arrowContent d-flex align-items-center">
                                                <Image src={removePersonal} alt="" />
                                                <p><b>Remove your personal info</b> from dangerous sites exposing you to identity thieves, hackers, spammers &amp; more.</p>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="eachArrow">
                                        <a href="javascript:;">
                                            <div className="arrowContent d-flex align-items-center">
                                                <Image src={monitorArrow} alt="" />
                                                <p><b>Remove your personal info</b> from dangerous sites exposing you to identity thieves, hackers, spammers &amp; more.</p>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12}>
                                    <div className="eachArrow">
                                        <a href="javascript:;">
                                            <div className="arrowContent d-flex align-items-center">
                                                <Image src={deletes} alt="" />
                                                <p><b>Remove your personal info</b> from dangerous sites exposing you to identity thieves, hackers, spammers &amp; more.</p>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="eachArrow">
                                        <a href="javascript:;">
                                            <div className="arrowContent d-flex align-items-center">
                                                <Image src={cleans} alt="" />
                                                <p><b>Remove your personal info</b> from dangerous sites exposing you to identity thieves, hackers, spammers &amp; more.</p>
                                            </div>
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className='mt-lg-5 pt-lg-5 mt-sm-2 pt-sm-2'>
                    <h2 className='text-center'>Trusted by leading privacy experts and millions of users:</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <div className="privacyCarousel">
                        <Row>
                            <Col lg={12}>
                                <OwlCarousel 
                                items={3} 
                                className="owl-theme" 
                                loop={true}
                                autoplay 
                                nav={true} 
                                dots={false} 
                                margin={30}
                                responsiveClass={true}
                                responsive={state.responsive}
                                navText={[
                                    '<span class="arrow prev">‹</span>',
                                    '<span class="arrow next">›</span>'
                                ]} >
                                    <div className="item">
                                        <div className="eachSliderItem">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In dicta, necessitatibus vel corrupti molestias a numquam ipsam sint sequi autem eum, ducimus adipisci. Iusto nulla officiis totam voluptate assumenda voluptatum.</p>
                                            <div className="quoteUser">
                                                <h4><FontAwesomeIcon icon={faUser} /> Linda Dickerson Hartsock</h4>
                                                <p>Executive Director of TechStars & Blackstone Launchpad at S.U.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="eachSliderItem">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In dicta, necessitatibus vel corrupti molestias a numquam ipsam sint sequi autem eum, ducimus adipisci. Iusto nulla officiis totam voluptate assumenda voluptatum.</p>
                                            <div className="quoteUser">
                                                <h4><FontAwesomeIcon icon={faUser} /> Linda Dickerson Hartsock</h4>
                                                <p>Executive Director of TechStars & Blackstone Launchpad at S.U.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="eachSliderItem">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In dicta, necessitatibus vel corrupti molestias a numquam ipsam sint sequi autem eum, ducimus adipisci. Iusto nulla officiis totam voluptate assumenda voluptatum.</p>
                                            <div className="quoteUser">
                                                <h4><FontAwesomeIcon icon={faUser} /> Linda Dickerson Hartsock</h4>
                                                <p>Executive Director of TechStars & Blackstone Launchpad at S.U.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="eachSliderItem">
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. In dicta, necessitatibus vel corrupti molestias a numquam ipsam sint sequi autem eum, ducimus adipisci. Iusto nulla officiis totam voluptate assumenda voluptatum.</p>
                                            <div className="quoteUser">
                                                <h4><FontAwesomeIcon icon={faUser} /> Linda Dickerson Hartsock</h4>
                                                <p>Executive Director of TechStars & Blackstone Launchpad at S.U.</p>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container> 
    </div>
  )
}

export default PrivacyBlock