export default {
    authtoken           :   '',
    device_id           :   '',
    register_id         :   null,
    register_data   :   {
        fname		    :	'',
        lname		    :	'',
        country 		: 	'US',
        twitter         :   '',
        instagram       :   '',
        facebook        :   '',
        email           :   '',
        password        :   '',
    },
    searchResult        :   '',
    privacyResult       :   '',
    mentionResult       :   '',
    allDashboardResult  :   '',
    userDashboardAll   :   '',
    userData            :   {},
    deviceToken         :   null,
    unreadNotification  :   0,
    fromNotification    :   false, //For navigation from notification screen
    appOpend            :   false,
    filterSelected      :   'all',
    filterSelectedLabel :   'All',
    searchTour          :   true,
    mentionTour         :   true,
    localStorageData    :   {},
    privacyApiResult    :   '',
    stripeValue    :   '',
    
    stripePriceValue :"",
    stripePriceId:"",

    subscriptionData: "",
    subscriptionDataProductApi: "",
    totalSiteToBeScanned: 33
}