import api from "./api";
const scan = {
  
	scanstepone(params) {
		return api.post("scanstepone",{device_id:localStorage.getItem('deviceId'),...params})
	},
	scansteptwo(params) {
		return api.post("scansteptwo",{device_id:localStorage.getItem('deviceId'),...params})
	}
};

export default scan;