import api from "./api";

const stripeuserExistance = {
	
	stripeuserExistance(params) {
		return api.post("ipv/checkUserExistance",{device_id:localStorage.getItem('deviceId'),...params})
	},

};

export default stripeuserExistance;

