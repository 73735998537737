import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import WithNav from "./components/WithNav";
import WithoutNav from "./components/WithoutNav";
import ScanStepLayout from "./components/ScanStepLayout";
import About from "../src/components/About";
import Privacy from "../src/components/privacy/Index";
import Terms from "../src/components/Terms";
import Notification from "../src/components/Notification";
import Dashboard from "./components/dashboard/Index";
import PlanStatus from "./components/subscribtion/PlanStatus"

import PolicyPolicy from "./components/Policy";
import Login from "./components/login/Index";
import RegistrationScreen from "./components/registration/Index";
import Forget from "./components/login/Forget";
import AccountSettings from "./components/AccountSettings";
import Monitoring from "./components/Monitoring";
import StepThree from "./components/registration/StepThree";
import StepFour from "./components/registration/StepFour";

import Social from "./components/Social";
import Consultation from "./components/Consultation";
import { v4 as uuidv4 } from 'uuid';
import Auth from "./guards/Auth";
import Guest from "./guards/Guest";
import SelectAll from './components/results/SelectAll'
import SelectAllbkp from "./components/results/SelectAllbckup";
import MentionScreen from "./components/mentions/MentionScreen";
import LocalService from "./services/localService";
import ScrollToTop from "./scrollToTop";
import Subscribtion from "./components/subscribtion/index";
import NewSubscription from "./components/subscribtion/NewSubscription";
import PaddleSubscription from "./components/subscribtion/PaddleSubscription";
import PaymentSuccess from "./components/subscribtion/PaymentSuccess";
import CheckoutFrom from "./components/subscribtion/CheckoutFrom";
import CheckOut from "./components/subscribtion/CheckOut";
import MobileSubscription from "./components/subscribtion/MobileSubscription";

import ScanningStepOne from "./components/scanningflow/StepOne";
import ScanningStepTwo from "./components/scanningflow/StepTwo";
import CurrentLocationScan from "./components/scanningflow/CurrentLocation";
import ScanningResult from "./components/scanningflow/ScanResult";

import globalService from "./services/globalService";
import DemoScan from "./components/DemoScan";

import Registration from "./components/guest-registration/Registration";
import QuickScan from "./components/scanningflow/QuickScan";

import HelloPrivacyScan from "./components/scanningflow/HelloPrivacyScan";

var checkValue = ""
function App() {
  const [authFound, setAuthFound] = useState(false);
  const [checkStatus, setCheckStatus] = useState(false);

  const uuidToken = uuidv4();
  LocalService.getUserData();
  // LocalService.getToken();
  if (!localStorage.getItem('deviceId')) {
    localStorage.setItem('deviceId', 'webId-' + uuidToken);
  }
  if (!localStorage.getItem('deviceToken')) {
    localStorage.setItem('deviceToken', uuidToken);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setCheckStatus(false);
    checkValue = localStorage.getItem('token');
    checkValue ? setAuthFound(true) : setAuthFound(false);
  }

  return (
    <>

      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route element={<Guest Component={WithoutNav} />}>
            {/* <Route path="/" element={<Login />} /> */}
            {/* <Route path="/current-location" element={<CurrentLocation />} /> */}
           
            <Route path="/app/login" element={<Login />} />
            <Route path="/app/forget" element={<Forget />} />
            <Route path="/app/registration" element={<RegistrationScreen />} />
            <Route path="/app/plans-and-billing/:userId" element={<MobileSubscription />} />
            <Route path="/app/guest-registration" element={<Registration />} />
            {/* <Route path="/app/policy" element={<PolicyPolicy />} /> */}
          </Route>

          <Route element={<Guest Component={ScanStepLayout} />}>
            <Route path="/app/old-scan-page" element={<ScanningStepOne />} />
            <Route path="/app/current-location-scan" element={<CurrentLocationScan />} />
            <Route path="/app/scanning-location" element={<ScanningStepTwo />} />
            <Route path="/app/scanning-result" element={<ScanningResult />} />
            <Route path="/app/" element={<QuickScan />} />
            <Route path="/quick-scan" element={<QuickScan />} />

            <Route path="/app/helloprivacyScan" element={ <HelloPrivacyScan />} /> 

            {/* For the WordPress site redirection */}
            <Route path="/app/:fname/:lname/:age" element={<QuickScan />} />
          </Route>

          <Route element={<Auth Component={WithNav} />}>

            <Route path="/app/subscribtion" element={<Subscribtion />} />
            <Route path="/app/dashboard" element={<Dashboard />} />
            <Route path="/app/results" element={<SelectAll />} />
            <Route path="/app/selectallbkp" element={<SelectAllbkp />} />
            <Route path="/app/privacy" element={<Privacy />} />

            <Route path="/app/demo-scan" element={<DemoScan />} />

            
            <Route path="/app/mention" element={<MentionScreen />} />
            <Route path="/app/mention_screen" element={<MentionScreen />} />
            <Route path="/app/notification" element={<Notification />} />

            <Route path="/app/planstatus" element={<PlanStatus />} />

            <Route path="/app/account_settings" element={<AccountSettings />} />
            <Route path="/app/monitoring" element={<Monitoring />} />
            <Route path="/app/social" element={<Social />} />
            <Route path="/app/terms" element={<Terms />} />
            <Route path="/app/about" element={<About />} />
            <Route path="/app/terms" element={<Terms />} />
            {/* <Route path="/app/consultation" element={<Consultation />} /> */}
            <Route path="/app/plans-and-billing" element={<NewSubscription />} />
            <Route path="/app/paddle-subscription" element={<PaddleSubscription />} />
            <Route path="/app/checkout" element={<CheckOut />} />
            <Route path="/*" element={<Navigate to="/app/" />} />

          </Route>

          <Route path="/app/payment_success" element={<PaymentSuccess />} />
          <Route path="/app/policy" element={<PolicyPolicy />} />
          
          {/* {
           checkValue != "" ?
            <Route element={<WithNav />}>
                <Route path="/plans-and-billing" element={<NewSubscription />} />
              </Route> 
               :
               <Route element={<WithoutNav />}>
                <Route path="/plans-and-billing/:userId" element={<MobileSubscription />} />
               </Route>
            }  */}
          {/* just for future use the above code is there */}
        </Routes>

      </BrowserRouter>
    </>
  );
}

export default App;
