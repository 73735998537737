import React from "react";
import { Button, Container } from "react-bootstrap";
function About() {
  return (
    <Container>
      <h1 className="text-center py-5 my-5">About</h1>
    </Container>
  );
}

export default About;
