import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import react1 from "../images/react1.png";
import react2 from "../images/react2.png";
import react3 from "../images/react3.png";
import react4 from "../images/react4.png";
import all from "../images/reacts.png";
import ExposedImg from "../images/dual-reaction.png"
function FilterModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getSentimentData = (sentiment_value, label) => {
    console.log(sentiment_value + '====' + label);
    props.onChangeSortValue(sentiment_value, label);
    // this.setState({isVisible: false})
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="justify-centent-center">
        <Modal.Title id="contained-modal-title-vcenter" className="w-100">
          Filter By
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        
        {props.isExposed?
          <div className="all-react d-flex flex-wrap justify-content-between">
            <div className="each-react w-100" onClick={() => getSentimentData('all', 'All')}>
              <img src={ExposedImg} alt="" style={{ width: 100 }} />
              <p>All </p>
            </div>
            <button className="each-react" onClick={() => getSentimentData('exposed', 'Exposed')}>
              <img src={react2} alt="" />
              <p>Exposed</p>
            </button>
            <button className="each-react" onClick={() => getSentimentData('clear', 'Not exposed')}>
              <img src={react1} alt="" />
              <p>Not Exposed</p>
            </button>
          </div>
          : 
          
          <div className="all-react d-flex flex-wrap justify-content-between">
          <div className="each-react w-100" onClick={() => getSentimentData('ALL', 'All')}>
            <img src={all} alt="" style={{ width: 100 }} />
            <p>All Reactions</p>
          </div>
          <button className="each-react" onClick={() => getSentimentData('SENTIMENT_POSITIVE', 'Positives')}>
            <img src={react1} alt="" />
            <p>Positives</p>
          </button>
          <button className="each-react" onClick={() => getSentimentData('SENTIMENT_NEGATIVE', 'Negatives')}>
            <img src={react2} alt="" />
            <p>Negatives</p>
          </button>
          <button className="each-react" onClick={() => getSentimentData('SENTIMENT_NEUTRAL', 'Neutrals')}>
            <img src={react3} alt="" />
            <p>Neutrals</p>
          </button>
          <button className="each-react" onClick={() => getSentimentData('SENTIMENT_NOT_ME', 'Not me')}>
            <img src={react4} alt="" />
            <p>Not me</p>
          </button>
        </div>
        }

      </Modal.Body>
    </Modal>
  );
}

export default FilterModal;
