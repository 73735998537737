import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col, Image } from "react-bootstrap";
import { NavLink, redirect } from "react-router-dom";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from '../../apis';
import localService from "../../services/localService";
import { useNavigate, useSearchParams, useParams  } from "react-router-dom";
import loginLogo from '../../images/logo.png';
import axios from "axios";
import Footer from "./footer/Footer";
import Header from "./header/Header"


function QuickScan({ handleFormData, values }) {

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [lnameErr, setLnameErr] = useState(false);
  const [fnameErr, setFnameErr] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [blankFname, setBlankFname] = useState(true);
  const [blankLname, setBlankLname] = useState(true);
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const params = useParams();
  const paramFname = params.fname; 
  const hasParam = (paramFname !== null && paramFname !== undefined) ? true : false;
  
  const paramLname = params.lname;
  const paramAge = params.age;

  useEffect(() => {
    console.log(params);
    getLocation();
    if(localStorage.getItem('customer_current_saved_email') !== null) {
      navigate("/app/scanning-result");
    } else {
      if(paramFname !== null && paramLname !== null && paramAge !== null && paramFname !== undefined && paramLname !== undefined && paramAge !== undefined) {
        setFname(paramFname);
        setLname(paramLname);
        setAge(paramAge);
        onCompleteStep1();
      }
    }
  }, []);  

  const handleFirstName = (e) => {
    setFname(e.target.value);
  }
  const handleLastName = (e) => {
    setLname(e.target.value);
  }
  const handleAge = (e) => {
    setAge(e.target.value);
  };


  const onCompleteStep1 = () => {
    setShowLoader(true);
    let payload = {
      firstname: (paramFname !== null  && paramFname !== undefined ) ? paramFname : fname,
      lastname: (paramLname !== null  && paramLname !== undefined ) ? paramLname : lname,
      age: (paramAge !== null  && paramAge !== undefined ) ? paramAge : age,
      email: email,
    };
    console.log(payload);
    const response = api.scan
      .scanstepone(payload)
      .then((response) => {
        setShowLoader(false);
        console.log("res......", response);
        if (response.data.status) {
          localStorage.setItem(
            "guest_user_data",
            JSON.stringify(response.data.data)
          );
          localStorage.setItem('guest_user_data_email', response.data.data.email);
          navigate("/app/current-location-scan");
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        console.log(err.message);
        console.log(err);
      });
  };

  const validate = () => {
    return (
      fname.length &&
      lname.length &&
      age.length > 0 &&
      age.length <= 3 &&
      age <= 150
    );
  };

  const getLocation = async () => {
    const location = await axios.get("https://ipapi.co/json");
    console.log(location);
    localStorage.setItem('user_current_location_country' ,location.data.country);
    localStorage.setItem('user_current_location_state' ,location.data.region);
    localStorage.setItem('user_current_location_state_code' ,location.data.region_code);
    localStorage.setItem('user_current_location_city' ,location.data.city);
    localStorage.setItem('user_current_location_country_name' ,location.data.country_name);
};


  return (
    <>
      {hasParam == false ?
        <>
          <Header/>
          <div className="log-reg-header position-relative px-3 guest-user-header">
            <Container>
                <Row>
                  {/* <Col lg={12}>
                    <div className="topBarLogo">
                      <Image src={loginLogo} alt="Login Logo" width={200}/>
                    </div>
                  </Col> */}
                  <Col lg={12}>
                    <div className="text-center welcomeHeads">
                      <h2>Start a quick scan</h2>
                      <p style={{ paddingTop: 10, lineHeight: .5}}>See if your address, phone number or other <b>info is exposed in</b> <b>seconds —</b> learn how to remove in a few easy steps.</p>
                    </div>
                  </Col>
                </Row>
              </Container>
          </div>

          <div className="log-reg-footer  position-relative guest-user-footer" id="guest-user-footer" style={{height:380, marginBottom:250}}>
            <Form>
              <Form.Group >
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: fnameErr ? "#F88D99" : "" }}
                    name="firstName"
                    maxLength={25}
                    type="text"
                    placeholder="First Name"
                    value={fname}
                    onChange={(e) => {
                      handleFirstName(e)
                    }}
                  />
                  <label htmlFor="fname">First name</label>
                </Form.Floating>

              </Form.Group>

              <Form.Group>
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: lnameErr ? "#F88D99" : "" }}
                    name="lastName"
                    maxLength={25}
                    type="text"
                    placeholder="Last Name"
                    value={lname}
                    onChange={(e) => {
                      handleLastName(e)
                    }}
                  />
                  <label htmlFor="lname">Last name</label>
                </Form.Floating>
              </Form.Group>

              <Form.Group>
                <Form.Floating className="mb-4">
                  <Form.Control
                    style={{ background: lnameErr ? "#F88D99" : "" }}
                    name="age"
                    maxLength={3}
                    type="number"
                    placeholder="Age"
                    value={age}
                    onChange={(e) => {
                        handleAge(e);
                    }}
                  />
                  <label htmlFor="email">Age</label>
                </Form.Floating>
              </Form.Group>

            

              <Button
                className="form-control subButton btn btn-theme"
                type="button"
                onClick={onCompleteStep1}
                disabled={!validate() || showLoader}
              >
                Scan For Free <FontAwesomeIcon icon={faArrowRight} />
                {showLoader ?
                  <FontAwesomeIcon icon={faSpinner} className="spinner" />
                  : null}
              </Button>
              
            </Form>
          </div>
          <Footer />
        </>
      :
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} className="spinner" />
          <span>Loading...</span>
        </div>
      }
      
    </>
  );
}

export default QuickScan;
