import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.scss";
import { useNavigate, redirect, } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCalendar, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutFrom";
// import StripeCheckout from "react-stripe-checkout";
import GlobalService from "../../services/globalService";
import api from "../../apis";
import localService from "../../services/localService";
import { toast } from 'react-toastify';

function Index() {
  const [clientSecret, setClientSecret] = useState("fghfg");
  const [paymentValue, setPaymentValue] = useState("");
  const [toggle, setToggle] = useState(true);
  const [productsMonthly, setProductsMonthly] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // openStipePage();
    document.getElementById("toggle").click();
    // testStripe();
    // testApi();
    // testApi2();
    // testApi3();
    stripeProductLists()
    // toggleFunc()
  }, [])

  const stripeProductLists = () => {
    setShowLoader(true)

    const response = api.stripeProductLists.get().then((response) => {
      console.log('stripeProductLists', response)
      if (response.data.status) {
        setShowLoader(false);
        // document.getElementById("toggle").click();
        setProductsMonthly(response.data.data);
      } else {
        setShowLoader(false);
        //   // toast.error(response.data?.message || "Something went wrong");
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      // setDashboardApiError(err.message)
      // console.log('err.message from adsboard api', err.message)
      // toast.error(err.message);
    });

  }


  const testApi = () => {
    // API for get requests
    let fetchRes = fetch('https://api.stripe.com/v1/products', {
      headers: { 'Authorization': 'Bearer ' + "sk_test_51MTSk0KfBR346qLzQRXDqC57B36zGZfNsUwyI7WjUWeBuGpXnumLbc3a9xXOp0iO1L9GYo9t7tknGiVzGfaMrRFB00v8l6xUJf" }
    })
    // .then(response => response.json())
    // // etc ...
    fetchRes.then(res =>
      res.json()).then(response => {
        console.log('\n\n\n rsponse of products: \n', response);
        setProductsMonthly(response.data);

      })

  }


  const testApi2 = () => {
    // API for get requests
    let fetchRes = fetch('https://api.stripe.com/v1/prices', {
      headers: { 'Authorization': 'Bearer ' + "sk_test_51MTSk0KfBR346qLzQRXDqC57B36zGZfNsUwyI7WjUWeBuGpXnumLbc3a9xXOp0iO1L9GYo9t7tknGiVzGfaMrRFB00v8l6xUJf" }
    })
    // .then(response => response.json())
    // // etc ...
    fetchRes.then(res =>
      res.json()).then(response => {
        console.log('\n\n\n rsponse of price: \n', response);
        setPriceList(response.data);

      })

  }

  const testApi3 = () => {
    // let payload = {
    // 	priceid : "price_1MZsO3KfBR346qLzmji1c2TN"
    // }
    fetch('https://api.stripe.com/v1/prices/price_1MZsO3KfBR346qLzmji1c2TN', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + "sk_test_51MTSk0KfBR346qLzQRXDqC57B36zGZfNsUwyI7WjUWeBuGpXnumLbc3a9xXOp0iO1L9GYo9t7tknGiVzGfaMrRFB00v8l6xUJf"
      },
      // body: JSON.stringify("price_1MZsO3KfBR346qLzmji1c2TN"),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }
  const testStripe = async () => {
    // async function testStripe() {
    let genCard = {
      'card[number]': "4242424242424242",
      'card[exp_month]': "11",
      'card[exp_year]': "25",
      'card[cvc]': "111"
    }

    const results = await fetch("https://api.stripe.com/v1/tokens", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + "sk_test_51MZsuASJJvAV40GYGCHXZEFokupsN1Ag9wK80UHf1jwwquMs0h756VWfXIswOOeejj4NEbYxVGqODSSATa2bFgIV00EAPQ7up1",
      },
      body: Object.keys(genCard)
        .map(key => key + '=' + genCard[key])
        .join('&'),
    }).then(response => response.json())

    console.log('\n\n\nlogging: \n', genCard, '\n', 'blank', '\n', results)

    return
  }

  const checkoutPage = (priceId) => {
    

    let prices = productsMonthly;
    for (var i = 0; i < prices.length; i++) {
      if (prices[i].plan_duration === "Monthly" && prices[i].stripe_pricing_id == priceId) {
        var priceListMonth = prices[i].price;
        var priceMonthId = prices[i].stripe_pricing_id;
        localService.setUserStripePrice(priceListMonth);
        localService.setUserStripePriceID(priceMonthId);
        GlobalService.stripePriceValue = priceListMonth;
        GlobalService.stripePriceId = priceMonthId;
      }
    }
    navigate("/app/checkout")
  }

  const checkoutPageYearly = (priceId) => {
   
    
    let prices = productsMonthly;
    for (var i = 0; i < prices.length; i++) {
      if (prices[i].plan_duration === "Yearly" && prices[i].stripe_pricing_id == priceId) {
        var priceListYear = prices[i].price;
        var priceYearId = prices[i].stripe_pricing_id;
        localService.setUserStripePrice(priceListYear);
        localService.setUserStripePriceID(priceYearId);
        GlobalService.stripePriceValue = priceListYear;
        GlobalService.stripePriceId = priceYearId;
      }
    }
    navigate("/app/checkout")
    navigate("/app/checkout")
  }


  // const monthlyPrice = (prices, prodId) => {
  //   for (var i = 0; i < prices.length; i++) {
  //     if (prices[i].recurring.interval === "month" && prices[i].product == prodId) {
  //       var priceListMonth = prices[i].unit_amount / 100;
  //     }
  //   }
  //   return priceListMonth
  // }

  const monthlyPrice = (priceId) => {
    let prices = productsMonthly;
    for (var i = 0; i < prices.length; i++) {
      if (prices[i].plan_duration === "Monthly" && prices[i].stripe_pricing_id == priceId) {
        var priceListMonth = prices[i].price;
        // localService.setUserStripeData(priceListMonth);
      }
    }
    // console.log('priceListMonth', priceListMonth)
    return priceListMonth
  }

  const yearlyPrice = (priceId) => {
    let prices = productsMonthly;
    for (var i = 0; i < prices.length; i++) {
      if (prices[i].plan_duration === "Yearly" && prices[i].stripe_pricing_id == priceId) {
        var priceListYear = prices[i].price;
      }
    }
    return priceListYear
  }

  // const yearlyPrice = (prices, prodId) => {
  //   for (var i = 0; i < prices.length; i++) {
  //     if (prices[i].recurring.interval === "year" && prices[i].product == prodId) {
  //       var priceListYear = prices[i].unit_amount / 100;
  //     }
  //   }
  //   return priceListYear
  // }

  const toggleFunc = () => {
    setToggle(!toggle)
  }

  return (



    <>
      <div className="subscribtion-banner">
        <div className="ban-txt">
          <p className="ban-head">Pricing That Fits Your Needs</p>
          <p>
            Select the plan that best fits your needs. You can upgrade or
            downgrade at any time.
          </p>
          <b>Pay annually and save up to 25%</b>
          <div className="toggle">
            <span>Monthly</span>
            <label class="switch">
              <input type="checkbox" id="toggle" onClick={() => setToggle(!toggle)} />
              <span class="slider round"></span>
            </label>
            <span>Annual</span>
          </div>
        </div>
        {/* {
          toggle? console.log('toggle'):console.log('not')
        } */}

      </div>

      {showLoader ?
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} className="spinner"
          />
          <span>Loading...</span>
        </div>
        :
        <>


          {toggle ?
            <>


              <Container className="py-md-5 py-sm-2">
                <Row className="py-md-5 py-sm-2">
                  <Col md={3} className="px-0">
                    <div className="each-price">
                      <small>Plan</small>
                      <h4>Intro</h4>
                      <p className="price">Free</p>
                      <p className="time">Start Your FREE 30 Days Trial</p>
                      {/* <StripeCheckout
                stripeKey="pk_test_51MZsuASJJvAV40GY4SmFfeA49FsJ6W5hCXDxPj1uxV2FglXHXuZEezxUK26PcGveXwTUGWbsaX9Pa7Pqqr16Lzfg00c4oNpVKz"
                // token={makePayment}
                name="Buy React"
                amount={100}
              // shippingAddress
              // billingAddress
              >
                <button>Sign Up</button>
              </StripeCheckout> */}

                      <button
                      // onClick={checkoutPage}
                      >Sign Up</button>


                      <p>Protect Your Personal & Private Information Online Today</p>
                      <p className="offer">2 Databases Removed with 1 Phone number protection</p>
                    </div>
                  </Col>

                  {
                    productsMonthly.length > 0 ?
                      (productsMonthly.map((res, index) => {
                        return (
                          <>
                            {
                              res.plan_duration === "Monthly" ?

                                <Col md={3} className="px-0">

                                  <div className="each-price">
                                    <small>Plan</small>
                                    <h4>{res.name}</h4>
                                    {
                                      // priceList && priceList.length > 0 ?
                                      <p className="price">${monthlyPrice(res.stripe_pricing_id)}</p>
                                      // : "0"
                                    }

                                    <p className="time">Start Your FREE 30 Days Trial</p>

                                    {/* <button onClick={() => checkoutPage(priceList, res.id)}>Sign Up</button> */}
                                    <button onClick={() => checkoutPage(res.stripe_pricing_id)}>Sign Up</button>
                                    <p>Protect Your Personal & Private Information Online Today</p>
                                    <p className="offer">2 Databases Removed with 1 Phone number protection</p>
                                  </div>


                                </Col>

                                : null}
                          </>

                        )
                      })) : <span className="no-result">No datarrr</span>
                  }

                </Row>
              </Container>



            </>
            :
            <>


              <Container className="py-md-5 py-sm-2">
                <Row className="py-md-5 py-sm-2">
                  <Col md={3} className="px-0">
                    <div className="each-price">
                      <small>Plan</small>
                      <h4>Intro</h4>
                      <p className="price">Free</p>
                      <p className="time">Start Your FREE 30 Days Trial</p>
                      {/* <StripeCheckout
                stripeKey="pk_test_51MZsuASJJvAV40GY4SmFfeA49FsJ6W5hCXDxPj1uxV2FglXHXuZEezxUK26PcGveXwTUGWbsaX9Pa7Pqqr16Lzfg00c4oNpVKz"
                // token={makePayment}
                name="Buy React"
                amount={100}
              // shippingAddress
              // billingAddress
              >
                <button>Sign Up</button>
              </StripeCheckout> */}

                      <button
                      // onClick={checkoutPage}
                      >Sign Up</button>


                      <p>Protect Your Personal & Private Information Online Today</p>
                      <p className="offer">2 Databases Removed with 1 Phone number protection</p>
                    </div>
                  </Col>
                  {productsMonthly.length > 0 ?
                    (productsMonthly.map((res, index) => {
                      return (
                        <>
                          {
                            res.plan_duration === "Yearly" ?
                              <Col md={3} className="px-0">
                                <div className="each-price">
                                  <small>Plan</small>
                                  <h4>{res.name}</h4>
                                  {
                                    // priceList && priceList.length > 0 ?
                                    <p className="price">${yearlyPrice(res.stripe_pricing_id)}</p>
                                    // : "0"
                                  }
                                  <p className="time">Start Your FREE 30 Days Trial</p>
                                  <button onClick={() => checkoutPageYearly(res.stripe_pricing_id)}>Sign Up</button>
                                  <p>Protect Your Personal & Private Information Online Today</p>
                                  <p className="offer">2 Databases Removed with 1 Phone number protection</p>
                                </div>
                              </Col>
                              : null}
                        </>
                      )
                    })) : <span className="no-result">No data</span>
                  }
                </Row>
              </Container>

            </>



          }



        </>
      }

    </>

  );
}

export default Index;
