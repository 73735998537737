import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Button, Col, Container, Row, Card, Alert } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../apis"
import { faSpinner, faFlagUsa, faChevronDown, faChevronUp, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GlobalService from "../../services/globalService";
import EmailVerify from "../../images/emailverify.png";
import NoImage from "../../images/no-image.png";
import LoaderImg from "../../images/download.gif";
import LoadingCircle from "../../images/loading-circle.gif";
import Table from 'react-bootstrap/Table';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Pagination from "../pagination/Pagination";
import Header from "./header/Header";
import Modal from 'react-bootstrap/Modal';
import Footer from "./footer/Footer";
import { toast } from 'react-toastify';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import "./style/SearchResult.scss";

let PageSize = 200;

function ScanningResult(props) {
  const [showScanningBox, setShowScanningBox] = useState(false);
  const [privacyResult, setPrivacyResult] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  const [showResults, setShowResults] = useState(false);
  const [privacyError, setPrivacyError] = useState("");
  const [exposedDataCount, setTotalExposedCount] = useState(0);
  const [totalSiteCount, setTotalSiteCount] = useState(GlobalService.totalSiteToBeScanned);
  const [totalSiteScannedCount, setTotalSiteScannedCount] = useState(0);
  const [remainingSiteScannedCount, setRemainingSiteScannedCount] = useState(0);
  const [checkStatus, setCheckStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  

  const navigate = useNavigate();

  var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));

  /*Following arrays are created for the table skeleton */
  const skeletonRowNumbers = ["1", "2", "3", "4" , "5", "6", "7", "8" , "9" , "10" ];
  const skeletonCellNumbers = ["1", "2", "3", "4" , "5" , "6", "7"];

  useEffect(() => {
    localStorage.setItem('current_timestamp',new Date().valueOf());

    if(localStorage.getItem('guest_user_data_email') !== null && localStorage.getItem('customer_current_saved_email') !== null) {
      if(localStorage.getItem('guest_user_data_email') == localStorage.getItem('customer_current_saved_email')) {
        if(localStorage.getItem('scan_page_results') !== null) {
           setPrivacyResult(JSON.parse(localStorage.getItem('scan_page_results'))); 
           setShowResults(localStorage.getItem('show_results'));
           setTotalExposedCount(localStorage.getItem('exposed_site_data_counter'));
           setTotalSiteCount(localStorage.getItem('total_sites'));
           setTotalSiteScannedCount(localStorage.getItem('total_site_scanned'));
           setRemainingSiteScannedCount(localStorage.getItem('remaining_site_scanned'));
           privacyApiCall('','final_call');
        } else {
          privacyApiCall('','final_call');
        }
        
      } else {
        setShowScanningBox(true);
        callPrivacyApiFunction();
      }
    } else {
      setShowScanningBox(true);
      callPrivacyApiFunction();
    }
    

    if(localStorage.getItem('redirected_from') == 'scan-result') {
      setShowModal(true);
      setShowScanningBox(false);
    }
    
  }, []);


  const startNewScan = () => {
    localStorage.removeItem("guest_user_data");
    localStorage.removeItem("guest_user_data_email");
    localStorage.removeItem("removal_request_applied_for_the_site_name_details");
    localStorage.removeItem("customer_current_saved_email");
    localStorage.removeItem('scan_page_results');
    localStorage.removeItem('show_results');
    localStorage.removeItem('exposed_site_data_counter');
    localStorage.removeItem('total_sites');
    localStorage.removeItem('total_site_scanned');
    localStorage.removeItem('remaining_site_scanned');
    navigate('/app/quick-scan');
  }

  let totalSites = 0;
  let totalScannedSites = 0;

  let currentTableData = [];
  if (privacyResult.length > 0) {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    currentTableData = (privacyResult.slice(firstPageIndex, lastPageIndex));
  }

  const getTimeDifference = () => {
    var createdDateTimestamp = localStorage.getItem('current_timestamp');
    var currentTimestamp = new Date().valueOf(); //converting into timestamp
    var diff = currentTimestamp - createdDateTimestamp;
    var minutesDifference = Math.round((diff/1000)/60);
    return minutesDifference;
  }


  const callPrivacyApiFunction = () => {
    const TIMING = 1000;

    let minutesDifference = getTimeDifference();
    let hourDifference = minutesDifference / 60;

    var interval = 0;
    let setIntervalFlag = false;

    privacyApiCall();
    
    if(!setIntervalFlag) {
      if ((hourDifference <= 5 && totalSiteScannedCount < totalSiteCount) ||(hourDifference <= 5 && totalSiteCount == 0)) {
        interval = setInterval(() => {
          let minDiff = getTimeDifference()
          console.log('logs in every 1 seconds...'+minDiff);
          console.log(totalSites);
          privacyApiCall('retrieve_webhook');
          if (((totalScannedSites == totalSites) && (totalSites !== 0)) || ((totalScannedSites !== totalSites) && (totalSites !== 0) && (minDiff >= 3)) || (totalSites == 0 && minDiff >= 3)) {
            setShowScanningBox(false);
            clearInterval(interval);

            privacyApiCall('','final_call'); /*This API is called to load the extra site details which are not there with the user's scan result*/
          } 
        }, TIMING);
      }
      setIntervalFlag = true;
    }
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }


  function checkIfImageExists(url, callback) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
      img.onerror = () => {
        callback(false);
      };
    }
  }


  const removeExposedInfo = (site_title = null, index =  null) => {

    if(site_title == null && index == null) {
      if (localStorage.getItem("localStorage_userData") !== null) {
        navigate('/app/privacy')
      } else {
        localStorage.setItem('scan_page_from','main_exposed_info_btn');
        navigate('/app/guest-registration');
      }

    } else {
      const LoggedInuserData = GlobalService.localStorageData;
      if (localStorage.getItem("localStorage_userData") !== null) {
        if(index == null) {
          index = 0;
        }
        document.getElementById("exposed-btn-spinner-"+index).style.display = "inline-block";
        document.getElementById("exposed-btn-container-"+index).classList.add("disableClass");
        var user_data = JSON.parse(localStorage.getItem('localStorage_userData'));
        let flag = 0;

        /*DO NOT REMOVE THE FOLLOWING IF CONDITION. The following "if" condition is written to provide user  the offer of free data removals of 2 sites under the free plan  */
        //if(localStorage.getItem('removal_request_applied_for_the_site_name_details') !== null && localStorage.getItem('removal_request_applied_for_the_site_name_details') !== '') {
          //if(JSON.parse(localStorage.getItem('removal_request_applied_for_the_site_name_details')).length == 2) {
            let payload = {
              user_id: user_data.id,
            }
            api.privacyApi.getSubscriptionData(payload).then(async (response) => {
              let subscriptionData = response.data.subscription_data;
              if(subscriptionData.length == 0){
                flag = 1;
              }
              if(subscriptionData.length > 0) {
                localStorage.setItem('current_subscription_data',subscriptionData);
                if(subscriptionData[subscriptionData.length - 1].status.toLowerCase() === "cancelled") {
                  flag = 1;
                }
              }
              if(flag == 1) {
                setShowModal(true);
                document.getElementById("exposed-btn-spinner-"+index).style.display = "none";
                document.getElementById("exposed-btn-container-"+index).classList.remove("disableClass");
              } else {
                upDateSubscription(user_data.email, site_title, index);
              }
            }).catch((err) => {
              console.log(err)
            });
          // } else {
          //   upDateSubscription(user_data.email, site_title, index);
          // }
        // } else {
        //   upDateSubscription(user_data.email, site_title, index);
        // }
      } else {
        //localStorage.setItem('redirected_from','scan-result');
        navigate('/app/guest-registration');
      }
    }
  }

  const upDateSubscription = (email, site_title,index) => {
    let updatePayload = {
      email: email, 
      site_title: site_title
    }
    api.privacyApi.updateSubscriptionData(updatePayload).then(async (response) => {
      console.log(response);
      if(response.data.status == true) {
        toast.success('Your request for data removal has been received. You can track the status using the tabs located at the top of privacy table.');
        localStorage.removeItem('removal_request_applied_for_the_site_name_details');
        localStorage.setItem('removal_request_applied_for_the_site_name_details',JSON.stringify(response.data.removal_request_applied_for_the_site_name_details))
        privacyApiCall();
      }
    }).then((f) => {
      const timer = setTimeout(() => {
        // document.getElementById("exposed-btn-spinner-"+index).style.display = "none";
        document.getElementById("exposed-btn-container-"+index).classList.remove("disableClass");
      }, 1500);
      return () => clearTimeout(timer);
      
    });
  }

  const closeModal = () => {
    setShowModal(false);
    localStorage.removeItem('redirected_from');
  }


  const privacyApiCall = (requestType = null, finalCall = null) => {
   
    var guest_user_data = JSON.parse(localStorage.getItem('guest_user_data'));

    var createdDateTimestamp = localStorage.getItem('current_timestamp');
    var currentTimestamp = new Date().valueOf(); //converting into timestamp
    var diff = currentTimestamp - createdDateTimestamp;
    var difference = (diff/1000);

    let payload = {
      user_id: guest_user_data.id,
      type: 'guest_user',
      finalCall : finalCall
    }
    console.log('requestType = '+requestType);
    
    let api_url = '';
    if(requestType == null || requestType == '') {
      console.log('old api call');
      api_url = api.privacyApi.privacyApi(payload);
    } else {
      console.log('new api call');
      api_url = api.privacyApi.retrievePrivacyWebhookDataApi(payload);console.log(api_url);
    }

    

    const response = api_url.then(async (response) => {
      console.log(response);

      if (response.data.status) {

        totalSites = response.data.total_sites;
        totalScannedSites = response.data.total_site_scanned;
        
        /*********************** Validating the favicon and site-logo *****************************/
        if (response.data.results.length > 0) {
          //let resultData = response.data.results;
          // for (let i = 0; i < resultData.length; i++) {
          //   checkIfImageExists(resultData[i]['icon'], (exists) => {
          //     if (exists) {
          //       resultData[i]['site_icon'] = resultData[i]['icon'];
          //     } else {
          //       checkIfImageExists(resultData[i]['logo'], (existLogo) => {
          //         if (existLogo) {
          //           resultData[i]['site_icon'] = resultData[i]['logo'];
          //         } else {
          //           resultData[i]['site_icon'] = NoImage;
          //         }
          //       })
          //     }
          //   })
          // }
         
          setPrivacyResult(response.data.results); 
          localStorage.setItem('scan_page_results',JSON.stringify(response.data.results));

          setShowResults(response.data.show_results);
          localStorage.setItem('show_results',response.data.show_results);
          //setTotalExposedCount(response.data.exposed_count);

          setTotalExposedCount(response.data.exposed_site_data_counter);
          localStorage.setItem('exposed_site_data_counter',response.data.exposed_site_data_counter);

          setTotalSiteCount(response.data.total_sites);
          localStorage.setItem('total_sites',response.data.total_sites);

          setTotalSiteScannedCount(response.data.total_site_scanned);
          localStorage.setItem('total_site_scanned',response.data.total_site_scanned);

          setRemainingSiteScannedCount(response.data.remaining_site_scanned);
          localStorage.setItem('remaining_site_scanned',response.data.remaining_site_scanned);

          if(requestType !== null && requestType !== '') {
            localStorage.setItem('customer_current_saved_email',response.data.customer_email);
          }

          if(requestType == null) {
            //await setShowLoader(false);
          }

          // setTimeout(async () => {
          //   await setCheckStatus(true);
          // }, 4000);
        } else {
          setShowLoader(false);
        }
        /*********************** Validating the favicon and site-logo *****************************/
      } else {
        setShowLoader(false);
      }
    }).catch((err) => {
      console.log(err)
      setShowLoader(false);
      setPrivacyError(err.message)
      console.log('err.message-------------', err.message)
    });
  }

  const showDetails = (index) => {

    let linkText = document.getElementById('show-hide-details-link-' + index).textContent;

    if(linkText.indexOf('Show') > -1) {
      document.getElementById('show-hide-details-link-' + index).textContent = 'Hide';
      document.getElementById('privacy-details-'+index).classList.remove("hideContainer");
      document.getElementById('show-details-link-' + index).classList.add("hideContainer");
      document.getElementById('hide-details-link-' + index).classList.remove("hideContainer");

      document.getElementById('all-relatives-container-' + index).classList.remove("hideContainer");
      document.getElementById('relatives-container-' + index).classList.add("hideContainer");
    } else {
      document.getElementById('show-hide-details-link-' + index).textContent = 'Show';
      document.getElementById('privacy-details-'+index).classList.add("hideContainer");
      document.getElementById('show-details-link-' + index).classList.remove("hideContainer");
      document.getElementById('hide-details-link-' + index).classList.add("hideContainer");

      document.getElementById('all-relatives-container-' + index).classList.add("hideContainer");
      document.getElementById('relatives-container-' + index).classList.remove("hideContainer");
    }
  }

  const redirectToPlanPage = () => {
    localStorage.removeItem('redirected_from');
    setShowModal(false);
    navigate('/app/paddle-subscription');
  }

  return (
    <>
    {console.log(exposedDataCount)}
      {showLoader ? (
        <div className="loader">
          <FontAwesomeIcon icon={faSpinner} className="spinner" />
          <span>Loading...</span>
        </div>
      ) : (
        <>
         <Header />
        <div className="mainFullResult">
          <Container>
            <div className="container my-4">
              {(showResults && !showScanningBox) && (
                <div className="center-align">
                  <h1 class="pb-3 pt-5 privacy-heading">
                    We Found <span className="text-exposed">{exposedDataCount}</span> Pieces of Exposed Info About You
                  </h1>
                  <p className="privacy-sub-heading">
                    <b>You may be at high risk</b> of identity theft, hacks, spam,
                    robo-dials and other threats.
                  </p>
                  <p className="privacy-sub-heading">
                    Sign up below to automatically monitor & remove your info from
                    these sites.
                  </p>
                </div>
              )}
              {privacyError != "" ? (
                <div className="verify">
                  <Card className="verify-card">
                    <Card.Body>
                      <img src={EmailVerify} />
                      <h6 style={{ margin: 0, top: "60%" }}>
                        No privacy results to display.
                        
                      </h6>
                    </Card.Body>
                  </Card>
                </div>
              ) 
              // : !showResults ? (
              //   <div className="verify">
              //     <Card className="verify-card">
              //       <Card.Body>
              //         <img src={EmailVerify} />
              //         <div>
              //           <h6>
              //             Your result is being processed, we will notify you when
              //             it is ready.
              //           </h6>
              //         </div>
              //       </Card.Body>
              //     </Card>
              //   </div>
              // ) 
              : (
                <>
                  <Row className="progressBarCustom">
                  {showScanningBox && 
                    <>
                      <Col lg={12}>
                        <h1 className="text-center pt-4">
                          Scanning the web for your personal information:
                        </h1>
                      </Col>
                      <Col lg={12} className="progressbar-container">
                        <div className="progressContnt text-center">
                          <h2>
                            <img src={LoadingCircle} />{" "}
                            Scanning...{" "}
                          </h2>
                          <div className="statusProgres">
                            <p>{totalSiteScannedCount} of {totalSiteCount} data sites scanned for your private info</p>
                            <ProgressBar className="progressbar" now={(totalSiteScannedCount / totalSiteCount) * 100} />

                            <div className="counterBar d-flex justify-content-between">
                              <span>{totalSiteScannedCount} SITES</span>
                              <span>{totalSiteCount} SITES</span>
                            </div>
                          </div>
                          
                        </div>
                      </Col>
                    </>
                  }

                  {/* {!showScanningBox &&
                      <>
                        <Col lg={12} className="progressbar-container">
                        <div className="progressContnt text-center">
                            <h2>You can request a maximum of 2 'Privacy Data Removals'. </h2>
                            <h2>To request more, please purchase a subscription</h2>
                        </div>
                      </Col>
                      </>
                  } */}

                    {privacyResult.length > 0 &&
                      <Col lg={12} className="center-aligned pb-4">
                        <a className="button-standard btn-theme" onClick={() => removeExposedInfo()}>
                            Remove My Exposed Info
                        </a>
                        <a className="new-scan-link" onClick={startNewScan}>Start New Scan</a>
                      </Col>
                    }
                  </Row>
                  <Row className="privacy-table-container">
                    {privacyResult.length > 0 ? (
                      <Fragment>
                        <div className="total-privacy">
                        <Table className="all-privacy" striped>
                          <thead>
                            <tr>
                              <th className="site-header">SITE</th>
                              <th>NAME</th>
                              <th>LOCATION</th>
                              <th className="relative-header">RELATIVES</th>
                              <th>PHONE</th>
                              <th>MORE INFO</th>
                              <th>STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData.map((exposed, index) => {
                              return (
                                <>
                                  {exposed.exposing_status !== 'clear' && 
                                    <>
                                      <tr
                                        key={exposed.key}
                                        className={(index%2 == 0) ? 'gray-row' : 'white-row'}
                                        onClick={() =>
                                          showDetails(index)
                                        }
                                      >
                                        <td
                                          verticalAlign="middle"
                                          className=""
                                        >
                                          <img
                                            id="imgg"
                                            className="logo-img"
                                            // src={
                                            //   exposed.site_icon != 'undefined'
                                            //     ? exposed.site_icon
                                            //     : exposed.site_icon == undefined
                                            //     ? LoaderImg
                                            //     : NoImage
                                            // }
                                            src={
                                              exposed.site_icon != '' ? exposed.site_icon : NoImage
                                            }
                                          />
                                          <h4>{exposed.title}</h4>
                                        </td>
                                        <td verticalAlign="middle">
                                          <div className="d-flex flex-column">
                                            {" "}
                                              <h5>{exposed.name}</h5>
                                            {" "}
                                            {(exposed.age !== '-') ? (<h6>Age: <span>{exposed.age}</span></h6>) : ''}
                                          </div>

                                        </td>
                                        <td verticalAlign="middle" className="wrap-data blur">
                                          <h5>{exposed.location.replace(/[^ ]/g, '*')}</h5>
                                        </td>
                                        <td verticalAlign="middle">
                                          <h5 style={{ whiteSpace: "pre-wrap"}}>
                                            <span id={'relatives-container-'+index}>{exposed.relatives}{(exposed.no_of_relatives > 3) && <span>...</span>}</span>
                                            <span id={'all-relatives-container-'+index} className="hideContainer">{exposed.all_relatives}</span>
                                          </h5>
                                        </td>
                                        <td verticalAlign="middle" className="blur">{exposed.phone_number.replace(/[^ ]/g, '*')}</td>
                                        
                                          <td verticalAlign="middle">
                                            {exposed.exposing_status != 'clear' && exposed.exposing_status != 'removed' && exposed.exposing_status != '-' &&
                                              <>
                                                <span id={'show-hide-details-link-' + index} className="hideContainer">Show</span>
                                                <a id={'show-details-link-' + index} href="javacript:void(0);">
                                                  Show{" "}
                                                  <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                    style={{ color: "#0d6efd" }}
                                                  />
                                                </a>
                                                <a id={'hide-details-link-' + index} className="hideContainer" href="javacript:void(0);">
                                                  Hide{" "}
                                                  <FontAwesomeIcon
                                                    icon={faChevronUp}
                                                    style={{ color: "#0d6efd" }}
                                                  />
                                                </a>
                                              </>
                                            }
                                          </td>
                                        
                                        <td>
                                          {/* {exposed.exposed_status ? (
                                            
                                          ) : null} */}
                                          {exposed.exposing_status == 'exposed' && 
                                            <span className="exposed-tag">
                                              <i
                                                class="fa fa-warning"
                                                aria-hidden="true"
                                              ></i>
                                              &nbsp; Exposed
                                            </span>
                                          }
                                          {exposed.exposing_status == 'clear' && 
                                            <span className="not-exposed-tag">
                                              <i
                                                class="fa fa-check"
                                                aria-hidden="true"
                                              ></i>
                                              &nbsp; Clear
                                            </span>
                                          }
                                          {exposed.exposing_status == 'applied' && 
                                            <span className="in-progress-tag">
                                                <i
                                                  class="fa fa-info-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              &nbsp; In Progress
                                            </span>
                                          }
                                          {exposed.exposing_status == 'removed' && 
                                            <span className="remove-tag">
                                                <i
                                                  class="fa fa-check"
                                                  aria-hidden="true"
                                                ></i>
                                              &nbsp; Removed
                                            </span>
                                          }
                                          {exposed.exposing_status == 'action_required' && 
                                              <span className="action-required-tag">
                                                  <i
                                                    class="fa fa-info-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                &nbsp; Action Required
                                              </span>
                                          }
                                          {exposed.exposing_status == 'probable' && 
                                              <span className="probable-tag">
                                                  <i
                                                    class="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                &nbsp; Probable
                                              </span>
                                          }
                                          {exposed.exposing_status == '-' && 
                                              <span className="probable-tag">
                                                <i
                                                    class="fa fa-exclamation-circle"
                                                    aria-hidden="true"
                                                  ></i>
                                                &nbsp; Probable
                                              </span>
                                              // <h5>N/A</h5>
                                          }
                                        </td>
                                      </tr>
                                      <tr id={'privacy-details-' + index} className="detailsTableCollapse hideContainer">
                                        <td colSpan={2}>
                                          <div className="collapseBox">
                                            <h2>This site also exposes your:</h2>
                                            <ul>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faTriangleExclamation}
                                                  style={{ color: "#ff0000" }}
                                                />{" "}
                                                Date of birth
                                              </li>
                                              <li>
                                                <FontAwesomeIcon
                                                  icon={faTriangleExclamation}
                                                  style={{ color: "#ff0000" }}
                                                />{" "}
                                                Court, arrest, criminal & civil records
                                              </li>
                                              
                                            </ul>
                                          </div>
                                        </td>
                                        <td colSpan={2}>
                                          <div className="collapseBox">
                                            <h2>This info puts you at risk of:</h2>
                                            <ul>
                                              <li>Identity theft</li>
                                              <li>Robocalls</li>
                                              <li>Email spam</li>
                                              <li>Hackers</li>
                                              <li>Stalkers and creeps</li>
                                              <li>Companies buying your data</li>
                                            </ul>
                                          </div>
                                          <div id={'exposed-btn-container-' + index}>
                                            {exposed.request_removal_applied == 'true' ? 
                                              <a className="button-standard already-requested-button" href="javascript:void(0);">
                                                Request Submitted
                                              </a>
                                            :
                                            <>
                                              {(exposed.exposing_status == 'exposed' || exposed.exposing_status == 'probable') && 
                                              <a className="button-standard btn-theme" onClick={() => removeExposedInfo(exposed.title, index)}>
                                                  Remove My Exposed Info <FontAwesomeIcon icon={faSpinner} className="spinner exposed-btn-spinner" id={'exposed-btn-spinner-' + index}/>
                                              </a>}
                                            </>
                                            }
                                        
                                          </div>
                                        </td>
                                        <td colSpan={3}>
                                          <div className="collapseBox">
                                            <h2>To protect yourself:</h2>
                                            <p>
                                              Our software can automatically remove all of
                                              your sensitive personal information from
                                              this site (and all 79 major data brokers).
                                              Click the button below to protect yourself.
                                            </p>
                                          </div>  
                                        </td>
                                      </tr>
                                    </>
                                  }
                                </>
                              );
                            })}
                          </tbody>

                        </Table>
                        </div>
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={privacyResult.length}
                          pageSize={PageSize}
                          onPageChange={(page) => setCurrentPage(page)}
                        />
                      </Fragment>
                    ) : (
                      // <span className="no-result">
                      //   No privacy results to display
                      // </span>
                      <Table className="all-privacy" striped responsive>
                          <thead>
                            <tr>
                              <th>SITE</th>
                              <th>NAME</th>
                              <th>LOCATION</th>
                              <th className="relative-header">RELATIVES</th>
                              <th>PHONE</th>
                              <th>MORE INFO</th>
                              <th>STATUS</th>
                            </tr>
                          </thead>
                          <tbody>
                            {skeletonRowNumbers.map((skel, indexNumber) => (
                                <tr className = {(indexNumber%2 == 0) ? '' : 'white-row'}>  
                                    {skeletonCellNumbers.map((s) => (
                                      <td>  <Skeleton />  </td>
                                    ))}
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                    )}
                  </Row>
                </>
              )}
            </div>
          </Container>
        </div>
        <Footer />
        </>
      )}

      <Modal
        show={showModal}
        onHide={() => closeModal()}
        className="customModalSearch"
        >
        <Modal.Header closeButton>
          <Modal.Title >Upgrade to Remove Your Exposed Private Info</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <div className="listUpBody">
            {/* <Alert variant='danger'> 
              <i class="fa fa-warning"></i>&nbsp;&nbsp;
              You have exhausted your free quota for 'Privacy Data Removal'. Please subscribe to request more. 
            </Alert> */}
            <p className="sub-header">Benifits of upgrading:</p>
            <ul>
              <li>Automatically remove your private info from 75+ websites in one click</li>
              <li>Keep your sensitive info (email, phone, address, age, etc.) private</li>
              <li>Protect yourself from spam, identity theft, hacks and robo calls</li>
              <li>Ongoing monitoring and removal any time your info reappears</li>
            </ul>
          </div>
          {/* <NavLink to="/app/plans-and-billing"> */}
            <button type="button" className="successMessageButton viewPlanPricingBtn" onClick={() => redirectToPlanPage()}>View Plans & Pricing</button>
          {/* </NavLink> */}
          <p className="modal-footer-text">No risk, no commitment. Cancel anytime.</p>
        </Modal.Body>
      </Modal>
    </>

    
  );
}

export default ScanningResult;

